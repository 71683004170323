import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import { Modal } from 'flowbite-react';
import CustomButton from "../../components/CustomButton";
import Api from "../../services/Api";
import CalendarBig from "../../components/CalendarBig";
import {CalEventInterface} from "../../interfaces/common";
import {daysLong} from "../../helpers/dateStructs";
import {convertToWeekDate, dateFormatHourMinute} from "../../helpers/dateFunctions";

interface DateExtra {
  from: Date;
  to: Date;
}

interface AvailInterface {
  from: Date
  to: Date
}

export default function AvailabilityRecurring() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t } = translation;
  const [avails, setAvails] = useState<Array<AvailInterface>>([]);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false)
  useEffect(() => {
    getData()
  }, [])
  const today = new Date()

  const getData = () => {
    api.get('local/coach/availability/get').then((result) => {
      const newAvails: Array<AvailInterface> = []
      for (const recurring of result.json.recurring) {
        const from = new Date(recurring.from)
        const to = new Date(recurring.to)
        newAvails.push({
          from: convertToWeekDate(today, from),
          to: convertToWeekDate(today, to)
        })
        setAvails(newAvails)
      }
    })
  }

  const getAllAvailability = () => {
    api.get('local/coach/availability/all').then((result) => {
      console.log(result.json.available)
    })
  }

  const saveEvents = (what: string, dates: Array<CalEventInterface>) => {
    const dateArr: Array<DateExtra> = []
    for (const d of dates) {
      dateArr.push({
        from: d.start,
        to: d.end,
      })
    }
    const data = {
      'recurring': dateArr
    }

    api.post('local/coach/availability/set/recurring', data).then((res) => {
      console.log('ok')
    })
  }

  const getRecurringEvents = () => {
    const startEvents: Array<CalEventInterface> = []
    let id = 1
    for (const d of avails) {
      startEvents.push({
        id: id,
        title: "R",
        start: d.from,
        end: d.to
      })
      id++
    }
    return startEvents
  }

  return (
    <>
      <Modal show={openCalendar} onClose={() => {
        setOpenCalendar(false);
        getData();
      }} size="4xl">
        <Modal.Header className="bg-neutral-700 p-4 max-w-4xl">
          Välj tider du är tillgänglig varje vecka
        </Modal.Header>
        <Modal.Body className="bg-neutral-700 p-4">
          {
            openCalendar &&
              <CalendarBig startEvents={getRecurringEvents()} save={saveEvents} calType="recurring"/>
          }
        </Modal.Body>
      </Modal>
      <p className="mt-4 text-lg font-medium" onClick={() => getAllAvailability()}>{t('set-hours-per-day')}</p>
      {
        avails.map((avail, idx) => {
          let prevWeekDay = idx > 0 ? avails[idx-1].from.getDay() : -1
          let thisWeekDay = avail.from.getDay()
          const showDay = prevWeekDay !== thisWeekDay
          return (
            <div key={"d_" + idx} className="mt-4 flex">
              <div className="w-20">
              {
                showDay && daysLong[thisWeekDay - 1]
              }
              </div>
              <div>
                {dateFormatHourMinute(avail.from)} &ndash;
                {dateFormatHourMinute(avail.to)}
              </div>

            </div>)
        })
      }

      <CustomButton
        onClick={() => setOpenCalendar(true)}
        extraStyle="w-full mt-4"
        buttonType="outlineWhiteBase"
      >
        Hantera återkommande tillgänglighet
      </CustomButton>
    </>
  )
};