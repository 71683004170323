import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const langMap = {
    "settings": ["Settings", "Inställningar"],
    "starting-video": ["Starting video", "Startar video"],
    "starting-camera": ["Starting camera", "Startar kamera"],
    "starting-network": ["Starting network", "Startar nätverk"],
    "booked-meetings": ["Booked meetings", "Bokade möten"],
    "coming": ["Upcoming", "Kommande"],
    "earlier": ["Earlier", "Tidigare"],
    "previous": ["Previous", "Föregående"],
    "calendar": ["Calendar", "Kalender"],
    "start": ["Start", "Start"],
    "my-bookings": ["My bookings", "Mina bokningar"],
    "meetings": ["Meetings", "Möten"],
    "menu-support": ["Support", "Support"],
    "menu-about-company": ["About Zebrain", "Om Zebrain"],
    "logout": ["Log out", "Logga ut"],
    "about-nav-title": ["About Zebrain", "Om Zebrain"],
    "support-nav-title": ["Support", "Support"],
    "support-description-placeholder": ["Describe your issue here...", "Beskriv ditt problem här..."],
    "support-email-info": ["You can also email us at", "Du kan också mejla oss på"],
    "set-availability": ["Set availability", "Ställ in tillgänglighet"],
    "set-hours-per-day": ["Set hours per day", "Ställ in timmar per dag"],
    "availability-timeperiod": ["Time period for availability", "Tidsperiod för tillgänglighet"],
    "availability-timeperiod-set": ["Set for how long your schedule lasts", "Ställ in hur länge ditt schema ska gälla"],
    "number-of-weeks": ["Number of weeks", "Antal veckor"],
    "schedule": ["Schedule", "Schema"],
    "other-settings": ["Other settings", "Övriga inställningar"],
    "sync-external-calendar": ["Sync external calendar", "Synka extern kalender"],
    "add-extra-availability": ["Add extra availability", "Lägg till extra tillgänglighet"],
    "add": ["Add", "Lägg till"],
    "block-time-dates": ["Block time and dates", "Blockera tider och datum"],
    "cancel": ["Cancel", "Avbryt"],
    "save": ["Save", "Spara"],
    "hours": ["hours", "timmar"],
    "minutes": ["minutes", "minuter"],
    "extra-availability-text": [
      "You can choose multiple dates. Selected dates overrules the standard schedule.",
      "Du kan välja flera datum samtidigt. Valda datum åsidosätter standardschemat."
    ],
    "time-before-booking": ["Time between new booking and start of meeting", "Tid mellan ny bokning och mötets start"],
    "time-before-booking-set": [
      "Set how much margin you want from a client can book a meeting until it starts.",
      "Ställ in hur mycket marginal du vill ha från att en klient bokar ett möte tills att mötet startar."
    ],
    "time-head": ["Time ahead", "Tid i förväg"],
    "start-interval": ["Start interval", "Startintervall"],
    "start-interval-set": ["Set start interval for meetings", "Ställ in startintervall för möten"],
    "time-interval": ["Time interval", "Tidsintervall"],
    "max-bookings": ["Max bookings per day", "Max antal bokningar per dag"],
    "max-bookings-set": ["Set max number of bookings per day", "Ställ in det maximala antalet bokningar per dag"],
    "max-per-day": ["Max per day", "Max per dag"],
    "buffer-time": ["Buffer time", "Bufferttid"],
    "buffer-time-set": ["Set the time marginal after a meeting.", "Lägg till tidsmarginal efter ett möte."],
    "margin-after-meeting": ["Margin after meeting", "Marginal efter möte"],
    "my-clients": ["My clients", "Mina klienter"],
    "my-profile": ["My profile", "Min profil"],
    "language": ["Language", "Språk"],
    "select-language": ["Select language", "Välj språk"],
    "choose-coaching-language": ["Choose language to coach in", "Välj språk att coacha på"],
    "profile-intro": ["Short about your self as coach", "Kort om dig som coach"],
    "profile-expertise": ["Expertise", "Expertis"],
    "profile-experience": ["Experience", "Erfarenhet"],
    "profile-philosophy": ["Philosophy", "Filosofi"],
    "se": ["Swedish", "Svenska"],
    "fi": ["Finnish", "Finska"],
    "en": ["English", "Engelska"],
    "de": ["German", "Tyska"],
    "fr": ["French", "Franska"],
    "es": ["Spanish", "Spanska"],
}

const english = {}
const swedish = {}
for (const k in langMap) {
    english[k] = langMap[k][0]
    swedish[k] = langMap[k][1]
}
const resources: any = {
    en: {
        translation: english
    },
    sv: {
        translation: swedish
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
}

i18n.use(initReactI18next).init({
    resources: resources
})

// i18n.use(initReactI18next).init({
//     resources: {
//         en: {
//             translation: {
//                 "settings": "Settings",
//                 "starting-video": "Starting video",
//                 "starting-camera": "Starting camera",
//                 "starting-network": "Starting network",
//                 "booked-meetings": "Booked meetings",
//                 "coming": "Upcoming",
//                 "previous": "Previous",
//                 "calendar": "Calendar",
//                 "start": "Start",
//                 "my-bookings": "My bookings",
//                 "meetings": "Meetings",
//                 'deadline': 'Deadline',
//                 "continue": "Continue",
//                 "time-ago-now": "now",
//                 "send": "Send",
//                 "chat-placeholder": "Type your message here...",
//                 "chat-send": "Send",
//                 "chat-start": "Get started",
//                 "menu-chat": "Chat with Emma",
//                 "menu-my-tasks": "My tasks",
//                 "menu-development-plan": "My development plan",
//                 "menu-my-account": "My account",
//                 "menu-support": "Support",
//                 "menu-about-company": "About Zebrain",
//                 "task": "Task",
//                 "description": "Description",
//                 "logout": "Log out",
//                 "tasks-page-title": "Select task to work on",
//                 "auto-task-chat-message": "I want to work on the task",
//                 "development-area-page-title": "Select focus area",
//                 "focus-area-quick-select": "Quick select",
//                 "focus-area-start-chat": "I want to start coaching within the focus area",
//                 "focus-area-start-chat-button": "Start coaching session",
//                 "focus-area-learn-more-button": "I want to learn more about the topic",
//                 "chat-nav-title": "Chat",
//                 "tasks-nav-title": "My tasks",
//                 "ongoing": "Ongoing",
//                 "completed": "Completed",
//                 "completed-tasks": "Completed tasks",
//                 "development-plan-nav-title": "My development plan",
//                 "focus-area-nav-title": "Focus Area",
//                 "no-tasks": "You have not created any tasks.",
//                 "no-development-areas": "You have not created any focus areas.",
//                 "chat-welcome": "Hi, nice to meet you here!\nI'm your AI coach Emma, let's start with a short introduction.",
//                 "support-nav-title": "Support",
//                 "support-page-title": "What can we help you with?",
//                 "support-description-placeholder": "Describe your issue here...",
//                 "support-email-info": "You can also email us at",
//                 "support-modal-success-title": "Thank you for reaching out to us! ",
//                 "support-modal-success-description": "We will get back to you as soon as possible.",
//                 "support-error-message": "An error occurred, please try again later.",
//                 "about-nav-title": "About Zebrain",
//
//
//             },
//         },
//         sv: {
//             translation: {
//                 "settings": "Inställningar",
//                 "starting-video": "Startar video",
//                 "starting-camera": "Startar kamera",
//                 "starting-network": "Startar nätverk",
//                 "booked-meetings": "Bokade möten",
//                 "coming": "Kommande",
//                 "previous": "Tidigare",
//                 "calendar": "Kalender",
//                 "start": "Start",
//                 "my-bookings": "Mina bokningar",
//                 "meetings": "Möten",
//                 'deadline': 'Slutdatum',
//                 "continue": "Fortsätt",
//                 "send": "Skicka",
//                 "time-ago-now": "nu",
//                 "chat-placeholder": "Skriv ditt meddelande här...",
//                 "chat-send": "Skicka",
//                 "chat-start": "Kom igång",
//                 "menu-chat": "Chatta med Emma",
//                 "menu-my-tasks": "Mina uppgifter",
//                 "menu-development-plan": "Min utvecklingsplan",
//                 "menu-my-account": "Mitt konto",
//                 "menu-support": "Support",
//                 "menu-about-company": "Om Zebrain",
//                 "task": "Uppgift",
//                 "description": "Beskrivning",
//                 "logout": "Logga ut",
//                 "tasks-page-title": "Välj uppgift att arbeta med",
//                 "auto-task-chat-message": "Jag vill arbeta med uppgiften",
//                 "development-area-page-title": "Välj fokusområde",
//                 "focus-area-quick-select": "Snabbval",
//                 "focus-area-start-chat": "Jag vill starta coaching inom fokusområdet",
//                 "focus-area-start-chat-button": "Starta coachsession",
//                 "focus-area-learn-more-button": "Jag vill lära mig mer om ämnet",
//                 "chat-nav-title": "Chatt",
//                 "tasks-nav-title": "Mina uppgifter",
//                 "ongoing": "Pågående",
//                 "completed": "Färdiga",
//                 "completed-tasks": "Färdiga uppgifter",
//                 "development-plan-nav-title": "Min utvecklingsplan",
//                 "focus-area-nav-title": "Fokusområde",
//                 "no-tasks": "Du har inte skapat några uppgifter.",
//                 "no-development-areas": "Du har inte skapat några fokusområden.",
//                 "chat-welcome": "Hej, vad kul att träffa dig här!\nJag är din AI coach Emma, låt oss börja med en kort introduktion.",
//                 "support-nav-title": "Support",
//                 "support-page-title": "Vad kan vi hjälpa dig med?",
//                 "support-description-placeholder": "Beskriv ditt problem här...",
//                 "support-email-info": "Du kan också mejla oss på",
//                 "support-modal-success-title": "Tack för att du kontaktar oss! ",
//                 "support-modal-success-description": "Vi återkommer så snart som möjligt.",
//                 "support-error-message": "Ett fel uppstod, försök igen senare.",
//                 "about-nav-title": "Om Zebrain",
//
//             },
//         },
//     },
//     fallbackLng: "en",
//     interpolation: {
//         escapeValue: false,
//     },
// });

export default i18n;