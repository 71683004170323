import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect, useRef } from "react";
import Api from "../../services/Api";
import {CoachInterface} from "../../interfaces/common";
import {ClientInterface} from "../../interfaces/common";
import {ChatInterface} from "../../interfaces/common";
import {CONFIG} from "../../constants"
import profileIcon from "shared-assets/icons/profile.svg";
import sendIcon from "../../../assets/images/icons/paper-airplane.svg"
import {dateFormatSoon} from "../../helpers/dateFunctions";
import WebSocketClient from "../../services/WebSocketClient";

interface Props {
  clientId?: number
  coachId?: number
  isCoach: boolean
}

const Chat: React.FC<Props> = ({ clientId, coachId, isCoach }) => {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [chats, _setChats] = useState<Array<ChatInterface>>([])
  const chatsRef = useRef<Array<ChatInterface>>([])
  const [client, setClient] = useState<ClientInterface>()
  const [coach, setCoach] = useState<CoachInterface>()
  const [text, setText] = useState<string>('')
  const [loaded, setLoaded] = useState<boolean>(false)
  const chatWindowRef = useRef(null)
  const setChats = (newChats: Array<ChatInterface>) => {
    chatsRef.current = newChats;
    _setChats(newChats);
  }

  const webSocketClient = useRef(WebSocketClient.getInstance())

  useEffect(() => {
    const url = clientId ? 'local/coach/chat/' + clientId + '/get' : 'local/client/chat/' + coachId + '/get'
    api.get(url).then((result) => {
      const chats: Array<ChatInterface> = []
      for (const chat of result.json.chats as Array<ChatInterface>) {
        chat.dateSent = new Date(chat.dateSent)
        if (chat.dateRead) {
          chat.dateRead = new Date(chat.dateRead)
        }
        chats.push(chat)
      }
      setClient(result.json.client)
      setCoach(result.json.coach)
      setChats(chats)
      setLoaded(true)
      webSocketClient.current.webSocket?.addEventListener("message", messageListener)
    })

    return () => {
      webSocketClient.current.webSocket?.removeEventListener("message", messageListener)
    }
  }, [])

  const messageListener = (event: any) =>  {
    const mess = JSON.parse(event.data)
    console.log(mess)
    if (mess.chat && mess.fromId === clientId) {
      const newChat: ChatInterface = {
        id: Math.floor(Math.random() * 1000000),
        text: mess.chat.message,
        sentByCoach: false,
        dateSent: new Date()
      }
      const newChats = [... chatsRef.current]
      newChats.push(newChat)
      setChats(newChats)
    }

  }

  useEffect(() => {
    if (chats.length > 0 && chatWindowRef.current) {
      chatWindowRef.current.scrollTo(0, chatWindowRef.current.scrollHeight)
    }
  }, [chats])

  if (!loaded) {
    return (<div className="mt-4">Loading chats...</div>)
  }

  const send = () => {
    if (text.length < 1) {
      return
    }

    const url = clientId ? 'local/coach/chat/' + clientId + '/send' : 'local/client/chat/' + coachId + '/send'
    const data = {
      text: text,
      sentByCoach: isCoach
    }
    api.post(url, data).then((result) => {
      if (result.response.ok) {
        const chat: ChatInterface = {
          id: 0,
          text: text,
          sentByCoach: isCoach,
          dateSent: new Date(),
        }
        const newChats = [...chats]
        newChats.push(chat)
        setChats(newChats)
        setText('')
        chatWindowRef.current.scrollTo(0, chatWindowRef.current.scrollHeight)
      }
    })
  }

  return (
    <div className="h-full relative flex justify-between flex-col">
      <div ref={chatWindowRef} className="overflow-y-scroll">
      {
        chats.map((chat: ChatInterface) => {
          const img = chat.sentByCoach ? (coach.picture ? (CONFIG.FILE_HOST + coach.picture) : profileIcon)
            :
            (client.picture ? (CONFIG.FILE_HOST + client.picture) : profileIcon)
          const name = chat.sentByCoach ? coach.firstName + ' ' + coach.lastName
            :
            client.firstName + ' ' + client.lastName
          return (
            <div className="px-4 mt-4 flex" key={"chat_" + chat.id}>
              <img className="picture-sm" src={img} alt="pic"/>
              <div className="ml-4">
                <div className="flex">
                  <div className="font-medium">
                    {name}
                  </div>
                  <span className="text-neutral-400 ml-4 font-normal">{dateFormatSoon(chat.dateSent, lang)}</span>
                </div>
                <div className="text-neutral-300 whitespace-pre-line">
                  {chat.text}
                </div>
              </div>
            </div>
          )
        })
      }
      </div>

      <div>
      <hr className="border-neutral-700 mt-4"/>
        <div className="flex justify-between items-center p-4">
        <textarea value={text} className="bg-neutral-900 rounded w-full h-20"
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Skriv svar här..."/>
          <img src={sendIcon} alt="send" className="ml-4 picture-sm" onClick={() => send()}/>
        </div>
      </div>


    </div>
  )
}

export default Chat