import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { dateFormatDate, dateFormatHourMinute } from "../../helpers/dateFunctions"
import Api from "../../services/Api";
import {BookedMeeting, CalEventInterface, CoachStartInterface} from "../../interfaces/common";
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconClock from "shared-assets/icons/clock.svg";
import iconDots from "shared-assets/icons/dots-horizontal.svg";
import CustomButton from "../../components/CustomButton";
import Popup from "../../components/Popup/Popup"
import CancelMeeting from "../../components/CancelMeeting/CancelMeeting";
import BookMeeting from "../../components/BookMeeting/BookMeeting"


export default function Start() {
  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<CoachStartInterface>({name: "None", meetings: []})
  const [popupEvent, setPopupEvent] = useState<React.MouseEvent<HTMLElement>|undefined>(undefined)
  const [selectedMeeting, setSelectedMeeting] = useState<BookedMeeting|null>(null)
  const [showCancelMeeting, setShowCancelMeeting] = useState<boolean>(false)
  const [showChangeMeeting, setShowChangeMeeting] = useState<boolean>(false)


  useEffect(() => {
    api.waitForToken(getStartData)
  }, [])

  const getStartData = () => {
    api.get('local/coach/start')
      .then(response => {
        let startData = response.json
        for (let m of startData.meetings as Array<BookedMeeting>) {
          m.startDate = new Date(m.start)
        }
        setData(response.json)
        setLoaded(true);
      })
  }

  const getMeetings = () => {
    api.get('coach_clients')
      .then(response => {
        console.log(response.json)
      })
  }

  const calculateMeetingsToday = () => {
    let midnight = new Date()
    midnight.setHours(23)
    midnight.setMinutes(59)
    let meetingsToday = 0
    for (const m of data.meetings as Array<BookedMeeting>) {
      if (m.startDate < midnight) {
        meetingsToday++
      }
    }
    if (meetingsToday === 0) {
      return "Du har inga möten idag."
    } else if (meetingsToday === 1) {
      return "Du har 1 möte inbokat idag."
    } else {
      return "Du har " + meetingsToday + " möten inbokade idag."
    }
  }

  const calculateEndTime = (start: Date, l: number) => {
    return new Date(start.getTime() + l * 60000)
  }

  const togglePopup = (e: React.MouseEvent<HTMLElement>, meeting: BookedMeeting) => {
    if (popupEvent === undefined) {
      setPopupEvent(e)
      setSelectedMeeting(meeting)
    } else {
      setPopupEvent(undefined)
    }
  }

  const popClick = (what: string) => {
    if (what === 'start') {
      navigate(`/meeting?who=coach&meetingKey=${selectedMeeting.key}`)
    } else if (what === 'cancel') {
      setShowCancelMeeting(true)
    } else {
      setShowChangeMeeting(true)
    }
  }

  const closeCancelMeeting = (changed: boolean) => {
    setShowCancelMeeting(false)
    if (changed) {
      getStartData()
    }
  }

  const closeChangeMeeting = (changed: boolean) => {
    setShowChangeMeeting(false)
    if (changed) {
      getStartData()
    }
  }

  const showMeetingToday = () => {
    if (data.meetings.length < 1) {
      return (<p>Du har inga möten idag</p>)
    } else {
      const meeting: BookedMeeting = data.meetings[0]
      const endTime = calculateEndTime(meeting.startDate, meeting.length)
      return (
        <>
          <Popup event={popupEvent} close={() => setPopupEvent(undefined)}>
            <>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('change')}>
                Ändra
              </div>
              <hr className="border-neutral-600"/>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('start')}>
                Gå till mötet
              </div>
              <hr className="border-neutral-600"/>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('cancel')}>
                Avboka
              </div>
            </>
          </Popup>

          <p className="text-xl font-medium text-blue-400">{meeting.firstName} {meeting.lastName}</p>
          <div className="flex justify-between items-center">
            <div className="flex items-center mt-2">
              <img alt="Date" src={iconCalendar} className="icon-lg"/>
              <span className="ml-2">{dateFormatDate(meeting.startDate, false, lang)}</span>
              <img className="ml-2 icon-lg" alt="Time" src={iconClock}/>
              <span className="ml-2">
                {dateFormatHourMinute(meeting.startDate)} - {dateFormatHourMinute(endTime)}
              </span>
            </div>
            <div className="cursor-pointer" onClick={(e) => togglePopup(e, meeting)}>
              <img className="icon-lg" alt="Options" src={iconDots}/>
            </div>
          </div>


          <div className="mt-4 flex justify-evenly">
            <CustomButton
              onClick={() => navigate(`/meeting?who=coach&meetingKey=${meeting.key}`)}
              extraStyle="w-full"
              buttonType="outlineWhiteBase"
            >
              Till möte
            </CustomButton>
          </div>
        </>
      )
    }
  }

  if (!loaded) {
    return (
      <>
        <h2>Loading...</h2>
      </>
    )
  }

  return (
    <>
      {
        (showCancelMeeting && selectedMeeting) &&
          <CancelMeeting lang={lang} meeting={selectedMeeting}
                         close={closeCancelMeeting}/>
      }
      {
        (showChangeMeeting && selectedMeeting) &&
          <BookMeeting meeting={selectedMeeting} close={closeChangeMeeting}
                       lang={lang}/>
      }
      <h1 className="text-3xl mt-4 font-medium dark:text-white" onClick={() => getMeetings()}>
        Hej {data.name}
      </h1>
      <p className="mt-4">{calculateMeetingsToday()}</p>

      <div className="rounded-lg mt-6 p-4 w-full dark:bg-neutral-800">
        <p className="font-medium">Nästa möte</p>
        <div className="mt-4">
          {showMeetingToday()}
        </div>
      </div>
    </>
  );
};