import React, {useState, useEffect, useRef} from 'react';
import { Modal } from 'flowbite-react';
import {BookedMeeting, CalEventInterface} from "../../interfaces/common";
import CustomButton from "../CustomButton";
import {
  addMinutes,
  dateFormatDate,
  dateFormatHourMinute,
} from "../../helpers/dateFunctions";
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconClock from "shared-assets/icons/clock.svg";
import iconBadgeCheck from "shared-assets/icons/badge-check.svg";
import iconCloseBlack from "shared-assets/icons/black/close.svg";
import Api from "../../services/Api";
import DatePicker from "../Calendar/DatePicker";

interface AvailInterface {
  from: Date
  to: Date
}

interface Props {
  meeting?: BookedMeeting;
  close: Function
  lang: string
}

const BookMeeting: React.FC<Props> = ({ meeting, close, lang }) => {
  const api = Api.getInstance();
  const [txt, setTxt] = useState<string>('');
  const [booked, setBooked] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [newMeeting, setNewMeeting] = useState<CalEventInterface>(null)
  const [avails, setAvails] = useState<Array<AvailInterface>>([]);
  const [bookableDays, setBookableDays] = useState<Array<Date>>([]);
  const [selectedDate, setSelectedDate] = useState<Date|null>(null)
  const minutesBetweenMeetings = useRef<number>(15)

  useEffect(() => {
    api.get('local/coach/availability/all').then((result) => {
      const newAvails: Array<AvailInterface> = []
      const newBookableDays: Array<Date> = []
      for (const recurring of result.json.available) {
        const from = new Date(recurring.from)
        const to = new Date(recurring.to)
        newBookableDays.push(from)
        newAvails.push({
          from: from,
          to: to
        })
      }
      minutesBetweenMeetings.current = result.json.startInterval
      setAvails(newAvails)
      setBookableDays(newBookableDays)
    })
  }, [])

  const doBook = () => {
    const data = {
      id: meeting.id,
      client_id: meeting.clientId,
      from: newMeeting.start,
      length: meeting.length,
      message: txt
    }
    api.post('local/meeting/book', data).then(response => {
      setBooked(true);
    })
  }

  const getBookableMeetings = (day: Date) => {
    const startEvents: Array<CalEventInterface> = []
    let id = 1
    const meetingInSeconds = meeting.length * 60
    const pauseInSeconds = minutesBetweenMeetings.current * 60
    for (const d of avails) {
      if (d.from.getFullYear() != day.getFullYear() || d.from.getMonth() != day.getMonth() || d.from.getDate() != day.getDate()) {
        continue
      }
      let start = new Date(d.from)
      while ((d.to.getTime() - start.getTime()) / 1000 >= meetingInSeconds) {
        let end = new Date(start)
        end.setSeconds(end.getSeconds() + meetingInSeconds)
        startEvents.push({
          id: id,
          title: "M",
          start: start,
          end: end
        })
        id++
        start = new Date(start)
        start.setSeconds(start.getSeconds() + pauseInSeconds)
      }

    }
    return startEvents
  }

  const showContent = () => {
    if (booked) {
      return (
        <div className="p-4">
          <div className="my-12 flex justify-center items-center">
            <img className="icon-xl" alt="check" src={iconBadgeCheck} />
          </div>
          <div className="text-neutral-300">
            Ditt möte har {meeting.id === 0 ? 'bokats' : 'ändrats'} och din klient uppdaterats.
          </div>
        </div>
      )
    } else if (newMeeting) {
      return (
        <div className="p-4">
          <div className="font-medium">
            {meeting.firstName} {meeting.lastName}
          </div>
          <div className="mt-4 flex items-center">
            <img alt="calendar" className="icon" src={iconCalendar} />
            <div className="ml-2">{dateFormatDate(newMeeting.start, false, lang)}</div>
          </div>
          <div className="mt-4 flex items-center">
            <img className="icon" alt="Time" src={iconClock}/>
            <span className="ml-2">
                {dateFormatHourMinute(newMeeting.start)} - {dateFormatHourMinute(newMeeting.end)}
              </span>
          </div>

          <div className="mt-4">
            <textarea value={txt} className="bg-neutral-700 text-neutral-300 rounded w-full h-24"
                      placeholder="Skriv meddelande till klienten..."
                   onChange={(e) => setTxt(e.target.value)} autoComplete="off" />
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  const selectMeetingTime = (e: CalEventInterface) => {
    setNewMeeting(e)
  }

  useEffect(() => {
    if (newMeeting) {
      setConfirm(true)
    }
  }, [newMeeting])

  const showConfirmText = () => {
    if (booked) {
      if (meeting.id === 0) {
        return "Mötet har bokats"
      } else {
        return "Mötet har ändrats"
      }
    } else {
      if (meeting.id === 0) {
        return "Bekräfta bokning"
      } else {
        return "Bekräfta ändring"
      }
    }
  }

  return (
    <>
      <Modal show={confirm} onClose={() => setConfirm(false)} size="lg" style={{zIndex: 2004}}>
        <Modal.Header className="bg-neutral-700 font-medium text-xl p-4">
          {showConfirmText()}
        </Modal.Header>
        <Modal.Body className="bg-neutral-700">
          <>
            {showContent()}

            <hr className="border-neutral-600"/>

            <div className="p-4">
              {
                booked ? (
                  <CustomButton extraStyle="w-full" onClick={() => close(booked)}>Ok</CustomButton>
                ) : (
                  <CustomButton extraStyle="w-full" onClick={() => doBook()}>
                    {meeting.id === 0 ? "Boka möte" : "Ändra möte"}
                  </CustomButton>
                )
              }
            </div>

          </>
        </Modal.Body>
      </Modal>


      {
        meeting.id > 0 &&
          <>
            <div className="text-2xl mt-4 font-medium">
              Ändra datum och tid
            </div>
            <div className="mt-4 p-4 bg-neutral-200 text-neutral-900 rounded">
                <div className="flex justify-betwen items-center">
                    <div className="font-medium">
                        Ursprunglig bokning
                    </div>
                    <div className="flex flex-grow justify-end">
                        <img className="cursor-pointer" src={iconCloseBlack} alt="close"
                             onClick={() => close(false)}/>
                    </div>
                </div>
                <div className="mt-4 flex items-center text-sm">
                    <div>
                      {meeting.firstName} {meeting.lastName}
                    </div>
                    <div className="ml-4 flex">
                        <img src={iconCalendar} className="icon" alt="cal"/>
                        <span className="ml-1">
                          {dateFormatDate(meeting.startDate, false, lang)}
                        </span>
                    </div>
                    <div className="ml-4 flex">
                        <img src={iconClock} className="icon" alt="cal"/>
                        <span className="ml-1">
                          {dateFormatHourMinute(meeting.startDate)} - {dateFormatHourMinute(addMinutes(meeting.startDate, meeting.length))}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-8">&nbsp;</div>
          </>
      }


      <DatePicker dates={bookableDays} clickDate={(d: Date) => setSelectedDate(d)}
                  changeMonth={() => setSelectedDate(null)}/>

      {
        selectedDate &&
          <div className="mt-8">
              <div className="flex justify-between items-end">
                  <div className="font-medium text-lg">Lediga tider</div>
                  <div>{dateFormatDate(selectedDate, false, lang)}</div>
              </div>

              <div className="grid grid-cols-3 gap-x-2">
                {
                  getBookableMeetings(selectedDate).map((d, i) => {
                    return (
                      <div key={"time_" + i} className="mt-2">
                        <CustomButton buttonType="outlineWhiteBase" onClick={() => selectMeetingTime(d)}
                                      extraStyle="w-full">
                          {dateFormatHourMinute(d.start)}
                        </CustomButton>
                      </div>
                    )
                  })
                }
              </div>

          </div>
      }
    </>
  );
};

export default BookMeeting;
