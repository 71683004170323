import { RouterProvider, createBrowserRouter } from "react-router-dom";
import BookingsPage from "./pages/BookingsPage/BookingsPage";
import Start from "./pages/StartPage/Start"
import { App } from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import AcceptInvite from "./pages/AcceptInvitePage";
import SupportPage from "./pages/SupportPage";
import AboutPage from "./pages/AboutPage";
import CalendarPage from "./pages/CalendarPage";
import Meeting from "./pages/MeetingPage/Meeting"
import { useTranslation } from 'react-i18next';
import Profile from "./pages/Profile/Profile";
import Availability from "./pages/Settings/Availability";
import ClientsPage from "./pages/Clients/ClientsPage";
import ClientPage from "./pages/Clients/ClientPage";
import CalendarBig from "./components/CalendarBig";

const AppRouter = ({ outerRoute }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout: auth0logout } = useAuth0();
  const isAuth = isAuthenticated && !isLoading;

  const translation = useTranslation();
  const { t } = translation;

  let routes = [
    {
      path: '/',
      element: (
        <Start />
      )
    },
    {
      path: '/bookings',
      element: (
        <BookingsPage />
      )
    },
    {
      path: '/clients',
      element: <ClientsPage/>
    },
    {
      path: '/clients/:clientId',
      element: <ClientPage />
    },
    {
      path: '/meeting',
      element: (
          <Meeting />
      )
    },
    {
      path: '/profile',
      element: <Profile/>,
    },
    {
      path: '/availability',
      element: <Availability />,
    },
    {
      path: '/accept_invite/:code',
      element: (
        <AcceptInvite />
      )
    },
    {
      path: '/about',
      element: (
        <AboutPage />
      )
    },
    {
      path: '/support',
      element: (
        <SupportPage />
      )
    },

    {
      path: '/calendar',
      element: (
        <CalendarPage />
      )
    },
    {
      path: '/calendartest',
      element: (
          <CalendarBig />
      )
    },

    // { path: '/auth', element: <AuthPage /> },
  ];


  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: routes
    }
  ]);

  useEffect(() => {
    if (outerRoute) {
      router.navigate(outerRoute);
    }
  }
    , [outerRoute]);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        const currentPath = window.location.pathname;
        const isAcceptInvite = currentPath.startsWith('/accept_invite/');

        const loginOptions = {
          appState: {
            returnTo: currentPath
          }
        };

        if (isAcceptInvite) {
          (loginOptions as any).authorizationParams = { screen_hint: "signup" };
        }

        loginWithRedirect(loginOptions);
      }
    }
  }, [isLoading, isAuthenticated]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
