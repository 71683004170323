import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Modal } from 'flowbite-react';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import 'moment/locale/sv.js'

import {addDays, dateFormatDate, twoDigits} from "../../helpers/dateFunctions";
import {daysLong} from "../../helpers/dateStructs";
import DatePicker from "../Calendar";
import iconClock from "shared-assets/icons/clock.svg";
import iconCalendar from "shared-assets/icons/calendar.svg";
import iconClose from "shared-assets/icons/close.svg";
import {CalEventInterface} from "../../interfaces/common";
import {CustomButton} from "shared-components";
// Using local modified version of 'react-big-calendar/lib/css/react-big-calendar.css'
import './react-big-calendar.css'
// Using local modified version of 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import './dragAndDropStyles.css';
import Api from "../../services/Api";

const DnDCalendar = withDragAndDrop(Calendar)

interface Props {
  startEvents: CalEventInterface[]
  save?: boolean
  calType?: string
  click?: Function
}

const CalendarBig: React.FC<Props> = ({ startEvents, save, calType, click }) => {
  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [selectedEvent, setSelectedEvent] = useState<CalEventInterface|null>(null)
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [hourFrom, setHourFrom] = useState<number>(0)
  const [minuteFrom, setMinuteFrom] = useState<number>(0)
  const [hourTo, setHourTo] = useState<number>(0)
  const [minuteTo, setMinuteTo] = useState<number>(0)
  const [events, setEvents] = useState<CalEventInterface[]>(startEvents)
  const [messageId, setMessageId] = useState<number>(startEvents.length + 1)
  // From https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/addons-drag-and-drop-props--on-event-drop
  moment.locale("se")
  const localizer = momentLocalizer(moment);

  const messages = {
    previous: '<',
    next: '>',
    today: 'Idag',
    month: 'Månad',
    week: 'Vecka',
    work_week: 'Vecka',
    day: 'D',
    agenda: '*',
    date: 'Datum',
    time: 'H',
  }

  const saveEvent = (remove: boolean, event: CalEventInterface) => {

    if (remove && event.dbId === 0) {
      console.log('Will not remove event with id ' + event.dbId)
      return
    }
    const data = {
      event: event,
      remove: remove
    }
    setSelectedEvent(null)

    api.post('local/coach/availability/edit', data).then((response) => {
      if (remove) {
        console.log('Removed ' + event.dbId)
      } else {
        const newId = response.json.id
        if (newId !== event.dbId) {
          const newEvents = [...events]
          for (const newEvent of newEvents) {
            if (newEvent.id === event.id) {
              newEvent.dbId = newId
            }
          }
          setEvents(newEvents)
        }
      }
      console.log('ok')
    })
  }

  const EmptyCustomToolbar = () => {
    return (<></>)
  }

  const getTypeTitle = (title: string) => {
    if (title === 'R') {
      if (lang === 'sv') {
        return 'Varje vecka'
      } else {
        return 'Every week'
      }
    } else if (title === 'A') {
      if (lang === 'sv') {
        return 'Tillgänglig'
      } else {
        return 'Available'
      }
    } else if (title === 'B') {
      if (lang === 'sv') {
        return 'Blockerad'
      } else {
        return 'Blocked'
      }
    }
  }

  const EventComponent = (e: CalEventInterface) => {
    const title = getTypeTitle(e.title)
    return (
      <span className="font-medium">{title}</span>
    )
  }

  const eventStyleGetter = (e: CalEventInterface) => {
    // console.log(e)
    let bg = '#94ABFF'
    if (e.title === 'B') {
      bg = '#FF6079'
    } else if (e.title === 'S') {
      bg = '#FF6079'
    }
    const style = {
      backgroundColor: bg,
      color: '#000'
    };
    return {
        style: style
    };
  }

  const getCalendarSettings = () => {
    const settings = {
      views: ['week', 'month', 'day'],
      defaultView: 'week',
      components: {},
      selectable: true,
      resizeable: true,
    }
    if (calType === 'recurring') {
      settings.views = ['work_week']
      settings.defaultView = 'work_week'
      settings.components = {
        work_week: {
          header: ({ date, localizer }) => localizer.format(date, 'dddd')
        },
        toolbar: EmptyCustomToolbar,
        event: EventComponent,
      }
    } else if (calType === 'available') {
      settings.views = ['week', 'month']
      settings.defaultView = 'week'
      settings.components = {
        event: EventComponent,
      }
    } else if (calType === 'blocked') {
      settings.views = ['week', 'month']
      settings.defaultView = 'week'
      settings.components = {
        event: EventComponent,
      }
    } else if (calType === 'book_meeting') {
      settings.views = ['work_week']
      settings.defaultView = 'work_week'
      settings.components = {
        event: EventComponent,
      }
      settings.selectable = false
      settings.resizeable = false
    }
    return settings
  }

  const settings = getCalendarSettings()

  const addEvent = (e: any) => {
    if (save) {
      const newEvents = [...events]
      const newEvent = {
        id: messageId,
        dbId: 0,
        start: e.start,
        end: e.end,
        title: "A",
      }
      newEvents.push(newEvent)
      // console.log('addEvent for id ' + messageId)
      setMessageId(messageId + 1)
      setEvents(newEvents)
      // setSelectedEvent(newEvent)
      openEvent(newEvent)
    } else {
      console.log('Not supposed to add stuff')
    }

  }

  const openEvent = (e: CalEventInterface) => {
    setHourFrom(e.start.getHours())
    setMinuteFrom(e.start.getMinutes())
    setHourTo(e.end.getHours())
    setMinuteTo(e.end.getMinutes())
    setSelectedEvent(e)
  }

  const editEvent = (e: CalEventInterface) => {
    if (click) {
      click(e)
    } else {
      console.log(e)
      openEvent(e)
    }
  }

  const onChangeEvent = (e) => {
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === e.event.id) {
        le.start = e.start
        le.end = e.end
      }
    }
    setEvents(newEvents)
    saveEvent(false, e.event)
  }

  const saveFromModal = () => {
    saveEvent(false, selectedEvent)
  }

  const removeEvent = () => {
    const newEvents = []
    for (let le of events) {
      if (le.id != selectedEvent.id) {
        newEvents.push(le)
      }
    }
    setEvents(newEvents)
    saveEvent(true, selectedEvent)
  }


  const calHeight = window.innerHeight - 240
  let scrollToTime = new Date()
  scrollToTime.setHours(8)
  scrollToTime.setMinutes(0)
  scrollToTime.setSeconds(0)

  const handleSelectedDay = (d: Date) => {

    const newEvent = selectedEvent
    newEvent.start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), selectedEvent.start.getHours(), selectedEvent.start.getMinutes(), 0)
    newEvent.end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), selectedEvent.end.getHours(), selectedEvent.end.getMinutes(), 0)
    setSelectedEvent(newEvent)

    // Update the list of events for calendar
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === newEvent.id) {
        le.start = newEvent.start
        le.end = newEvent.end
      }
    }
    setEvents(newEvents)
    setShowDatePicker(false)
  }

  const setTime = (e: any, t: string, what: string) => {
    const value = parseInt(e.currentTarget.value)
    const newEvent = selectedEvent
    if (what === "hour") {
      if (t === "from") {
        setHourFrom(value)
        newEvent.start.setHours(value)
      } else if (t === "to") {
        setHourTo(value)
        newEvent.end.setHours(value)
      }
    } else if (what === "minute") {
      if (t === "from") {
        setMinuteFrom(value)
        newEvent.start.setMinutes(value)
      } else if (t === "to") {
        setMinuteTo(value)
        newEvent.end.setMinutes(value)
      }
    }
    setSelectedEvent(newEvent)
    // Update the list of events for calendar
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === newEvent.id) {
        le.start = newEvent.start
        le.end = newEvent.end
      }
    }
    setEvents(newEvents)
  }

  const makeRange = (from: number, to: number, st:number = 1) => {
    const step = from > to ? -st : st
    let l = Math.abs(from - to) / step
    return Array.from({ length: l + 1}, (v, k) => from + k * step)
  }

  const changeSelectedEvent = (e) => {
    const newSelectedEvent = {...selectedEvent}
    newSelectedEvent.title = e.target.value
    setSelectedEvent(newSelectedEvent)
    const newEvents = [...events]
    for (const e of newEvents) {
      if (e.id === newSelectedEvent.id) {
        console.log('setting from ' + e.title + ' to ' + newSelectedEvent.title )
        e.title = newSelectedEvent.title
      } else {
        console.log(e.id + ' != ' + newSelectedEvent.id )
      }
    }
    setEvents(newEvents)
  }

  const handleChangedDate = (date: Date, view: string, action: string) => {
    if (view !== 'week') {
      return
    }
    let days = action === 'NEXT' ? 7 : -7
    const newEvents = [...events]
    for (const event of newEvents) {
      if (event.title === 'R') {
        event.start = addDays(event.start, days)
        event.end = addDays(event.end, days)
      }
    }
    setEvents(newEvents)
  }

  const renderPopupModal = () => {
    return (
      <>
        <div className="flex justify-between">
          <div className="text-xl font-medium">
            <select className="z-select" value={selectedEvent.title}
                    onChange={(e) => changeSelectedEvent(e)}>
              <option value="A">{getTypeTitle("A")}</option>
              <option value="R">{getTypeTitle("R")}</option>
              <option value="B">{getTypeTitle("B")}</option>
            </select>
          </div>
          <img className="cursor-pointer"
               onClick={() => saveFromModal()} alt="close" src={iconClose}/>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex cursor-pointer relative">
            <img className="icon" alt="date" src={iconCalendar}/>
            {
              selectedEvent.title === "R" ? (
                <div className="ml-2">
                  {daysLong[selectedEvent.start.getDay() - 1]}{lang === 'sv' ? 'ar' : 's'}
                </div>
              ) : (
                <div className="ml-2" onClick={() => setShowDatePicker(true)}>
                  {dateFormatDate(selectedEvent.start, false, lang)}
                </div>
              )
            }

            {
              showDatePicker &&
                <div style={{position: 'absolute', top: '22px', zIndex: 19001, width: '400px'}}
                     className="bg-neutral-800 p-4">
                    <div className="flex justify-end">
                        <img className="cursor-pointer"
                             onClick={() => setShowDatePicker(false)} alt="close" src={iconClose}/>
                    </div>
                    <DatePicker dates={[selectedEvent.start]}
                                clickDate={(d: Date) => handleSelectedDay(d)}/>
                </div>
            }
          </div>
          <div className="flex items-center">
            <img className="icon" alt="date" src={iconClock}/>
            <select value={hourFrom}
                    className="ml-2 z-select sm"
                    onChange={(e) => setTime(e, "from", "hour")}>
              {
                makeRange(0, hourTo).map(hour => {
                  return (
                    <option key={"hour_from_" + hour} value={hour}>{twoDigits(hour)}</option>
                  )
                })
              }
            </select>:
            <select value={minuteFrom}
                    className="z-select sm"
                    onChange={(e) => setTime(e, "from", "minute")}>
              {
                makeRange(0, 45, 15).map(minute => {
                  return (
                    <option key={"minute_from_" + minute} value={minute}>{twoDigits(minute)}</option>
                  )
                })
              }
            </select>
            &ndash;
            <select value={hourTo}
                    className="ml-2 z-select sm"
                    onChange={(e) => setTime(e, "to", "hour")}>
              {
                makeRange(hourFrom, 23).map(hour => {
                  return (
                    <option key={"hour_to_" + hour} value={hour}>{twoDigits(hour)}</option>
                  )
                })
              }
            </select>:
            <select value={minuteTo}
                    className="z-select sm"
                    onChange={(e) => setTime(e, "to", "minute")}>
              {
                makeRange(0, 45, 15).map(minute => {
                  return (
                    <option key={"minute_to_" + minute} value={minute}>{twoDigits(minute)}</option>
                  )
                })
              }
            </select>
          </div>
        </div>
        <CustomButton buttonType="blueBase" className="mt-4 w-full" onClick={() => saveFromModal()}>Spara</CustomButton>
        <CustomButton buttonType="warning" className="mt-4 w-full" onClick={() => removeEvent()}>Ta bort</CustomButton>
      </>
    )
  }

  return (
    <>
      <Modal show={selectedEvent != null} onClose={() => saveFromModal()} size="lg">
        <Modal.Body className="bg-neutral-700 p-4 border border-neutral-300 rounded">
          <>
            {
              selectedEvent != null && renderPopupModal()
            }
          </>
        </Modal.Body>
      </Modal>

      <div className="bg-neutral-900 text-white font-hoves">
        <DnDCalendar
          components={settings.components}
          views={settings.views}
          defaultView={settings.defaultView}
          localizer={localizer}
          messages={messages}
          scrollToTime={scrollToTime}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{height: calHeight}}
          draggableAccessor={() => true}
          resizableAccessor={() => true}
          onSelectSlot={(e) => addEvent(e)}
          onEventDrop={(e) => onChangeEvent(e)}
          onEventResize={(e) => onChangeEvent(e)}
          onSelectEvent={(e: CalEventInterface) => editEvent(e)}
          onNavigate={(date: Date, view: string, action: string) => handleChangedDate(date, view, action)}
          eventPropGetter={(eventStyleGetter)}
          selectable={settings.selectable}
          popup={true}
          resizable={settings.resizeable}
          culture='sv'
          step={30}
        />
      </div>
    </>
  );
};

export default CalendarBig;
