import React from 'react';
import 'flowbite';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { NotificationProvider } from './contexts/notificationContext';
import { AuthContextProvider } from './contexts/AuthContext';
import AppRouter from './AppRouter';
import { NavigationProvider } from './contexts/NavigationContext';

import { UIContextProvider } from './contexts/UIContext';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <All />
);

function All() {
  const [outerRoute, setOuterRoute] = React.useState(null);
  return (
    <Auth0Provider
      domain="zebrain.eu.auth0.com"
      clientId="Ec9P4CVtrm3cIVjUCNou3BMcXXJIPfjm"
      authorizationParams={{
        audience: 'https://zebrain.eu.auth0.com/api/v2/',
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={
        (appState) => {
          console.log("appState", appState);
          if (appState) {
            setOuterRoute(appState.returnTo);
          }
        }
      }
    >
      <AuthContextProvider>
        <NotificationProvider>
          <NavigationProvider>
            <UIContextProvider>
              <AppRouter outerRoute={outerRoute} />
            </UIContextProvider>
          </NavigationProvider>
        </NotificationProvider>
      </AuthContextProvider>
    </Auth0Provider >
  );
}
