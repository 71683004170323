import * as bodySegmentation from '@tensorflow-models/body-segmentation'
// import '@mediapipe/selfie_segmentation'
import {ResourcesInterface, CanvasInterface} from "./interfaces";
// import {blurBodyPart} from "@tensorflow-models/body-segmentation/dist/shared/calculators/render_util";

interface BgImageInterface {
  image: string
  name_sv: string
  name_en: string
}

const foregroundColor = {r: 0, g: 0, b: 0, a: 0};
const backgroundColor = {r: 0, g: 0, b: 0, a: 255};
const useOwnMask = true
const logFps = false
let fpsCounter = 0
let t1 = performance.now()
import bgBeach from "shared-assets/images/video_background/beach.jpg"
import bgForest from "shared-assets/images/video_background/forest.jpg"
import bgKitchen from "shared-assets/images/video_background/kitchen.jpg"
import bgOffice from "shared-assets/images/video_background/office.jpg"
import bgOffice2 from "shared-assets/images/video_background/office2.jpg"
import bgSunset from "shared-assets/images/video_background/sunset.jpg"
import bgWall from "shared-assets/images/video_background/wall.jpg"


export const bgImages: Array<BgImageInterface> = [
  {
    image: '',
    name_en: 'No background',
    name_sv: 'Ingen bakgrund'
  },
  {
    image: 'blur',
    name_en: 'Blur',
    name_sv: 'Suddig'
  },
  {
    image: 'office',
    name_en: 'Office',
    name_sv: 'Kontor'
  },
  {
    image: 'office2',
    name_en: 'Office 2',
    name_sv: 'Kontor 2'
  },
  {
    image: 'kitchen',
    name_en: 'Kitchen',
    name_sv: 'Kök'
  },
  {
    image: 'forest',
    name_en: 'Forest',
    name_sv: 'Skog'
  },
  {
    image: 'wall',
    name_en: 'Wall',
    name_sv: 'Vägg'
  },
  {
    image: 'beach',
    name_en: 'Beach',
    name_sv: 'Strand'
  },
  {
    image: 'sunset',
    name_en: 'Sun set',
    name_sv: 'Solnedgång'
  }
]

function changeBackground(resources: ResourcesInterface) {
  if (resources.changeBackground) {
    let img = ''
    if (resources.background.length > 0 && resources.background !== 'blur') {
      // img = '/public/video_background/' + resources.background + '.jpg'
      if (resources.background === 'beach') {
        img = bgBeach
      } else if (resources.background === 'forest') {
        img = bgForest
      } else if (resources.background === 'kitchen') {
        img = bgKitchen
      } else if (resources.background === 'office') {
        img = bgOffice
      } else if (resources.background === 'office2') {
        img = bgOffice2
      } else if (resources.background === 'sunset') {
        img = bgSunset
      } else if (resources.background === 'wall') {
        img = bgWall
      }
    }

    if (img.length > 0) {
      const background = new Image();
      background.src = img

      background.onload = () => {
        if (resources.canvas) {
          const canvas: HTMLCanvasElement = document.createElement('canvas')
          canvas.width = resources.canvas.width
          canvas.height = resources.canvas.height
          const ctx = canvas.getContext('2d')
          if (ctx) {
            if (background.width > canvas.width && background.height > canvas.height) {
              const sx = (background.width - canvas.width) / 2
              // const sy = (background.height - canvas.height) / 2
              const sy = 0
              const w = canvas.width
              const h = canvas.height
              ctx.drawImage(background, sx, sy, w, h, 0, 0, w, h);
            } else {
              ctx.drawImage(background, 0, 0);
            }

            resources.canvas.backgroundData = ctx.getImageData(
              0, 0, resources.canvas.width, resources.canvas.height)
          }
        }
      }
    } else {
      console.log('No img')
      if (resources.canvas) {
        console.log('Restoring canvas')
        resources.canvas.backgroundData = new ImageData(resources.canvas.width, resources.canvas.height)
      }
    }

    resources.changeBackground = false
  }
}

export async function startBackground(resources: ResourcesInterface) {
  resources.stateFunc('info', 'Changing background...')
  resources.stateFunc('showInfo', true)
  if (resources.videoContainer && resources.videoElement?.videoWidth && resources.videoElement?.videoHeight) {

    const width = resources.videoElement.videoWidth
    const height = resources.videoElement.videoHeight
    resources.videoElement.width = width
    resources.videoElement.height = height

    const canvas = createCanvases(resources.videoContainer, width, height)
    resources.canvas = canvas
    resources.canvas.backgroundData = new ImageData(width, height)

    const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;

    canvas.segmenter = await bodySegmentation.createSegmenter(model, {
      runtime: 'mediapipe',
      // solutionPath: '@mediapipe/selfie_segmentation'
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation'
    });

    resources.videoElement?.classList.add('visibility_hidden')
    canvas.canvasOut.classList.remove('visibility_hidden')
    resources.stateFunc('arrangeVideos', '')
    if (resources.partners.length > 0) {
      connectCanvasStream(resources)
    }
    drawToCanvas(resources, width, height)
  }

}

export function connectCanvasStream(resources: ResourcesInterface) {
  // @ts-ignore
  // captureStream is still in draft for HTMLCanvasElement
  const canvasStream = resources.canvas?.canvasOut.captureStream()
  canvasStream?.getTracks().forEach((track: MediaStreamTrack) => {
    for (const partner of resources.partners) {
      partner.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
        if (sender.track !== null && sender.track.kind === 'video') {
          sender.replaceTrack(track).then(_result => {
            console.log('Replaced track')
          })
        }
      })
    }
  })
}

function backgroundToMask(bg: ImageData, m: ImageData) {
  const bgData = bg.data
  const mData = m.data
  let prevPerson = false
  for (let n = 0; n < mData.length; n += 4) {
    // n + 3 > 240 means pixel is outside person
    if (mData[n + 3] > 240) {
      mData[n] = bgData[n]
      mData[n + 1] = bgData[n + 1]
      mData[n + 2] = bgData[n + 2]
      // Previous pixel was person, set opacity in background to 127 to smoothen image
      if (prevPerson) {
        mData[n + 3] = 127
      }
      prevPerson = false
    } else {
      // Previous pixel was background, set opacity in background to 127 to smoothen image
      if (!prevPerson) {
        mData[n - 1] = 127
      }
      prevPerson = true
    }
  }
}

function mergeBackground(bg: ImageData, m: ImageData) {
  const bgData = bg.data
  const mData = m.data
  for (let n = 0; n < mData.length; n += 4) {
    // n + 3 > 240 means pixel is outside person
    if (mData[n + 3] > 128) {
      mData[n + 3] = 0
    } else {
      mData[n] = bgData[n]
      mData[n + 1] = bgData[n + 1]
      mData[n + 2] = bgData[n + 2]
      mData[n + 3] = 255 - mData[n + 3]
    }
  }
}

async function drawToCanvas(resources: ResourcesInterface, width: number, height: number) {
  const canvas = resources.canvas
  const videoElement = resources.videoElement
  if (canvas && videoElement) {
    if (!canvas.doLoop) {
      console.log('Stopping animation loop')
      return
    }

    if (logFps) {
      fpsCounter++
      if (fpsCounter % 20 === 0) {
        const t2 = performance.now()
        console.log('20 frames took ' + (t2 - t1))
        t1 = t2
      }
    }

    if (resources.background === 'blur') {
      const people = await canvas.segmenter.segmentPeople(videoElement, {flipHorizontal: false})
      await bodySegmentation.drawBokehEffect(canvas.canvasOut, videoElement,
        people, 0.5, 3);
    } else {
      if (useOwnMask) {
        if (canvas.ctxTemp) {
          canvas.ctxTemp.drawImage(videoElement, 0, 0)
          const videoImage = canvas.ctxTemp.getImageData(0, 0, width, height)
          const people = await canvas.segmenter.segmentPeople(videoImage, {flipHorizontal: false})
          let maskImage: ImageData = await people[0].mask.toImageData()
          mergeBackground(canvas.backgroundData, maskImage)
          await bodySegmentation.drawMask(canvas.canvasOut, videoElement, maskImage, 1, 0)
        }
      } else {
        const people = await canvas.segmenter.segmentPeople(videoElement, {flipHorizontal: false})
        const backgroundMask = await bodySegmentation.toBinaryMask(people, foregroundColor, backgroundColor,
          false, 0.5);

        if (canvas.backgroundData) {
          backgroundToMask(canvas.backgroundData, backgroundMask)
          await bodySegmentation.drawMask(canvas.canvasOut, videoElement, backgroundMask, 1, 0);
        }
      }
    }


    if (resources.changeBackground) {
      changeBackground(resources)
      resources.stateFunc('showInfo', false)
    }

    canvas.animFrameId = requestAnimationFrame(function() {
      drawToCanvas(resources, width, height)
    })

  } else {
    console.log('!!!No canvas to draw on')
  }
}

export function stopBackground(resources: ResourcesInterface) {

  if (resources.canvas) {
    resources.canvas.doLoop = false
    cancelAnimationFrame(resources.canvas.animFrameId)
    console.log('Stopping ' + resources.canvas.animFrameId)

    // Allow for loops to finish
    setTimeout(() => {
      if (resources.videoContainer && resources.canvas) {
        resources.videoContainer.removeChild(resources.canvas.canvasOut)
        resources.canvas.canvasOut.remove()
        resources.glStructs = undefined
      }

      resources.canvas = undefined
      resources.videoElement?.classList.remove('visibility_hidden')
    }, 200)


  }
}

function createCanvases(container: HTMLElement, width: number, height: number) {
  const canvasOut: HTMLCanvasElement = document.createElement('canvas')
  const canvasTemp: HTMLCanvasElement = document.createElement('canvas')
  canvasTemp.width = width
  canvasTemp.height = height

  canvasOut.width = width
  canvasOut.height = height
  canvasOut.classList.add('visibility_hidden')
  canvasOut.classList.add('video_mirrored')
  canvasOut.setAttribute('style', 'position: absolute; top: 1px; left: 1px; z-index: 901')

  container.append(canvasOut)

  const canvas: CanvasInterface = {
    canvasOut: canvasOut,
    ctxTemp: canvasTemp.getContext('2d'),
    width: width,
    height: height,
    segmenter: undefined,
    animFrameId: undefined,
    doLoop: true,
  }
  return canvas
}
