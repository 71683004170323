import React, {useState, useEffect, useRef} from 'react';
import { Modal } from 'flowbite-react';
import CustomButton from "../CustomButton";
import Api from "../../services/Api";
import {CONFIG} from "../../constants";

interface Props {
  picture: string;
  close: Function
  lang: string
  who: string
}

const EditPicture: React.FC<Props> = ({ picture, close, lang, who }) => {
  const api = Api.getInstance();
  const rawImage = useRef<HTMLImageElement|null>(null);
  const [renderedPic, setRenderedPic] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(100)
  const [offsetX, setOffsetX] = useState<number>(0)
  const [offsetY, setOffsetY] = useState<number>(0)

  useEffect(() => {
    if (picture) {
      fetch(CONFIG.FILE_HOST + picture, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        // headers: {'Content-Type': 'image/jpeg'},
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
      }).then(result => {
        result.blob().then(blob => {
          const reader = new FileReader() ;
          reader.onload = function() {
            createRawImage(this.result.toString())
          }
          reader.readAsDataURL(blob) ;
        })
      })
    }
  }, [])

  useEffect(() => {
    if (rawImage) {
      setChanged(true)
      renderFromRaw()
    }
  }, [scale, offsetX, offsetY])

  const createRawImage = (src: string) => {
    let img = document.createElement("img")
    img.classList.add("hidden")
    img.src = src
    rawImage.current = img
    img.addEventListener("load", (e) => {
      renderFromRaw()
    })
  }

  const renderFromRaw = ()=> {
    if (rawImage.current === null) {
      console.log('rawImage not loaded yet')
      return
    }
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');
    const nWidth = rawImage.current.naturalWidth;
    const nHeight = rawImage.current.naturalHeight;
    const iWidth = rawImage.current.width;
    const iHeight = rawImage.current.height;
    const cx = iWidth / 2;
    const cy = iHeight / 2;
    const radius = (100 / scale) * Math.min(iWidth, iHeight) / 2
    const sWidth = 2 * radius * nWidth / iWidth;
    const sHeight = 2 * radius * nHeight / iHeight;
    let sx = (cx - radius) * nWidth / iWidth;
    let sy = (cy - radius) * nHeight / iHeight;
    if (offsetX != 0) {
      sx = sx + offsetX * sx / 50
    }
    if (offsetY != 0) {
      sy = sy + offsetY * sy / 50
    }

    if (ctx !== null) {
      ctx.drawImage(rawImage.current, sx, sy, sWidth, sHeight, 0, 0, 200, 200);
      setRenderedPic(canvas.toDataURL('image/jpeg'))
    }
  }

  const saveImage = () => {
    const data = {
      picture: renderedPic
    }
    api.post('local/upload_picture/' + who, data).then(result => {
      close(result.json.picture)
    })
  }

  const uploadImage = (evt: any) => {
    if (evt === null) {
      alert('Empty event');
      return;
    }

    const picFile = evt.target.files[0];
    if (!picFile) {
      console.log('No pic file')
      return
    }

    if (!picFile.type.match('image.*')) {
      alert('This is not an image');
      return;
    }

    const reader = new FileReader();

    reader.onload = ((theFile) => {
      return (e: any) => {
        setChanged(true)
        createRawImage(e.target.result)
      };
    })(picFile);
    reader.readAsDataURL(picFile);
  }

  return (
    <>
      <Modal show={true} onClose={() => close(changed)} size="md">
        <Modal.Header className="bg-neutral-700 font-medium text-xl p-4">
          Profile picture
        </Modal.Header>
        <Modal.Body className="bg-neutral-700 p-4">
          <>
            {
              renderedPic.length > 0 ? (
                <>
                  <div>
                    <div>Storlek:</div>
                    <input className="mt-2" type="range" min="100" max="600" value={scale}
                           onChange={(e) => setScale(parseInt(e.target.value))}
                           id="myRange"/>
                  </div>


                  <div className="flex justify-center mt-4">
                    <img alt="picture" style={{borderRadius: "50%"}} src={renderedPic}/>
                    <input disabled={scale == 100} className="mt-4" type="range" min="-50" max="50" value={offsetY}
                           style={{transform: "rotate(90deg)"}}
                           onChange={(e) => setOffsetY(parseInt(e.target.value))}/>
                  </div>

                  <input disabled={scale == 100} className="mt-4" type="range" min="-50" max="50" value={offsetX}
                         onChange={(e) => setOffsetX(parseInt(e.target.value))}/>


                </>
              ) : (
                <div style={{width: "200px", height: "200px"}} className="p-4">
                  Ladda upp en ny bild här...
                </div>
              )
            }

            <div className="cursor-pointer mt-4">
              <input id="profileFile" type="file" className="hidden" onChange={(e: any) => uploadImage(e)}/>
              <label htmlFor="profileFile" className="underline">
                Välj bild
              </label>
            </div>

            {
              changed &&
                <CustomButton extraStyle="mt-4 w-full" onClick={() => saveImage()}>Spara</CustomButton>
            }
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPicture;
