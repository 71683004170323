import React, { useState, useEffect } from 'react';
import './CalendarPage.css'; // Assuming you're using some styles

const CalendarPage = () => {
  const [currentMonth, setCurrentMonth] = useState(9); // Start with October (index 9)
  const [currentYear, setCurrentYear] = useState(2024); // Start with 2024
  const [selectedDate, setSelectedDate] = useState<{ day: number; month: number; year: number } | null>(null); // Track selected date
  const [highlightedDates, setHighlightedDates] = useState<number[]>([25]); // Dynamically set highlighted dates
  const [paddingDays, setPaddingDays] = useState<number[]>([]);

  // Define event dates (adjust the dates as needed)
  const eventDates = [
    { day: 5, month: 9, year: 2024 },  // October 5, 2024
    { day: 10, month: 9, year: 2024 }, // October 10, 2024
    { day: 15, month: 9, year: 2024 }, // October 15, 2024
  ];

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  const daysInMonth = (month: number, year: number) => new Date(year, month + 1, 0).getDate();
  const weekDayOfFirst = (month: number, year: number) => {
    let dayOfWeek = new Date(year, month, 1).getDay();
    // Sunday is day 0 in javascript :-/
    // Translate so that Monday is 0 and Sunday is 6
    if (dayOfWeek === 0) {
      // Javascript day 0 is Sunday - return 6
      return 0
    } else {
      return dayOfWeek - 1
    }
  }
  const today = new Date();

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    setDays(Array.from({ length: daysInMonth(currentMonth, currentYear) }, (_, i) => i + 1));
    const weekDay = weekDayOfFirst(currentMonth, currentYear);
    console.log('Weekday of 1st is ' + weekDay);
    setPaddingDays(Array.from({length: weekDay}, (_, i) => i + 1));
  }, [currentMonth, currentYear]);

  // Function to dynamically highlight specific dates
  const highlightTargetDay = (day: number) => {
    if (highlightedDates.includes(day)) {
      return 'border-white text-white hover:border-blue-600 hover:text-blue-600';
    }
    return '';
  };

  // Apply style for today's date
  const isToday = (day: number) => {
    return day === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear()
      ? 'text-blue-600'
      : 'text-white';
  };

  // Apply style for the selected date
  const isSelectedDay = (day: number) => {
    return selectedDate &&
      day === selectedDate.day &&
      currentMonth === selectedDate.month &&
      currentYear === selectedDate.year
      ? 'bg-blue-600 text-white'
      : '';
  };

  // Check if the day has an event
  const hasEvent = (day: number) => {
    return eventDates.some(
      (event) => event.day === day && event.month === currentMonth && event.year === currentYear
    );
  };

  // Handle date click event
  const handleDateClick = (day: number) => {
    setSelectedDate({ day, month: currentMonth, year: currentYear }); // Store selected date
  };

  // Handle previous month click
  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1); // Decrement year if going back from January
    } else {
      setCurrentMonth((prev) => prev - 1);
    }
  };

  // Handle next month click
  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1); // Increment year if going forward from December
    } else {
      setCurrentMonth((prev) => prev + 1);
    }
  };

  // Handle month selection from dropdown
  const handleMonthSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentMonth(Number(e.target.value));
  };

  // Handle year selection from dropdown
  const handleYearSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentYear(Number(e.target.value));
  };

  return (
    <div className="sm:flex">
      <div className="p-3 space-y-0.5 w-full">
        {/* Month and Year Navigation */}
        <div className="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
          <div className="col-span-1">
            <button
              type="button"
              onClick={handlePreviousMonth}
              className="size-8 flex justify-center items-center text-white hover:bg-gray-100 rounded-full"
            >
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
            </button>
          </div>

          <div className="col-span-3 flex justify-center items-center gap-x-1">
            {months[currentMonth]} {currentYear}
            {/* <select
              value={currentMonth}
              onChange={handleMonthSelect}
              className="bg-white border border-gray-300 rounded-lg px-2 py-1 text-neutral-400"
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </select>
            <span>/</span>
            <select
              value={currentYear}
              onChange={handleYearSelect}
              className="bg-white border border-gray-300 rounded-lg px-2 py-1 text-neutral-400"
            >
              {Array.from({ length: 10 }, (_, i) => currentYear - 5 + i).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select> */}
          </div>

          <div className="col-span-1 flex justify-end">
            <button
              type="button"
              onClick={handleNextMonth}
              className="size-8 flex justify-center items-center text-white hover:bg-gray-100 rounded-full"
            >
              <svg
                className="shrink-0 size-4"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        </div>

        {/* Days of the Week */}
        <div className="grid grid-cols-7 pb-1.5">
          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (
            <span key={day} className="m-px w-10 block text-center text-sm text-white">
              {day}
            </span>
          ))}
        </div>

        {/* Calendar Days */}
        <div className="grid grid-cols-7">
          {
            paddingDays.map(paddingDay => (
              <span key={"p_" + paddingDay}></span>
            ))
          }
          {days.map((day) => (
            <button
              key={day}
              onClick={() => handleDateClick(day)} // Handle click
              className={`relative m-px size-10 flex flex-col justify-center items-center border border-transparent text-sm rounded-full hover:bg-blue-600 hover:text-white ${highlightTargetDay(
                day
              )} ${isToday(day)} ${isSelectedDay(day)}`}
            >
              <span>{day}</span>
              {hasEvent(day) && (
                <span className="absolute bottom-1 w-1.5 h-1.5 bg-blue-600 rounded-full"></span>
              )}
            </button>
          ))}
        </div>

        {/* Selected Date Display */}
        {selectedDate && (
          <div className="mt-4">
            <p className="text-white">
              Selected Date: {selectedDate.day}/{selectedDate.month + 1}/{selectedDate.year}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarPage;
