import React, {useState, useEffect} from 'react';
import { Modal } from 'flowbite-react';
import {BookedMeeting, CalEventInterface} from "../../interfaces/common";
import CustomButton from "../CustomButton";
import {calculateEndTime, dateFormatDate, dateFormatHourMinute} from "../../helpers/dateFunctions";
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconClock from "shared-assets/icons/clock.svg";
import iconBadgeCheck from "shared-assets/icons/badge-check.svg";
import Api from "../../services/Api";
import CalendarBig from "../CalendarBig";

interface AvailInterface {
  from: Date
  to: Date
}

interface Props {
  meeting: BookedMeeting;
  close: Function
  lang: string
}

const BookMeeting: React.FC<Props> = ({ meeting, close, lang }) => {
  const api = Api.getInstance();
  const [txt, setTxt] = useState<string>('');
  const [booked, setBooked] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [newMeeting, setNewMeeting] = useState<CalEventInterface>(null)
  const [avails, setAvails] = useState<Array<AvailInterface>>([]);

  useEffect(() => {
    api.get('local/coach/availability/all').then((result) => {
      const newAvails: Array<AvailInterface> = []
      for (const recurring of result.json.available) {
        const from = new Date(recurring.from)
        const to = new Date(recurring.to)
        newAvails.push({
          from: from,
          to: to
        })
      }
      setAvails(newAvails)
    })
  }, [])

  useEffect(() => {
    if (avails.length > 0) {
      setShowCalendar(true)
    }
  }, [avails])

  const doBook = () => {
    const data = {
      id: meeting.id,
      client_id: meeting.clientId,
      from: newMeeting.start,
      length: meeting.length,
      message: txt
    }
    api.post('local/meeting/book', data).then(response => {
      setBooked(true);
    })
  }

  const getBookableMeetings = () => {
    const startEvents: Array<CalEventInterface> = []
    let id = 1
    const meetingInSeconds = meeting.length * 60
    const pauseInSeconds = 15 * 60
    for (const d of avails) {
      let start = new Date(d.from)
      while ((d.to.getTime() - start.getTime()) / 1000 >= meetingInSeconds) {
        let end = new Date(start)
        end.setSeconds(end.getSeconds() + meetingInSeconds)
        startEvents.push({
          id: id,
          title: "M",
          start: start,
          end: end
        })
        id++
        start = new Date(start)
        start.setSeconds(start.getSeconds() + meetingInSeconds + pauseInSeconds)
      }

    }
    return startEvents
  }

  const showContent = () => {
    if (booked) {
      return (
        <div className="p-4">
          <div className="my-12 flex justify-center items-center">
            <img className="icon-xl" alt="check" src={iconBadgeCheck} />
          </div>
          <div className="text-neutral-300">
            Ditt möte har {meeting.id === 0 ? 'bokats' : 'ändrats'} och din klient uppdaterats.
          </div>
        </div>
      )
    } else if (newMeeting) {
      return (
        <div className="p-4">
          <div className="font-medium">
            {meeting.firstName} {meeting.lastName}
          </div>
          <div className="mt-4 flex items-center">
            <img alt="calendar" className="icon" src={iconCalendar} />
            <div className="ml-2">{dateFormatDate(newMeeting.start, false, lang)}</div>
          </div>
          <div className="mt-4 flex items-center">
            <img className="icon" alt="Time" src={iconClock}/>
            <span className="ml-2">
                {dateFormatHourMinute(newMeeting.start)} - {dateFormatHourMinute(newMeeting.end)}
              </span>
          </div>

          <div className="mt-4">
            <textarea value={txt} className="bg-neutral-700 text-neutral-300 rounded w-full h-24"
                      placeholder="Skriv meddelande till klienten..."
                   onChange={(e) => setTxt(e.target.value)} autoComplete="off" />
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  const selectMeetingTime = (e: CalEventInterface) => {
    setNewMeeting(e)
  }

  useEffect(() => {
    if (newMeeting) {
      setConfirm(true)
    }
  }, [newMeeting])

  const showConfirmText = () => {
    if (booked) {
      if (meeting.id === 0) {
        return "Mötet har bokats"
      } else {
        return "Mötet har ändrats"
      }
    } else {
      if (meeting.id === 0) {
        return "Bekräfta bokning"
      } else {
        return "Bekräfta ändring"
      }
    }
  }

  return (
    <>
      <Modal show={confirm} onClose={() => setConfirm(false)} size="md">
        <Modal.Header className="bg-neutral-700 font-medium text-xl p-4">
          {showConfirmText()}
        </Modal.Header>
        <Modal.Body className="bg-neutral-700">
          <>
            {showContent()}

            <hr className="border-neutral-600"/>

            <div className="p-4">
              {
                booked ? (
                  <CustomButton extraStyle="w-full" onClick={() => close(booked)}>Ok</CustomButton>
                ) : (
                  <CustomButton extraStyle="w-full" onClick={() => doBook()}>
                    {meeting.id === 0 ? "Boka möte" : "Ändra möte"}
                  </CustomButton>
                )
              }
            </div>

          </>
        </Modal.Body>
      </Modal>

      <Modal show={showCalendar} onClose={() => {
        close(false);
      }} size="4xl">
        <Modal.Header className="bg-neutral-700 p-4 max-w-4xl">
          {
            meeting &&
              <>
                  Välj ny mötestid för {meeting.firstName}
                  (från {dateFormatDate(meeting.startDate, true, lang)})
              </>
          }
        </Modal.Header>
        <Modal.Body className="bg-neutral-700">
          {
            showCalendar &&
              <CalendarBig startEvents={getBookableMeetings()} click={selectMeetingTime}
                           calType="book_meeting"/>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookMeeting;
