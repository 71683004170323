import React from 'react';
import { ResourcesInterface } from "./interfaces";
import Api from "../../services/Api";

interface ComponentProps {
  resources: ResourcesInterface
  audioDeviceId: string
  micOn: boolean
}

interface ComponentState {
  micVolume: number
}

export default class TestSound extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance();
  private doLoop = true;
  volumeBoxes: Array<number> = []


  state = {
    micVolume: 0,
  }

  componentDidMount() {
    for (let i = 0; i < 100; i += 4) {
      this.volumeBoxes.push(i)
    }

    console.log('Mounted test sound, audioDeviceId ' + this.props.audioDeviceId)
    if (this.props.resources.localStream !== undefined) {
      this.displayTestVolume(this.props.resources.localStream)
    }
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>) {
    if (prevProps.audioDeviceId !== this.props.audioDeviceId) {
      console.log('component did update TestSound')
      this.doLoop = false
      setTimeout(() => {
        if (this.props.resources.localStream !== undefined) {
          this.doLoop = true
          this.displayTestVolume(this.props.resources.localStream)
        } else {
          console.warn('------ no localStream')
        }
      }, 500)
    }
  }

  componentWillUnmount() {
    this.doLoop = false;
    console.log('Unmounted test sound')
  }

  displayTestVolume = (stream: MediaStream) => {
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);
    const destination = audioContext.createMediaStreamDestination();
    const analyser = audioContext.createAnalyser();
    analyser.connect(destination);

    const audio = document.createElement('audio');
    audio.srcObject = destination.stream;
    audio.autoplay = false;
    mediaStreamSource.connect(destination);
    mediaStreamSource.connect(analyser);

    analyser.fftSize = 1024;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    requestAnimationFrame(() => {
      this.drawSound(analyser, bufferLength, dataArray);
    })
  }

  drawSound = (analyser: any, bufferLength: number, dataArray: Uint8Array) => {
    if (!this.doLoop) {
      return;
    }

    analyser.getByteFrequencyData(dataArray)
    let total = 0;
    for(let i = 0; i < bufferLength; i++) {
      total += dataArray[i];
    }

    let volume = Math.sqrt(total / bufferLength) * 10;
    this.setState({ micVolume: volume });

    requestAnimationFrame(() => {
      this.drawSound(analyser, bufferLength, dataArray);
    })
  }

  render() {

    return (
      <div className="top_margin">
        {
          !this.props.micOn ? (
          <div className="top_margin white">
            You have muted your microphone...
          </div>
          ) : (
            <div className="top_margin white">
              Volume:
            </div>
          )
        }

        <div className="test_sound_volume_container top_margin">
          {
            this.volumeBoxes.map(vol => {
              return (
                <div className={"test_sound_volume_box " + (this.state.micVolume > vol ? "active" : "")}
                     key={"box_" + vol}/>
              )
            })
          }
        </div>
        <div className="top_margin">
          {
            this.props.micOn &&
              <>
                {
                  this.state.micVolume === 0 ? (
                  <div>
                    <h4>Om mätaren inte rör sig</h4>
                    <ul>
                      <li>
                        Höj volymen på din mikrofon
                      </li>
                      <li>Byt hörlurar</li>
                      </ul>
                    </div>
                  ) : (
                    <div className="white">
                      &nbsp;
                    </div>
                  )
                }
              </>
          }
        </div>
      </div>
    );
  }

}
