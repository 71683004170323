import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { useAuth0 } from '@auth0/auth0-react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { ClientDataContextProvider } from './contexts/ClientDataContext';
import Navbar from './components/Navbar';
import Breadcrumbs from './components/Breadcrumbs';
import * as Sentry from "@sentry/react";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}
const firebaseConfig = {
  apiKey: "AIzaSyBwYtmQG33PnzAFxpplqpBpGye11ttFBDc",
  authDomain: "zebrain.firebaseapp.com",
  projectId: "zebrain",
  storageBucket: "zebrain.appspot.com",
  messagingSenderId: "337432544873",
  appId: "1:337432544873:web:32dabdb379af35ced284f8"
};

const app = initializeApp(firebaseConfig);
const DB = getFirestore(app);

// if not localhost
if (window.location.hostname !== "localhost" && window.location.hostname !== "stage.zebrain.se") {
  Sentry.init({
    dsn: "https://3436c77ca5096a2502811a7920a68314@o4507441234575360.ingest.de.sentry.io/4507583744049232",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navbarRef = useRef(null);


  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  // const { isAuthenticated, isLoading } = useAuth0();
  const isAuthenticated = true
  const isLoading = false

  useEffect(() => {
    const isAuth = isAuthenticated && !isLoading;
    if (!isLoading) {
      // TODO: SHOULD BE DEPENDED ON THE LOCAL USER STATE
      if (isAuth && location.pathname === "/auth") {
        const redirectTo = searchParams.get('redirectTo');
        if (redirectTo) {
          navigate(redirectTo);
        }
      } else if (!isAuth) {

      }
    }
  }, [isLoading, isAuthenticated]);

  return (
    <ClientDataContextProvider>
      <div className='font-hoves'>
      {
        location.pathname === "/meeting" ? (
          <Outlet/>
        ) : (
          <div className="h-screen w-screen flex flex-col ">
            <div ref={navbarRef}>
              <Navbar/>
            </div>
            <div className="w-full h-full flex justify-center min-h-0 overflow-y-auto">
              <div className="flex flex-col justify-start align-middle w-full desktop:w-151 mx-4 min-h-0">
                <Breadcrumbs/>
                <Outlet/>
                &nbsp;
              </div>
            </div>
          </div>
        )
      }
      </div>
    </ClientDataContextProvider>
  );
}

export {App, DB};