import '../../lang/i18n';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import CustomButton from "../../components/CustomButton";
import Api from "../../services/Api";

export default function AvailabilityOther() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t } = translation;
  const [ahead, setAhead] = useState<number>(0);
  const [startInterval, setStartInterval] = useState<number>(0);
  const [maxBooking, setMaxBooking] = useState<number>(1);
  const [bufferTime, setBufferTime] = useState<number>(0);
  useEffect(() => {
    api.get('local/meeting_settings').then((result) => {
      setAhead(result.json.ahead)
      setStartInterval(result.json.startInterval)
      setMaxBooking(result.json.maxBooking)
      setBufferTime(result.json.bufferTime)
    })
  }, [])


  const save = () => {
    const data = {
      startInterval: startInterval,
      ahead: ahead,
      maxBooking: maxBooking,
      bufferTime: bufferTime,
    }
    api.post('local/meeting_settings', data).then((res) => {
      toast("Sparade dina profilinställningar")
    })
  }

  const timesAhead = [0, 1, 2, 4, 8, 24]
  const maxBookings = [1, 2, 3, 4, 5, 6, 7, 8]
  const bufferTimes = [0, 5, 10, 15, 20, 25, 30, 45]

  return (
    <>
      <Toaster />
      <p className="mt-6 text-lg font-medium">{t('time-before-booking')}</p>
      <p className="text-neutral-300">{t('time-before-booking-set')}</p>
      <p className="mt-4 font-medium">{t('time-head')}</p>

      <select onChange={(e) => setAhead(parseInt(e.target.value))}
              className="mt-2 bg-neutral-900 rounded" value={ahead}>
        {
          timesAhead.map(hours => {
            return (
              <option value={hours} key={"hours_" + hours}>{hours} {t('hours')}</option>
            )
          })
        }
      </select>

      <p className="mt-6 text-lg font-medium">{t('start-interval')}</p>
      <p className="text-neutral-300">{t('start-interval-set')}</p>
      <p className="mt-4 font-medium">{t('time-interval')}</p>

      <select onChange={(e) => setStartInterval(parseInt(e.target.value))}
              className="mt-2 bg-neutral-900 rounded" value={startInterval}>
        <option value={15}>Varje kvart</option>
        <option value={30}>Varje halvtimme</option>
        <option value={60}>Varje heltimme</option>
      </select>

      <p className="mt-6 text-lg font-medium">{t('max-bookings')}</p>
      <p className="text-neutral-300">{t('max-bookings-set')}</p>
      <p className="mt-4 font-medium">{t('max-per-day')}</p>

      <select onChange={(e) => setMaxBooking(parseInt(e.target.value))}
              className="mt-2 bg-neutral-900 rounded" value={maxBooking}>
        {
          maxBookings.map(hours => {
            return (
              <option value={hours} key={"max_" + hours}>{hours}</option>
            )
          })
        }
      </select>

      <p className="mt-6 text-lg font-medium">{t('buffer-time')}</p>
      <p className="text-neutral-300">{t('buffer-time-set')}</p>
      <p className="mt-4 font-medium">{t('margin-after-meeting')}</p>

      <select onChange={(e) => setBufferTime(parseInt(e.target.value))}
              className="mt-2 bg-neutral-900 rounded" value={bufferTime}>
        {
          bufferTimes.map(m => {
            return (
              <option value={m} key={"max_" + m}>{m} {t('minutes')}</option>
            )
          })
        }
      </select>

      <CustomButton
        onClick={() => save()}
        extraStyle="w-full mt-6"
      >
        Spara
      </CustomButton>
    </>
  )
};