import React, {ChangeEvent, useEffect, useState} from "react";
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import Api from "../../services/Api";
import {CONFIG} from "../../constants";
import profileIcon from "../../../assets/images/icons/profile.svg"
import closeIcon from "../../../assets/images/icons/close.svg"
import pictureCircleIcon from "shared-assets/icons/picture-circle.svg"
import CustomButton from "../../components/CustomButton";
import EditPicture from "../../components/EditPicture/EditPicture";

interface CoachLanguageInterface {
  language: string;
  intro: string;
  expertise: string;
  experience: string;
  philosophy: string;
}

interface ProfileInterface {
  firstName: string;
  lastName: string;
  email: string;
  picture?: string;
  languages: Array<CoachLanguageInterface>
}

export default function Profile() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [profile, setProfile] = useState<ProfileInterface>()
  const [selectedLanguage, setSelectedLanguage] = useState<string>("")
  const [profileIntro, setProfileIntro] = useState<string>("")
  const [profileExpertise, setProfileExpertise] = useState<string>("")
  const [profileExperience, setProfileExperience] = useState<string>("")
  const [profilePhilosophy, setProfilePhilosophy] = useState<string>("")
  const [picture, setPicture] = useState<boolean>(false)
  const { t } = translation;
  const coachLanguages = [ "se", "en", "fr", "de", "fi", "es" ]

  useEffect(() => {
    api.get('local/profile').then((result) => {
      setProfile(result.json.profile)
      if (result.json.profile.languages.length > 0) {
        const firstLang: CoachLanguageInterface = result.json.profile.languages[0]
        setInputFromLanguage(firstLang)
      }
      setLoaded(true)
    })
  }, [])

  const save = () => {
    // The pain! To ensure we get the latest changes, we need to look at both profile.languages and e.g profileIntro
    const languages: Array<CoachLanguageInterface> = []
    for (const language of profile.languages) {
      if (language.language === selectedLanguage) {
        languages.push({
          language: selectedLanguage,
          intro: profileIntro,
          expertise: profileExpertise,
          experience: profileExperience,
          philosophy: profilePhilosophy
        })
      } else {
        languages.push(language)
      }
    }

    const data = {
      "languages": languages
    }

    api.post('local/profile/languages/save', data).then((result) => {
      alert("Saved data")
    })
  }

  const deleteLanguage = (language: string) => {

    api.get('local/profile/language/delete/' + language).then((result) => {
      const newProfile = structuredClone(profile)
      const languages: Array<CoachLanguageInterface> = []
      for (const lang of profile.languages) {
        if (lang.language != language) {
          languages.push(lang)
        }
      }
      newProfile.languages = languages
      setProfile(newProfile)
      if (language === selectedLanguage) {
        if (languages.length > 0) {
          setSelectedLanguage(languages[0].language)
        } else {
          setSelectedLanguage("")
        }

      }
    })
  }

  const setInputFromLanguage = (coachLanguage: CoachLanguageInterface) => {
    setSelectedLanguage(coachLanguage.language)
    setProfileIntro(coachLanguage.intro)
    setProfileExpertise(coachLanguage.expertise)
    setProfileExperience(coachLanguage.experience)
    setProfilePhilosophy(coachLanguage.philosophy)
  }

  useEffect(() => {
    // When changing language, check if we should add new, set to empty or use existing

    // Used when adding new language or setting language to "Choose language"
    const emptyLanguage: CoachLanguageInterface = {
      language: selectedLanguage,
      intro: '',
      expertise: '',
      experience: '',
      philosophy: ''
    }

    if (selectedLanguage === "") {
      setInputFromLanguage(emptyLanguage)
    } else {
      let foundLangTexts: CoachLanguageInterface|null = null
      for (let l of profile.languages) {
        if (l.language === selectedLanguage) {
          foundLangTexts = l
          break
        }
      }
      if (foundLangTexts) {
        setInputFromLanguage(foundLangTexts)
      } else {
        const newProfile = structuredClone(profile)
        setInputFromLanguage(emptyLanguage)
        newProfile.languages.push(emptyLanguage)
        setProfile(newProfile)
      }
    }

  }, [selectedLanguage])

  const updatePreviousLanguage = () => {
    // Take the current values for intro, expertise, etc, and insert into the current profile
    const newProfile = structuredClone(profile)
    for (let l of newProfile.languages) {
      if (l.language == selectedLanguage) {
        l.intro = profileIntro
        l.expertise = profileExpertise
        l.experience = profileExperience
        l.philosophy = profilePhilosophy
        break
      }
    }
    setProfile(newProfile)
  }

  const selectLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value
    // Check if we should insert previous data into profile structure
    updatePreviousLanguage()

    setSelectedLanguage(language)
  }

  const editLanguage = (e: ChangeEvent<HTMLTextAreaElement>, what: string) => {
    if (what === "intro") {
      setProfileIntro(e.target.value)
    } else if (what === "expertise") {
      setProfileExpertise(e.target.value)
    } else if (what === "experience") {
      setProfileExperience(e.target.value)
    } else if (what === "philosophy") {
      setProfilePhilosophy(e.target.value)
    }
  }

  if (!loaded) {
    return (<div className="mt-4">Loading profile...</div>)
  }

  const closeEditPicture = (picture: string)=> {
    console.log('Picture changed: ' + picture)
    if (picture && picture.length > 0) {
      const newProfile = {...profile}
      newProfile.picture = picture
      setProfile(newProfile)
    }
    setPicture(false)
  }

  return (
    <>
      {
        picture &&
        <EditPicture picture={profile.picture} close={closeEditPicture} lang={"se"} who={"coach"}/>
      }
      <h1 className="text-2xl font-medium dark:text-white mt-4">
        {t('settings')}
      </h1>

      <div className="flex mt-6 items-center">
        <div className="relative cursor-pointer" onClick={() => setPicture(true)}>
          <img className="picture-lg" alt="pic"
               src={profile.picture ? (CONFIG.FILE_HOST + profile.picture) : profileIcon}/>
          <img alt="pic-icon" style={{position: "absolute", zIndex: 1001, bottom: "0px", right: "0px"}}
               src={pictureCircleIcon}/>
        </div>
        <div className="ml-4">
          <p className="text-xl font-medium">{profile.firstName} {profile.lastName}</p>
          <p className="text-neutral-400">{profile.email}</p>
        </div>
      </div>

      <div className="mt-6 text-xl font-medium">{t('language')}</div>

      <div className="mt-2">{t('choose-coaching-language')}</div>

      <select onChange={(e) => selectLanguage(e)}
              className="bg-neutral-900 rounded mt-2" value={selectedLanguage}>
        <option value="">{t("select-language")}</option>
        {
          coachLanguages.map(l => {
            return (
              <option value={l} key={"lang_" + l}>{t(l)}</option>
            )
          })
        }
      </select>

      {
        profile.languages.length > 0 &&
        <div className="flex mt-2">
          {
            profile.languages.map(lang => {
              return (
                <div key={"edit_" + lang.language} onClick={() => deleteLanguage(lang.language)}
                     className="bg-neutral-700 rounded py-1 px-4 mr-2 flex items-center">
                  <div className="text-neutral-300 text-sm font-medium">{t(lang.language)}</div>
                  <img className="ml-4 h-4" alt="delete" src={closeIcon} />
                </div>
              )
            })
          }
        </div>
      }

      <div className="mt-6 text-xl font-medium">Presentation</div>

      {
        selectedLanguage != "" &&
          <div className="mt-6">
              <div className="mt-2 font-medium">{t('profile-intro')}</div>
              <textarea onChange={(e) => editLanguage(e, 'intro')}
                        placeholder="Write text here..."
                        value={profileIntro}
                        className="bg-neutral-900 rounded mt-4 w-full h-40"/>

              <div className="mt-2 font-medium">{t('profile-expertise')}</div>
              <textarea onChange={(e) => editLanguage(e, 'expertise')}
                        placeholder="Write text here..."
                        value={profileExpertise}
                        className="bg-neutral-900 rounded mt-4 w-full h-40"/>

              <div className="mt-2 font-medium">{t('profile-experience')}</div>
              <textarea onChange={(e) => editLanguage(e, 'experience')}
                        placeholder="Write text here..."
                        value={profileExperience}
                        className="bg-neutral-900 rounded mt-4 w-full h-40"/>

              <div className="mt-2 font-medium">{t('profile-philosophy')}</div>
              <textarea onChange={(e) => editLanguage(e, 'philosophy')}
                        placeholder="Write text here..."
                        value={profilePhilosophy}
                        className="bg-neutral-900 rounded mt-4 w-full h-40"/>

          </div>
      }

      {
        profile.languages.length > 0 &&
          <CustomButton
              onClick={() => save()}
              extraStyle="w-full my-4"
              buttonType="outlineWhiteBase"
          >
            {t('save')}
          </CustomButton>
      }

    </>
  );
};