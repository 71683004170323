import React, { createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithCustomToken
} from "firebase/auth";
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from "@sentry/react";
import {CONFIG} from "../constants";


const firebaseConfig = {
  apiKey: "AIzaSyBwYtmQG33PnzAFxpplqpBpGye11ttFBDc",
  authDomain: "zebrain.firebaseapp.com",
  projectId: "zebrain",
  storageBucket: "zebrain.appspot.com",
  messagingSenderId: "337432544873",
  appId: "1:337432544873:web:32dabdb379af35ced284f8"
};

const AuthContext = createContext(null) as any;

const AuthContextProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout: auth0logout } = useAuth0();

  const [platformUserId, setPlatformUserId] = useState(null);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const fetchUserData = async (auth0Token) => {
    try {
      const response = await fetch('https://api.dazzle.zebrain.se/user', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${auth0Token}`
        }
      });

      if (!response.ok) {
        setPlatformUserId(null);
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      console.log("User data:", userData);
      Sentry.setUser({
        id: userData.id
      });
      setPlatformUserId(userData.id);
      return userData;
    } catch (error) {
      setPlatformUserId(null);
      console.error('Error fetching user data:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        try {
          loginToFirebase();
        } catch (error) {
          console.log("error", error);
        }
      }
    }

  }, [isAuthenticated, isLoading]);

  // LOGIN
  const loginToBackend = async () => {
    let token = await getAccessTokenSilently();
    window.localStorage[CONFIG.TOKEN_NAME] = token
    console.log('Logged in to backend')
    fetchUserData(token);
    // console.log("token", token);
    try {
      const response = await fetch('https://api.dazzle.zebrain.se/fs_token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const loginToFirebase = async () => {
    try {
      const customToken = await loginToBackend();
      return await signInWithCustomToken(auth, customToken)
    } catch (error) {
      throw error;
    }
  }

  const logout = async () => {
    try {
      console.log("Sign out")
      await auth0logout();
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        user,
        platformUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };