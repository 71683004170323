import {CanvasInterface, DeviceInterface, GlInterface, PartnerInterface} from "./interfaces";

export default class MeetingResources {
  static myInstance: MeetingResources;

  audioDevices: Array<DeviceInterface>
  audioOutDevices: Array<DeviceInterface>
  videoDevices: Array<DeviceInterface>
  audioDeviceId: string
  audioGroupId: string
  videoDeviceId: string
  videoGroupId: string
  partners: Array<PartnerInterface>
  localStream?: MediaStream
  screenStream?: MediaStream
  videoContainer?: HTMLElement
  videoElement?: HTMLVideoElement
  screenContainer?: HTMLElement
  screenElement?: HTMLVideoElement
  videoContainerRef: any
  webSocket?: WebSocket
  webSocketHasOpened: boolean
  userId: string
  userName: string
  who: string
  meetingKey: string
  stateFunc: Function
  stateGet: Function
  errorFunc: Function
  canvas?: CanvasInterface
  background: string
  changeBackground: boolean
  small: boolean
  webSocketPingInterval: any
  rtcStatus: string
  rtcHasConnected: boolean
  glStructs?: GlInterface
  skipTurn: boolean
  gotInputDevices: boolean
  externalMeeting: boolean

  constructor(
    searchParams: URLSearchParams,
    email: string,
    stateFunc: Function,
    stateGet: Function,
    errorFunc: Function,
    videoRef: any
  ) {
    this.who = searchParams.get("who")
    if (this.who === 'external') {
      this.userId = 'external_' + searchParams.get("externalId")
      this.externalMeeting = true
    } else {
      this.userId = this.who + '_' + email
      this.externalMeeting = false
    }

    this.videoDevices = []
    this.audioDevices = []
    this.audioOutDevices = []
    this.audioDeviceId = 'unset'
    this.audioGroupId = 'unset'
    this.videoDeviceId = 'unset'
    this.videoGroupId = 'unset'
    this.partners = [] as Array<PartnerInterface>
    this.videoContainerRef = videoRef
    this.userName = searchParams.get("name")
    this.webSocket = undefined
    this.webSocketHasOpened = false
    this.meetingKey = searchParams.get("meetingKey")
    this.stateFunc = stateFunc
    this.stateGet = stateGet
    this.errorFunc = errorFunc
    this.small = false
    this.background = ''
    this.changeBackground = false
    this.webSocketPingInterval = null
    this.rtcStatus = 'waiting'
    this.rtcHasConnected = false
    this.skipTurn = document.location.search.indexOf('skipTurn') > -1
    this.gotInputDevices = false
  }

  static getInstance(
    searchParams: URLSearchParams,
    email: string,
    stateFunc: Function,
    stateGet: Function,
    errorFunc: Function,
    videoRef: any
  ): MeetingResources {
    if (MeetingResources.myInstance == null) {
      MeetingResources.myInstance = new MeetingResources(searchParams, email, stateFunc, stateGet, errorFunc, videoRef);
    }

    return this.myInstance;
  }
}