import React, {useEffect} from 'react';


interface Props {
  children?: React.ReactNode;
  event: React.MouseEvent<HTMLElement>;
  close?: Function
}

// @ts-ignore
const Popup: React.FC<Props> = ({ event, children, close }: Props) => {

    const closeFunction = () => {
      if (close) {
        close()
      }
    }

    useEffect(() => {
      if (close) {
        // console.log('AddEventListener resize')
        window.addEventListener('resize', closeFunction);
      }
      return () => {
        if (close) {
          // console.log('RemoveEventListener resize')
          window.removeEventListener('resize', closeFunction);
        }
      }
    }, [])

    const calculateStyle = () => {
      const style = {position: 'absolute', boxShadow: '3px 6px 6px 0px rgba(25, 25, 60, 0.70)'}
      const width = window.innerWidth
      const height = window.innerHeight
      // @ts-ignore
      const clientHeight = event.target.clientHeight
      // @ts-ignore
      const clientWidth = event.target.clientWidth
      const fromLeft = event.nativeEvent.clientX - event.nativeEvent.offsetX
      const fromTop = event.nativeEvent.clientY - event.nativeEvent.offsetY + clientHeight
      // console.log('clientHeight ' + clientHeight + ' clientY ' + event.nativeEvent.clientY)
      // console.log('offsetY ' + event.nativeEvent.offsetY + ' height ' + height)
      let x: number
      let y: number
      if ((width - fromLeft) < 200) {
        // If element is to the left or has too little space to the right, align left
        x = width - fromLeft
        style['right'] = x - clientWidth + 'px'
      } else {
        x = fromLeft
        style['left'] = x + 'px'
      }

      if ((height - fromTop) < 200) {
        // If element is below middle or has too little space beneath, align from bottom
        y = height - fromTop + clientHeight
        style['bottom'] = y + 'px'
      } else {
        y = fromTop
        style['top'] = y + 'px'
      }

      // console.log(style)

      return style
    }

    if (event) {
      const style: any = calculateStyle()

      // @ts-ignore
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 1001,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
             onClick={closeFunction}>
          <div className="bg-neutral-700 rounded shadow-gray-600" style={style}>
            { children }
          </div>
        </div>

      );
    }
    return (<></>)

};

export default Popup;
