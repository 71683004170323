export interface PartnerInterface {
  peerConnection: RTCPeerConnection
  videoContainer?: HTMLElement
  videoStream?: MediaStream
  screenContainer?: HTMLElement
  screenStream?: MediaStream
  videoElement?: HTMLVideoElement
  videoWidth: number
  videoHeight: number
  screenElement?: HTMLVideoElement
  userId: string
  userName: string
  videoTrack?: MediaStreamTrack
  audioTrack?: MediaStreamTrack
  screenTrack?: MediaStreamTrack
  offerType: string
  connectedRemoteStream: number
}

export interface DeviceInterface {
  id: string
  label: string
  groupId: string
}


export interface CanvasInterface {
  canvasOut: HTMLCanvasElement
  ctxTemp: CanvasRenderingContext2D | null
  width: number
  height: number
  backgroundData?: any
  segmenter: any
  animFrameId: any
  doLoop: any
}

export interface GlInterface {
  gl: any
  program: any
}

export enum ExtraWindow {
  None,
  Chat,
  BookMeeting,
  Client,
  Settings,
  Background,
}

export interface ResourcesInterface {
  audioDevices: Array<DeviceInterface>
  audioDeviceId: string
  audioGroupId: string
  audioOutDevices: Array<DeviceInterface>
  videoDevices: Array<DeviceInterface>
  videoDeviceId: string
  videoGroupId: string
  partners: Array<PartnerInterface>
  localStream?: MediaStream
  screenStream?: MediaStream
  videoContainer?: HTMLElement
  videoElement?: HTMLVideoElement
  screenContainer?: HTMLElement
  screenElement?: HTMLVideoElement
  localInfoElement?: HTMLElement
  videoContainerRef: any
  webSocket?: WebSocket
  webSocketHasOpened: boolean
  userId: string
  userName: string
  who: string
  meetingKey: string
  stateFunc: Function
  errorFunc: Function
  canvas?: CanvasInterface
  background: string
  externalMeeting: boolean
  changeBackground: boolean
  small: boolean
  webSocketPingInterval: any
  rtcStatus: string
  rtcHasConnected: boolean
  glStructs?: GlInterface
  skipTurn: boolean
  gotInputDevices: boolean
  showRightMenu: boolean
  extraWindow: ExtraWindow
}

export interface MeetingInfoInterface {
  meetingStart: string
  meetingLength: number
  clientId: number
  clientName: string
  coachId: number
  coachName: string
  meetingId: number
}

export enum ERRORS {
  WEBSOCKET_FAILED_OPEN,
  WEBSOCKET_NOT_OPEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_EVENT_CLOSED,
  WEBSOCKET_NOT_AVAILABLE,
  RTC_CONNECTION_STATE_FAILED,
  RTC_NEGOTIATION_SLOW
}
