import React, { useState, useEffect, useRef } from 'react';
import chevronLeft from '../../../assets/images/icons/chevronLeft.svg';
import chevronRight from '../../../assets/images/icons/chevronRight.svg';

interface Props {
  dates: Array<Date>;
  clickDate: Function;
  changeMonth?: Function;
}

interface EventDateInterface {
  year: number;
  month: number;
  day: number;
}

const DatePicker: React.FC<Props> = ({ dates, clickDate, changeMonth }: Props) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [selectedDate, setSelectedDate] = useState<{ day: number; month: number; year: number } | null>(null); // Track selected date
  const [paddingDays, setPaddingDays] = useState<number[]>([]);
  const [columnGap, setColumnGap] = useState<string>("48px");
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Define event dates (adjust the dates as needed)
  const eventDates: Array<EventDateInterface> = []
  for (const d of dates) {
    eventDates.push({day: d.getDate(), month: d.getMonth(), year: d.getFullYear()});
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  const daysInMonth = (month: number, year: number) => new Date(year, month + 1, 0).getDate();
  const weekDayOfFirst = (month: number, year: number) => {
    let dayOfWeek = new Date(year, month, 1).getDay();
    // Sunday is day 0 in javascript :-/
    // Translate so that Monday is 0 and Sunday is 6
    if (dayOfWeek === 0) {
      // Javascript day 0 is Sunday - return 6
      return 6
    } else {
      return dayOfWeek - 1
    }
  }

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    setDays(Array.from({ length: daysInMonth(currentMonth, currentYear) }, (_, i) => i + 1));
    const weekDay = weekDayOfFirst(currentMonth, currentYear);
    console.log('first day of month is ' + weekDay)
    setPaddingDays(Array.from({length: weekDay}, (_, i) => i + 1));
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (containerRef) {
      const w = containerRef.current.clientWidth
      if (w < 636) {
        // Space between calendar cols: width - 312 / 6, where 312 is cols with + margin and
        // 6 is nr of spaces between cols*/
        const colGap = w / 6 - 52
        setColumnGap(colGap + "px")
      }
    }
  }, [containerRef])

  // Check if the day has an event
  const hasEvent = (day: number) => {
    return eventDates.some(
      (event) => event.day === day && event.month === currentMonth && event.year === currentYear
    );
  };

  // Handle date click event
  const handleDateClick = (day: number) => {
    setSelectedDate({ day, month: currentMonth, year: currentYear }); // Store selected date
    clickDate(new Date(currentYear, currentMonth, day));
  };

  // Handle previous month click
  const handlePreviousMonth = () => {
    if (changeMonth) {
      changeMonth()
    }
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1); // Decrement year if going back from January
    } else {
      setCurrentMonth((prev) => prev - 1);
    }
  };

  // Handle next month click
  const handleNextMonth = () => {
    if (changeMonth) {
      changeMonth()
    }
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1); // Increment year if going forward from December
    } else {
      setCurrentMonth((prev) => prev + 1);
    }
  };

  const dayClasses = (day: number): string => {
    let classes = "relative mb-4 size-10 flex flex-col justify-center items-center border border-transparent " +
      "font-medium rounded-full hover:bg-sky-500 hover:text-white"
    if (selectedDate && day === selectedDate.day && currentMonth === selectedDate.month && currentYear === selectedDate.year) {
      classes += ' bg-sky-500 text-white'
      console.log('selected: ' + day)
    } else {
      classes += ' text-neutral-400'
    }
    if (hasEvent(day)) {
      classes += ' border-white';
    }
    return classes
  }

  const todayDot = (day) => {
    if (day === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear()) {
      return (<span className="absolute bottom-1 w-0.5 h-0.5 bg-white rounded-full"></span>)
    } else {
      return <></>
    }
  }

  return (
    <div className="sm:flex" ref={containerRef}>
      <div className="space-y-0.5 w-full">
        {/* Month and Year Navigation */}
        <div className="grid grid-cols-5 items-center gap-x-3 pb-3">
          <div className="col-span-1">
            <button
              type="button"
              onClick={handlePreviousMonth}
              className="size-8 flex justify-center items-center text-white hover:bg-neutral-600 rounded-full"
            >
              <img alt="left" src={chevronLeft} className="h-6"/>
            </button>
          </div>

          <div className="col-span-3 flex justify-center items-center gap-x-1 text-lg font-medium">
            {months[currentMonth]} {currentYear}
          </div>

          <div className="col-span-1 flex justify-end">
            <button
              type="button"
              onClick={handleNextMonth}
              className="size-8 flex justify-center items-center text-white hover:bg-neutral-600 rounded-full"
            >
              <img alt="right" src={chevronRight} className="h-6"/>
            </button>
          </div>
        </div>

        {/* Days of the Week */}
        <div className="grid grid-cols-7 pb-2.5" style={{columnGap: columnGap}}>
          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (
            <span key={day} className="m-px w-10 block text-center font-medium text-white">
              {day}
            </span>
          ))}
        </div>

        {/* Calendar Days */}
        <div className="grid grid-cols-7" style={{columnGap: columnGap}}>
          {
            paddingDays.map(paddingDay => (
              <span key={"p_" + paddingDay}></span>
            ))
          }
          {days.map((day) => (
            <button
              key={day}
              onClick={() => handleDateClick(day)} // Handle click
              className={dayClasses(day)}>
              <span>{day}</span>
              {todayDot(day)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
