import React, {useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {arrangeVideos} from "./placeVideos"
import {openSocket, waitForSocket, sendSocketError, connectMeeting, closeSocket} from "./socket"
import {formatSoon} from "../../helpers/dateFunctions"
import {
  createLocalInfoDiv,
  createVideoContainer,
  createVideoElement,
  replaceTrackToPeer,
  startShareScreen,
  stopShareScreen,
  unmountVideo,
} from "./peer";
import {
  DeviceInterface,
  MeetingInfoInterface, ERRORS
} from "./interfaces";
import './Video.css';
import {connectCanvasStream, startBackground, stopBackground, bgImages} from "./backgroundImage";
import Api from "../../services/Api";
import TestSound from "./TestSound";
import MeetingResources from "./MeetingResources";
import iconDots from "shared-assets/icons/dots-horizontal.svg"
// @ts-ignore
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// @ts-ignore
import phoneOff from "../../../assets/images/icons/video/phone-off.svg"
// @ts-ignore
import fullscreenOpen from "../../../assets/images/icons/video/fullscreen_open.svg"
// @ts-ignore
import fullscreenClose from "../../../assets/images/icons/video/fullscreen_close.svg"
// @ts-ignore
import iconCameraOn from "../../../assets/images/icons/video/camera-on.svg"
// @ts-ignore
import iconCameraOff from "../../../assets/images/icons/video/camera-off.svg"
// @ts-ignore
import iconMicOn from "../../../assets/images/icons/video/mic-on.svg"
// @ts-ignore
import iconMicOff from "../../../assets/images/icons/video/mic-off.svg"
// @ts-ignore
import screenshareOn from "../../../assets/images/icons/video/screenshare-on.svg"
// @ts-ignore
import screenshareOff from "../../../assets/images/icons/video/screenshare-off.svg"
// @ts-ignore
import soundFileData from "../../../assets/sound/creativeminds.mp3"
import iconClose from "shared-assets/icons/close.svg"
import iconVolume from "shared-assets/icons/video/volume.svg"
import iconImageOutline from "shared-assets/icons/video/image-outline.svg"
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconHelpCircle from "shared-assets/icons/video/help-circle.svg"



interface StateInterface {
  meetingInfo: MeetingInfoInterface
  videoCompatible: boolean
  screenShareCompatible: boolean
  showVisionBoard: boolean
  meetingId: number
  showMenu: boolean
  showHelp: boolean
  showNotes: boolean
  showAddBooking: boolean
  showCoachCalendar: boolean
  shareScreen: boolean
  chatOpen: boolean
  showAccessHelp: boolean
  innerWidth: number
  testSpeakers: boolean
}

function initialState() {
  console.log('Initial state')
  return {
    meetingInfo: {} as MeetingInfoInterface,
    videoCompatible: !!navigator.mediaDevices,
    screenShareCompatible: 'getDisplayMedia' in navigator.mediaDevices,
    showVisionBoard: false,
    meetingId: 0,
    showMenu: false,
    showHelp: false,
    showNotes: false,
    showAddBooking: false,
    showCoachCalendar: false,
    shareScreen: false,
    chatOpen: false,
    // chatMessages: [] as Array<ChatInterface>,
    showAccessHelp: false,
    innerWidth: window.innerWidth,
    testSpeakers: false,
  }
}

const soundFile = new Audio(soundFileData)
const debugOrder = false

export default function Meeting(){

  const api = Api.getInstance()
  const navigate = useNavigate();
  const location = useLocation();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [searchParams] = useSearchParams();

  let localOpened = false
  let devicesChanging = false
  let infoText = ''
  const [state, setMyState] = useState<StateInterface>(initialState)
  const [fullscreen, setFullscreen] = useState<boolean>(false)
  const [cameraOn, setCameraOn] = useState<boolean>(false)
  const [micOn, setMicOn] = useState<boolean>(false)
  const [useCanvas, setUseCanvas] = useState<boolean>(false)
  const [videoDeviceId, setVideoDeviceId] = useState<string>('unset')
  const [videoGroupId, setVideoGroupId] = useState<string>('unset')
  const [audioDeviceId, setAudioDeviceId] = useState<string>('unset')
  const [audioGroupId, setAudioGroupId] = useState<string>('unset')
  const [audioOutDeviceId, setAudioOutDeviceId] = useState<string>('unset')
  const [menuSection, setMenuSection] = useState<string>(window.innerWidth < 800 ? '' : 'mic')
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [info, setInfo] = useState<string>('')
  const [infoLink, setInfoLink] = useState<string>('')


  useEffect(() => {
    if (!fullscreen) {
      // We get a fullscreen change on start, make sure we are in fullscreen before exiting fullscreen
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
    } else {
      const elem = document.getElementById("whole_window")
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        }
      }
    }
  }, [fullscreen])

  useEffect(() => {
    arrangeVideos(resources)
  }, [state.innerWidth])

  const stateFunc = (key: string, value: any) => {
    if (key === 'shareScreen') {
      setState({shareScreen: value})
    } else if (key === 'showInfo') {
      setShowInfo(value)
    } else if (key === 'info') {
      setInfo(value)
    } else if (key === 'infoText') {
      infoText = value
    } else if (key === 'info') {
      setState({info: value})
    } else if (key === 'arrangeVideos') {
      arrangeVideos(resources)
    } else {
      console.log('!!! Unknown key to stateFunc: ' + key)
    }
  }

  const stateGet = (key: string) => {
    if (key === 'chatOpen') {
      return state.chatOpen
    } else if (key === 'showNotes') {
      return state.showNotes
    } else if (key === 'showVisionBoard') {
      return state.showVisionBoard
    }
    return null
  }

  const errorFunc = (error: ERRORS) => {
    let errorText: string
    switch(error) {
      case ERRORS.WEBSOCKET_FAILED_OPEN: {
        errorText = 'Failed opening connection to Zebrain system.\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_OPEN: {
        errorText = 'Lost connection to Zebrain system (1).\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_CLOSED: {
        errorText = 'Websocket is closed'
        break
      }
      case ERRORS.WEBSOCKET_EVENT_CLOSED: {
        errorText = 'Lost connection to Zebrain system (2).\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_AVAILABLE: {
        errorText = 'Websocket has died :-(\nTry reloading the page, ' +
            'and if that doesn\'t help, contact support@zebrain.se'
        break
      }
      case ERRORS.RTC_CONNECTION_STATE_FAILED: {
        if (resources.rtcHasConnected) {
          errorText = 'Lost video connection. This is probably due to a network error between you and meeting partner.\n'
          errorText += 'Check your internet connection. If it works, try reloading this page.\n'
          errorText += 'If that doesn\'t work, the problem could be with your partner\'s network.\n\n'
          errorText += 'If nothing works, contact support@zebrain.se and we will try to help.'
        } else {
          errorText = 'Failed connecting video stream. This is probably due to a firewall issue.\n'
          errorText += '\nSend a mail to support@zebrain.se and we will help.'
        }

        break
      }
      case ERRORS.RTC_NEGOTIATION_SLOW: {
        errorText = 'If your cannot see your meeting partner within ten seconds, try these steps:\n\n' +
            '1. Exit meeting and join again\n\n' +
            '2. If that doesn\'t work, restart your browser and join meeting again\n\n' +
            '3. If that doesn\'t work, contact support'
        break
      }
      default: {
        errorText = 'Unknown error: ' + error
        break
      }
    }

    displayInfo(errorText, true)
  }

  const resources = MeetingResources.getInstance(
    searchParams, api.email, stateFunc, stateGet, errorFunc, useRef(null)
  )

  async function start() {
    if (debugOrder) console.log('Order 2: start()')
    displayInfo(t('starting-network'), true)
    if (debugOrder) console.log('Order 3: openSocket()')
    openSocket(resources, resources.userId, resources.userName)


    const socketOpened = await waitForSocket(resources)
    if (debugOrder) console.log('Order 4: socketOpened is ' + socketOpened)

    if (!socketOpened) {
      if (resources.webSocket) {
        if (resources.webSocket.readyState === WebSocket.CLOSED) {
          console.log('-- websocket is closed ' + resources.webSocket.readyState)
        } else if (resources.webSocket.readyState === WebSocket.CONNECTING) {
          console.log('-- websocket is connecting ' + resources.webSocket.readyState)
        } else {
          console.log('websocket is in state ' + resources.webSocket.readyState)
        }
      }
      return
    }

    displayInfo(t('starting-camera'), true)

    if (debugOrder) console.log('Order 5: getInputDevices')
    resources.gotInputDevices = await getInputDevices()


    displayInfo(t('starting-video'), true)

    if (debugOrder) console.log('Order 6: startLocalVideo')
    startLocalVideo()
    if (debugOrder) console.log('Order 7: startLocalVideo done')

    setupMediaChange()
    if (debugOrder) console.log('Order 8: setupMediaChange')
    window.addEventListener('resize', onResize)
    window.addEventListener('fullscreenchange', fullscreenChange)
  }

  useEffect(() => {
    if (debugOrder) console.log('Order 1: useEffect')
    // start will set up all initial resources
    start().then(() => console.log('start() has completed'));

    // When the component is unmounted we need to clean up resources
    return () => {
      console.log('Unmounting resources')
      window.removeEventListener('resize', onResize)
      window.removeEventListener('fullscreenchange', fullscreenChange)
      closeSocket(resources)
      unmountVideo(resources)
      setMyState(initialState())
    }
  }, [])

  const getChatData = () => {
    api.get('local/meeting/' + resources.who + '/' + resources.meetingKey).then(response => {
      const meetingInfo: MeetingInfoInterface = {
        meetingStart: response.json.meeting.start,
        meetingLength: response.json.meeting.meetingLength,
        clientId: response.json.meeting.clientId,
        clientName: response.json.meeting.clientName,
        coachId: response.json.meeting.coachId,
        coachName: response.json.meeting.coachName,
      }
      let info = "Meeting starts"
      info += ': ' + formatSoon(meetingInfo.meetingStart, lang) + ', '
        + 'length' + ': ' + meetingInfo.meetingLength + ' minutes'

      info += '\n\nWaiting for '
      if (resources.who === 'coach') {
        info += meetingInfo.clientName
      } else {
        info += meetingInfo.coachName
      }
      info += '...'
      const meetingStart = new Date(meetingInfo.meetingStart)
      const now = new Date()
      const secondsToStart = (meetingStart.getTime() - now.getTime()) / 1000
      if (secondsToStart > 3600) {
        info += '\n\n\n\n'
        if (lang === 'sv') {
          info += 'Obs! Det är långt kvar tills mötet startar.'
        } else {
          info += 'NB! It is a long time until meeting starts.'
        }
      }
      displayInfo(info, true)
      setState({
        // chatMessages: response.json.chats,
        meetingId: response.json.meeting.id,
        meetingInfo: meetingInfo
      })
    })
  }

  const setState = (data: any, func?: Function) => {
    const newState = structuredClone(state)

    for (const key in data) {
      const value = data[key]
      switch (key) {
        case 'meetingId':
          newState.meetingId = value
          break
        case 'meetingInfo':
          newState.meetingInfo = value
          break
        case 'innerWidth':
          newState.innerWidth = value
          break
        case 'showMenu':
          newState.showMenu = value
          break
        case 'testSpeakers':
          newState.testSpeakers = value
          break
        default:
          console.log('---------- unhandled key ' + key)
      }
    }
    setMyState(newState)
    if (func) {
      console.log('---------- not running func!!!')
      console.log(data)
      // func()
    }
  }

  const fullscreenF = () => {
    if (document.fullscreenElement) {
      setFullscreen(false)
    } else {
      setFullscreen(true)
    }
  }

  const fullscreenChange = (event: any) => {
    console.log('-------- fullscreenChange checking: ' + fullscreen)
    // console.log(event)
    // setTimeout(() => {
    //   if (!document.fullscreenElement && state.fullscreen) {
    //     console.log('-------- fullscreenChange: ' + false)
    //     setState({"fullscreen": false})
    //   }
    // }, 100)
  }

  const hangup = () => {
    if (window.opener) {
      window.opener.console.log('From opened window')
      console.log('----- Closing window')
      window.opener.postMessage('video_window_close')
      // Catcher in the rye - if window.opener has gone away
      // setTimeout(() => {
      //   window.location.href = '/app/' + who + '/overview'
      // }, 300)
    } else {
      // If location.key is 'default' you are routed here from external link
      if (location.key != 'default') {
        navigate(-1)
      } else {
        navigate('/')
      }
    }
  }

  const setupMediaChange = () => {
    navigator.mediaDevices.ondevicechange = () => {
      console.log('-------- media device change')
      if (devicesChanging) {
        console.log('---- warning: devices are in changing state')
        return
      }
      devicesChanging = true
      const oldAudioGroupId = audioGroupId
      const oldVideoGroupId = videoGroupId

      getInputDevices().then(() => {
        if (localOpened) {
          if (oldAudioGroupId !== audioGroupId || oldVideoGroupId !== videoGroupId) {
            console.log('Different audio or video')
            if (oldAudioGroupId !== audioGroupId) {
              console.log('Different audio: ' + oldAudioGroupId + ' !== ' + audioGroupId)
              stopLocalStream('audio')
            }
            if (oldVideoGroupId !== videoGroupId) {
              console.log('Different video: ' + oldVideoGroupId + ' !== ' + videoGroupId)
              stopLocalStream('video')
            }

            changeLocalVideo()
            console.log('Changed input stream')
          }
        }
        devicesChanging = false
      })
    }
  }

  // Local video methods
  async function getInputDevices() {
    console.log('------ getInputDevices starting')
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices: Array<DeviceInterface> = []
    const audioDevices: Array<DeviceInterface> = []
    const audioOutDevices: Array<DeviceInterface> = []
    for (let device of mediaDevices) {
      if (device.kind === 'audioinput') {
        audioDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      } else if (device.kind === 'videoinput') {
        videoDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId
        })
      } else if (device.kind === 'audiooutput') {
        audioOutDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      }
    }

    resources.audioDevices = audioDevices
    resources.videoDevices = videoDevices
    resources.audioOutDevices = audioOutDevices

    if (audioDevices.length > 0 && audioDevices[0].id !== ''
      && videoDevices.length > 0 && videoDevices[0].id !== '') {
      console.log('Setting audioDevices to ' + audioDevices[0].id + ' v ' + videoDevices[0].id)
      resources.audioDeviceId = audioDevices[0].id
      resources.audioGroupId = audioDevices[0].groupId
      resources.videoDeviceId = videoDevices[0].id
      resources.videoGroupId = videoDevices[0].groupId
      setAudioDeviceId(audioDevices[0].id)
      setAudioGroupId(audioDevices[0].groupId)
      setVideoDeviceId(videoDevices[0].id)
      setVideoGroupId(videoDevices[0].groupId)
      return true
    }
    return false
  }

  const getConstraints = () => {
    let video:any = resources.videoDeviceId === 'unset' ? true : {deviceId: {exact: resources.videoDeviceId}}
    const audio = resources.audioDeviceId === 'unset' ? true : {deviceId: {exact: resources.audioDeviceId}}
    return {
      video: video,
      audio: audio
    }
  }

  function startLocalVideo() {

    resources.videoContainer = createVideoContainer(resources.videoContainerRef.current)
    resources.videoElement = createVideoElement(resources.videoContainer)
    resources.videoElement.classList.add('video_mirrored')
    
    const constraints = getConstraints()

    if (debugOrder) console.log('Order 7.1: got constraints')

    if (!resources.gotInputDevices) {
      displayInfo('Waiting for camera access approval',true)
    }

    if (debugOrder) console.log('Order 7.2: getUserMedia')
    console.log(constraints)

    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {

      if (debugOrder) console.log('Order 7.3: in getUserMedia')
      if (!resources.externalMeeting) {
        displayInfo('Looking up meeting information',true)
        getChatData()
        if (debugOrder) console.log('Order 7.4: in getChatData')
      } else {
        setShowInfo(false)
      }
      if (resources.videoContainer) {
        createLocalInfoDiv(resources, resources.videoContainer, displayInfo)
        if (debugOrder) console.log('Order 7.5: createLocalInfoDiv')
      }

      resources.localStream = localStream
      // In case we could not get input devices due to allow access dialogue, run getInputDevices again
      if (debugOrder) console.log('Order 7.6: getInputDevices')
      if (resources.videoDeviceId === 'unset' || resources.audioDeviceId === 'unset') {
        console.log('getInputDevices since v ' + resources.videoDeviceId + ' a ' + resources.audioDeviceId)
        getInputDevices().then(result => {
          resources.gotInputDevices = result
        })
      }
      try {
        // If user changed mic or camera, respect if the turned off camera or mic

        if (resources.videoElement) {
          if (debugOrder) console.log('Order 7.7: has videoElement')
          resources.videoElement.onloadedmetadata = () => {
            if (debugOrder) console.log('Order 7.8: onloadedmetadata')
            localOpened = true
            arrangeVideos(resources)
            setMicOn(true)
            setCameraOn(true)
            connectMeeting(resources)
          }

          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          resources.videoElement.muted = true
          resources.videoElement.srcObject = localStream
        } else {
          alert('Failed getting videoElement')
          sendSocketError('No video element', resources)
        }


      } catch (error) {
        displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
        sendSocketError('Failed opening video', resources)
      }
    }).catch(err => {
      setState({showAccessHelp: true})
      sendSocketError('Failed getUserMedia', resources)
    })
  }

  function changeLocalVideo() {
    const constraints = getConstraints()
    try {
      navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
        resources.localStream = localStream
        if (resources.videoElement) {
          console.log('-------- changeLocalVideo c ' + cameraOn + ' m ' + micOn)
          // If user changed mic or camera, respect if the turned off camera or mic
          if (!micOn || !cameraOn) {
            resources.localStream?.getTracks().forEach((track: MediaStreamTrack) => {
              if (track.kind === 'video') {
                track.enabled = cameraOn
              } else if (track.kind === 'audio') {
                track.enabled = micOn
              }
            })
          }
          resources.videoElement.onloadedmetadata = () => {
            if (useCanvas) {
              connectCanvasStream(resources)
            } else {
              replaceTrackToPeer(resources)
            }
          }
          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          resources.videoElement.muted = true
          resources.videoElement.srcObject = localStream
        }

      }).catch(err => {
        setState({showAccessHelp: true})
        sendSocketError('Failed getUserMedia', resources)
      })
    } catch (error) {
      displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
      sendSocketError('Failed opening video', resources)
    }
  }

  const onResize = () => {
    setState({innerWidth: window.innerWidth})
    // clearTimeout(resizeFinished);
    // resizeFinished = setTimeout(() => {
    //   setState({innerWidth: window.innerWidth})
    //   arrangeVideos(resources)
    // }, 300);
  }

  const stopLocalStream = (kind: string) => {
    if (resources.localStream) {
      resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === kind && track.readyState === 'live') {
          console.log('Change, stopping previous track ' + track.kind)
          track.stop()
        }
      })
    }
  }

  const changeVideo = (deviceId: string) => {
    if (deviceId === resources.videoDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    stopLocalStream('video')

    setState({showMenu: false})
    resources.videoDeviceId = deviceId
    setVideoDeviceId(deviceId)
    changeLocalVideo()
  }

  const changeAudio = (deviceId: string) => {
    if (deviceId === resources.audioDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    stopLocalStream('audio')

    resources.audioDeviceId = deviceId
    setAudioDeviceId(deviceId)
    changeLocalVideo()
    console.log('Changed to ' + deviceId)
  }

  const changeOutAudio = (deviceId: string) => {
    if (deviceId === audioOutDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }

    if (resources.videoElement && 'setSinkId' in HTMLMediaElement.prototype) {
      resources.audioDeviceId = deviceId
      setAudioDeviceId(deviceId)
      // @ts-ignore
      resources.videoElement.setSinkId(deviceId)
      // @ts-ignore
      soundFile.setSinkId(deviceId)
      console.log('Changed audioDeviceId to ' + deviceId)
    }
  }

  const displayInfo = (infoTxt: string, reset: boolean, link?: string) => {
    if (reset) {
      infoText = infoTxt
    } else {
      infoText += '\n' + infoTxt
    }
    let showInfoLink = link ? link : ''
    setInfoLink(showInfoLink)
    setInfo(infoText)
    setShowInfo(true)
  }

  const toggleCamera = () => {
    if (resources.localStream) {
      resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'video') {
          const newState = !cameraOn
          track.enabled = newState
          setCameraOn(newState)
        }
      })
    }
  }

  const toggleMic = () => {
    if (resources.localStream) {

      resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'audio') {
          const newState = !micOn
          track.enabled = newState
          setMicOn(newState)
        }
      })
    }
  }

  const toggleNotes = () => {
    setState({showNotes: !state.showNotes})
    arrangeVideos(resources)
  }

  const closeNotes = () => {
    setState({showNotes: false})
    arrangeVideos(resources)
  }

  const toggleChat = (chatOpen: boolean) => {
    setState({chatOpen: chatOpen})
    arrangeVideos(resources)
  }

  const toggleShareScreen = () => {
    const shareScreen = !state.shareScreen
    if (shareScreen) {
      startShareScreen(resources)
    } else {
      stopShareScreen(resources)
    }
    // setState({shareScreen: shareScreen})
  }

  const toggleCanvas = (background: string) => {
    const shouldUseCanvas = background.length > 0
    const changeCanvas = useCanvas !== shouldUseCanvas
    resources.background = background
    resources.changeBackground = true
    setState({showMenu: false})
    setUseCanvas(shouldUseCanvas)
    if (changeCanvas) {
      if (shouldUseCanvas) {
        startBackground(resources)
      } else {
        stopBackground(resources)
        replaceTrackToPeer(resources)
        setTimeout(() => {
          // Allow for canvas to be removed
          arrangeVideos(resources)
        }, 200)

      }
    }
  }

  const showMenuLeft = (small: boolean) => {
    return (
      <div className="video_menu_left">
        <div className="flex flex-row justify-between items-center top_left_margin">
          <p className="text-xl p-4">{t('settings')}</p>
          {
            small &&
              <div className="flex justify-end cursor-pointer mr-4"
                   onClick={() => setState({showMenu: false})}>
                  <img className="icon_small" src={iconClose} alt="close"/>
              </div>
          }
        </div>

        <div className={"video_menu_box mt-4 " + (menuSection === 'mic' ? 'selected' : '')}>
          <div className="flex items-center"
               onClick={() => setMenuSection('mic')}>
            <img src={iconMicOn} alt="sound" className="video_menu_icon"/>
            <p>Mic</p>
          </div>
        </div>

        <div className={"video_menu_box " + (menuSection === 'loudspeakers' ? 'selected' : '')}>
          <div className="flex items-center"
               onClick={() => setMenuSection('loudspeakers')}>
            <img src={iconVolume} alt="sound" className="video_menu_icon"/>
            <p>{lang === 'sv' ? 'Högtalare' : 'Loudspeakers'}</p>
          </div>
        </div>

        {
          resources.videoDevices.length > 1 &&
            <div className={"video_menu_box " + (menuSection === 'video' ? 'selected' : '')}>
              <div className="flex items-center" onClick={() => setMenuSection('video')}>
                <img src={iconCameraOn} alt="camera" className="video_menu_icon"/>
                <p>Video</p>
              </div>
            </div>
        }

        <div className={"video_menu_box " + (menuSection === 'background' ? 'selected' : '')}>
          <div className="flex items-center" onClick={() => setMenuSection('background')}>
            <img src={iconImageOutline} alt="mic" className="video_menu_icon"/>
            <p>Background</p>
          </div>
        </div>

        {
          resources.who === 'client' &&
            <div className="video_menu_box flex items-center"
                 onClick={() => setState({showAddBooking: !state.showAddBooking, showMenu: false})}>
                <img src={iconCalendar} alt="calendar" className="svg_white video_menu_icon"/>
                <p>Book new meeting</p>
            </div>
        }
        {
          resources.who === 'coach' &&
            <div className="video_menu_box flex items-center"
                 onClick={() => setState({showCoachCalendar: !state.showCoachCalendar, showMenu: false})}>
                <img src={iconCalendar} alt="calendar" className="svg_white video_menu_icon"/>
                <p>Manage bookings</p>
            </div>
        }
        <div className={"video_menu_box flex items-center" + (menuSection === 'background' ? 'selected' : '')}
             onClick={() => setMenuSection('help')}>
          <img src={iconHelpCircle} alt="help" className="video_menu_icon tiny_icon"/>
          <p>Help</p>
        </div>
      </div>
    )
  }

  const showMenuRight = (small: boolean) => {
    return (
      <div className="video_menu_right">
        {
          small ? (
            <div className="flex justify-between m-4">
              <h3 onClick={() => setState({menuSection: ''})}>
                &lt;&nbsp;Back
              </h3>
              <div className="text_right w-full cursor-pointer" onClick={() => setState({showMenu: false})}>
                <img src={iconClose} alt="close" className="icon_small" />
              </div>
            </div>
          ) : (
            <div className="flex justify-end cursor-pointer" onClick={() => setState({showMenu: false})}>
              <img src={iconClose} alt="close" className="icon_small m-4" />
            </div>
          )
        }

        <div className="mx-4">
        {
          showMenuSection()
        }
        </div>
      </div>
    )
  }

  const testSpeakers = () => {
    if (state.testSpeakers) {
      soundFile.pause()
    } else {
      soundFile.play()
    }
    setState({ testSpeakers: !state.testSpeakers })
  }

  const speakertext = () => {
    if (state.testSpeakers) {
      return (
        lang === 'sv' ?
        'Spelar upp musik...'
        : 
        `Playing music...`)
    } else {
      return (
      lang === 'sv' ?
        'Testa dina högtalare'
        : 
        `Test your speakers`)
    }
  }

  const showMenuSection = () => {
    if (menuSection === 'mic') {
      return (
        <div>
          <h3>Mic</h3>
          {
            resources.audioDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={audioDeviceId}
                      onChange={(e) => changeAudio(e.target.value)}>
                {
                  resources.audioDevices.map((device, index) => {
                    return (
                      <option key={"audio_" + index} value={device.id}>
                          {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
          <TestSound resources={resources} micOn={micOn} audioDeviceId={audioDeviceId}/>
        </div>
      )
    } else if (menuSection === 'loudspeakers') {
      return (
        <div>
          <h3>{lang === 'sv' ? 'Högtalare' : 'Speakers'}</h3>
          {
            resources.audioOutDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={audioOutDeviceId}
                      onChange={(e) => changeOutAudio(e.target.value)}>
                {
                  resources.audioOutDevices.map((device, index) => {
                    return (
                      <option key={"audio_" + index} value={device.id}>
                        {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
          <div className="top_margin">
            <div onClick={() => testSpeakers()}  className="flex">
              <div className={'video_test_sound ' + (state.testSpeakers ? 'on' : 'off')} />
              <div style={{ marginLeft: '5px' }} className='align-center'>
                {speakertext()}
              </div>
            </div>
            <div style={{ fontStyle: 'italic' }} className="top_margin font_small">
              { lang === 'sv' ?
                'Om du hör musik spelas, så fungerar dina högtalare'
                :
                `If you hear music, your speakers are working.`
              }
            </div>

            <h4 className="top_margin">
              {lang === 'sv' ? 'Hör du inget ljud?' : `Can't hear any sound?`}
            </h4>
            <div className="top_margin font_small">
              {lang === 'sv' ? 'Kontrollera att dina högtalare är på' : `Check if your speakers are on.`}
            </div>
          </div>
        </div>
      )
    } else if (menuSection === 'video') {
      return (
        <div>
          <h3>Video</h3>
          {
            resources.videoDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={videoDeviceId}
                      onChange={(e) => changeVideo(e.target.value)}>
                {
                  resources.videoDevices.map((device, index) => {
                    return (
                      <option key={"video_" + index} value={device.id}>
                        {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
        </div>
      )
    } else if (menuSection === 'background') {
      return (
        <div>
          <h3>Background</h3>

          <select className="mt-4 page_select w100" value={resources.background}
                  onChange={(e) => toggleCanvas(e.target.value)}>
            {
              bgImages.map((bgImage, index) => {
                return (
                  <option key={"bg_" + index} value={bgImage.image}>
                    {lang === 'sv' ? bgImage.name_sv : bgImage.name_en}
                  </option>
                )
              })
            }
          </select>
        </div>
      )
    } else if (menuSection === 'help') {
      return (
        <div>
          <h3>Help</h3>
        </div>
      )
    } else {
      return (<div>Missing content for {menuSection}</div>)
    }
  }

  const showMenu = () => {
    const small = state.innerWidth < 800
    let showLeft = true
    let showRight = true

    if (small) {
      if (menuSection === '') {
        showRight = false
      } else {
        showLeft = false
      }
    }
    return (
      <div className="video_menu_page flex">
        {
          showLeft && showMenuLeft(small)
        }
        {
          showRight && showMenuRight(small)
        }
      </div>
    )
  }

  if (!state.videoCompatible) {
    return (
      <div className="m-4">Your browser is not compatible with video conferences</div>
    )
  } else if (state.showAccessHelp) {
    return (
        <p>Access help</p>
    )
  } else {
    return (
      <div id="whole_window">
        <div ref={resources.videoContainerRef}/>
        {
          showInfo &&
            <div className="video_info">
              {info}
            </div>
        }

        {
          state.showMenu && showMenu()
        }

        <div className="video_footer flex items-center justify-center">
          <div className="flex justify-evently">

            <div className="cursor-pointer video_footer_icon" onClick={() => toggleCamera()}>
              <img title={cameraOn ? "Hide" : "Show"}
                   alt="camera" className="video_icon"
                   src={cameraOn ? iconCameraOn : iconCameraOff}/>
            </div>
            <div className="cursor-pointer video_footer_icon" onClick={() => toggleMic()}>
              <img alt="microphone" className="video_icon"
                   title={micOn ? "Mute" : "Unmute"}
                   src={micOn ? iconMicOn : iconMicOff}/>
            </div>

            {
              state.screenShareCompatible &&
                <div className="cursor-pointer video_footer_icon" onClick={() => toggleShareScreen()}>
                    <img alt="present" className="video_icon"
                         title={state.shareScreen ? "Stop" : "Start"}
                         src={state.shareScreen ? screenshareOff : screenshareOn}/>
                </div>
            }
            <div className="cursor-pointer video_footer_icon" onClick={() => setState({showMenu: !state.showMenu})}>
              <img alt="menu" className="video_icon"
                   src={iconDots}/>
            </div>
            <div className="cursor-pointer video_footer_icon" onClick={() => hangup()}>
              <img title="Leave" alt="hangup" className="video_icon"
                   src={phoneOff}/>
            </div>
            {
              state.innerWidth > 800 &&
                <div className="cursor-pointer video_footer_icon round border_white p-1" onClick={() => fullscreenF()}>
                    <img title="fullscreen" alt="fullscreen" className="p-2"
                         src={fullscreen ? fullscreenClose : fullscreenOpen}/>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
};
