import React, { useState, useEffect } from 'react';
import { CustomButton as Button } from 'shared-components';

const Calendar = () => {
  // Initialize with the current month and year (one-based indexing)
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth() + 1); // Add 1 for one-based indexing
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const [selectedDate, setSelectedDate] = useState<{
    day: number;
    month: number; // One-based indexing
    year: number;
  } | null>(null); // Track selected date

  const [availableTimes, setAvailableTimes] = useState<string[]>([
    '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45',
    '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
    '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45',
    '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45',
    '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
    '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45',
    '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45',
  ]);

  // Define highlighted dates with day, month (1-12), and year
  const [highlightedDates, setHighlightedDates] = useState([
    { day: 25, month: 11, year: 2024 }, // November 25, 2024
    { day: 15, month: 11, year: 2024 }, // November 15, 2024
  ]);

  // Define event dates with one-based month indexing
  const eventDates = [
    // { day: 5, month: 11, year: 2024 },  // November 5, 2024
    // { day: 10, month: 11, year: 2024 }, // November 10, 2024
    // { day: 15, month: 11, year: 2024 }, // November 15, 2024
  ];

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const daysInMonth = (month: number, year: number) =>
    new Date(year, month, 0).getDate(); // Adjusted for one-based indexing

  const [days, setDays] = useState<
    { day: number; month: number; year: number; currentMonth: boolean }[]
  >([]);

  useEffect(() => {
    const daysInCurrentMonth = daysInMonth(currentMonth, currentYear);

    // Compute first day index (with week starting on Monday)
    let firstDayIndex = new Date(currentYear, currentMonth - 1, 1).getDay() - 1;
    if (firstDayIndex === -1) {
      firstDayIndex = 6;
    }

    const daysArray = [];

    // Number of days in the previous month
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const prevMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    const daysInPrevMonth = daysInMonth(prevMonth, prevMonthYear);

    // Fill in dates from the previous month
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      daysArray.push({
        day: daysInPrevMonth - i,
        month: prevMonth,
        year: prevMonthYear,
        currentMonth: false,
      });
    }

    // Add days of the current month
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      daysArray.push({
        day: i,
        month: currentMonth,
        year: currentYear,
        currentMonth: true,
      });
    }

    // Fill in dates for the next month until we have 42 days total
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextMonthYear = currentMonth === 12 ? currentYear + 1 : currentYear;
    const totalSlots = 42; // 6 weeks
    const remainingSlots = totalSlots - daysArray.length;

    for (let i = 1; i <= remainingSlots; i++) {
      daysArray.push({
        day: i,
        month: nextMonth,
        year: nextMonthYear,
        currentMonth: false,
      });
    }

    setDays(daysArray);
  }, [currentMonth, currentYear]);

  // Function to dynamically highlight specific dates
  const highlightTargetDay = (dayObj: {
    day: number;
    month: number; // One-based indexing
    year: number;
    currentMonth: boolean;
  }) => {
    return highlightedDates.some(
      (highlightedDate) =>
        highlightedDate.day === dayObj.day &&
        highlightedDate.month === dayObj.month &&
        highlightedDate.year === dayObj.year
    )
      ? 'border border-white text-white hover:border-neutral-500'
      : '';
  };

  // Apply style for today's date
  const isToday = (dayObj: {
    day: number;
    month: number; // One-based indexing
    year: number;
    currentMonth: boolean;
  }) => {
    if (
      dayObj.day === today.getDate() &&
      dayObj.month === today.getMonth() + 1 && // Adjusted for one-based indexing
      dayObj.year === today.getFullYear()
    ) {
      return isSelectedDay(dayObj) ? 'font-bold' : 'text-white font-bold';
    }
    return '';
  };

  // Apply style for the selected date
  const isSelectedDay = (dayObj: {
    day: number;
    month: number; // One-based indexing
    year: number;
    currentMonth: boolean;
  }) => {
    return selectedDate &&
      dayObj.day === selectedDate.day &&
      dayObj.month === selectedDate.month &&
      dayObj.year === selectedDate.year
      ? 'bg-primary-500 text-neutral-800'
      : '';
  };

  // Check if the day has an event
  const hasEvent = (dayObj: {
    day: number;
    month: number; // One-based indexing
    year: number;
    currentMonth: boolean;
  }) => {
    return eventDates.some(
      (event) =>
        event.day === dayObj.day &&
        event.month === dayObj.month &&
        event.year === dayObj.year
    );
  };

  // Handle date click event
  const handleDateClick = (dayObj: {
    day: number;
    month: number; // One-based indexing
    year: number;
    currentMonth: boolean;
  }) => {
    setSelectedDate({ day: dayObj.day, month: dayObj.month, year: dayObj.year });
  };

  // Handle previous month click
  const handlePreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1); // Decrement year if going back from January
    } else {
      setCurrentMonth((prev) => prev - 1);
    }
  };

  // Handle next month click
  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1); // Increment year if going forward from December
    } else {
      setCurrentMonth((prev) => prev + 1);
    }
  };

  return (
    <div className="sm:flex">
      <div className="p-3 w-full">
        {/* Month and Year Navigation */}
        <div className="grid grid-cols-7 items-center pb-3">
          {/* Previous Month Button */}
          <div className="col-start-1 flex justify-center">
            <button
              type="button"
              onClick={handlePreviousMonth}
              className="flex justify-center items-center text-white hover:text-gray-400 rounded-full"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M15 18l-6-6 6-6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          {/* Month and Year Display */}
          <div className="col-start-2 col-end-7 flex justify-center items-center">
            <span className="text-white">
              {months[currentMonth - 1]} / {currentYear}
            </span>
          </div>

          {/* Next Month Button */}
          <div className="col-start-7 flex justify-center">
            <button
              type="button"
              onClick={handleNextMonth}
              className="flex justify-center items-center text-white hover:text-gray-400 rounded-full"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M9 18l6-6-6-6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Days of the Week */}
        <div className="grid grid-cols-7 pb-1.5">
          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (
            <span
              key={day}
              className="w-full text-center text-sm text-white"
            >
              {day}
            </span>
          ))}
        </div>

        {/* Calendar Days */}
        <div className="grid grid-cols-7 gap-0">
          {days.map((dayObj, index) => (
            <div key={index} className="flex justify-center items-center pb-2">
              <button
                onClick={() => handleDateClick(dayObj)}
                className={`relative w-10 h-10 flex justify-center items-center border border-transparent text-sm rounded-full ${
                  dayObj.currentMonth ? 'text-white' : 'text-gray-400'
                } ${
                  isSelectedDay(dayObj)
                    ? ''
                    : 'hover:bg-neutral-700 hover:text-white'
                } ${
                  !isSelectedDay(dayObj) ? highlightTargetDay(dayObj) : ''
                } ${isToday(dayObj)} ${isSelectedDay(dayObj)}`}
              >
                <span>{dayObj.day}</span>

                {/* Dot under today's date */}
                {isToday(dayObj) && (
                  <span className="absolute bottom-1 w-0.5 h-0.5 bg-white rounded-full"></span>
                )}

                {/* Event indicator */}
                {hasEvent(dayObj) && (
                  <span className="absolute bottom-1 w-1.5 h-1.5 bg-green-500 rounded-full"></span>
                )}
              </button>
            </div>
          ))}
        </div>

        {/* Selected Date Display */}
        {selectedDate && (
          <div className="mt-4 grid grid-cols-3 gap-y-2 gap-x-2">
            {availableTimes.map((time, index) => (
              <Button key={index} buttonType="outlineWhiteBase">
                {time}
              </Button>
            ))}

            <span className="text-white">Selected Date:</span>
            <span className="col-span-2 text-white">
              {selectedDate.day}/{selectedDate.month}/{selectedDate.year}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
