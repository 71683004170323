import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Modal } from 'flowbite-react';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import 'moment/locale/sv.js'
// Using local modified version of 'react-big-calendar/lib/css/react-big-calendar.css'
import './react-big-calendar.css'
// Using local modified version of 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import './dragAndDropStyles.css';
import {dateFormatDate, dateFormatHourMinute} from "../../helpers/dateFunctions";
import {daysLong} from "../../helpers/dateStructs";
import DatePicker from "../Calendar";
import iconClock from "shared-assets/icons/clock.svg";
import iconCalendar from "shared-assets/icons/calendar.svg";
import iconClose from "shared-assets/icons/close.svg";
import {CalEventInterface} from "../../interfaces/common";
import CustomButton from "../CustomButton";

const DnDCalendar = withDragAndDrop(Calendar)

interface Props {
  startEvents: CalEventInterface[]
  save?: Function
  calType?: string
  click?: Function
}

const CalendarBig: React.FC<Props> = ({ startEvents, save, calType, click }) => {
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [selectedEvent, setSelectedEvent] = useState<CalEventInterface|null>(null)
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [hourFrom, setHourFrom] = useState<number>(0)
  const [minuteFrom, setMinuteFrom] = useState<number>(0)
  const [hourTo, setHourTo] = useState<number>(0)
  const [minuteTo, setMinuteTo] = useState<number>(0)
  const [events, setEvents] = useState<CalEventInterface[]>(startEvents)
  const [messageId, setMessageId] = useState<number>(startEvents.length + 1)
  // From https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/addons-drag-and-drop-props--on-event-drop
  moment.locale("se")
  const localizer = momentLocalizer(moment);

  const messages = {
    previous: '<',
    next: '>',
    today: 'Nu',
    month: 'Månad',
    week: 'Vecka',
    work_week: 'Vecka',
    day: 'D',
    agenda: '*',
    date: 'Datum',
    time: 'H',
  }

  const EmptyCustomToolbar = () => {
    return (<></>)
  }

  const getTypeTitle = (title: string) => {
    if (title === 'R') {
      if (lang === 'sv') {
        return 'Varje vecka'
      } else {
        return 'Every week'
      }
    } else if (title === 'A') {
      if (lang === 'sv') {
        return 'Tillgänglig'
      } else {
        return 'Available'
      }
    } else if (title === 'B') {
      if (lang === 'sv') {
        return 'Blockerad'
      } else {
        return 'Blocked'
      }
    }
  }

  const EventComponent = (e: CalEventInterface) => {
    const title = getTypeTitle(e.title)
    return (
      <span><strong>{title}</strong></span>
    )
  }

  const eventStyleGetter = (e: CalEventInterface) => {
    // console.log(e)
    let bg = '#00f'
    if (e.title === 'B') {
      bg = '#c00'
    }
    const style = {
      backgroundColor: bg,
    };
    return {
        style: style
    };
  }

  const getCalendarSettings = () => {
    const settings = {
      views: ['week', 'month', 'day'],
      defaultView: 'week',
      components: {},
      selectable: true,
      resizeable: true,
    }
    if (calType === 'recurring') {
      settings.views = ['work_week']
      settings.defaultView = 'work_week'
      settings.components = {
        work_week: {
          header: ({ date, localizer }) => localizer.format(date, 'dddd')
        },
        toolbar: EmptyCustomToolbar,
        event: EventComponent,
      }
    } else if (calType === 'available') {
      settings.views = ['week', 'month']
      settings.defaultView = 'week'
      settings.components = {
        event: EventComponent,
      }
    } else if (calType === 'blocked') {
      settings.views = ['week', 'month']
      settings.defaultView = 'week'
      settings.components = {
        event: EventComponent,
      }
    } else if (calType === 'book_meeting') {
      settings.views = ['week', 'month']
      settings.defaultView = 'week'
      settings.components = {
        event: EventComponent,
      }
      settings.selectable = false
      settings.resizeable = false
    }
    return settings
  }

  const settings = getCalendarSettings()

  const getTitle = () => {
    if (calType === "recurring") {
      return "R"
    } else if (calType === "available") {
      return "A"
    } else if (calType === "blocked") {
      return "B"
    }
  }
  const addEvent = (e: any) => {
    if (save) {
      const newEvents = [...events]
      newEvents.push({
        id: messageId,
        start: e.start,
        end: e.end,
        title: getTitle(),
      })
      // console.log('addEvent for id ' + messageId)
      setMessageId(messageId + 1)
      setEvents(newEvents)
      save(calType, newEvents)
    } else {
      console.log('Not supposed to add stuff')
    }

  }

  const editEvent = (e: CalEventInterface) => {
    if (click) {
      click(e)
    } else {
      setHourFrom(e.start.getHours())
      setMinuteFrom(e.start.getMinutes())
      setHourTo(e.end.getHours())
      setMinuteFrom(e.end.getMinutes())
      setSelectedEvent(e)
    }
  }

  const onChangeEvent = (e) => {
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === e.event.id) {
        le.start = e.start
        le.end = e.end
      }
    }
    setEvents(newEvents)
    save(calType, newEvents)
  }

  const removeEvent = () => {
    const newEvents = []
    for (let le of events) {
      if (le.id != selectedEvent.id) {
        newEvents.push(le)
      }
    }
    setEvents(newEvents)
    setSelectedEvent(null)
    save(calType, newEvents)
  }


  const calHeight = window.innerHeight - 120
  let scrollToTime = new Date()
  scrollToTime.setHours(8)
  scrollToTime.setMinutes(0)
  scrollToTime.setSeconds(0)

  const handleSelectedDay = (d: Date) => {

    const newEvent = selectedEvent
    newEvent.start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), selectedEvent.start.getHours(), selectedEvent.start.getMinutes(), 0)
    newEvent.end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), selectedEvent.end.getHours(), selectedEvent.end.getMinutes(), 0)
    setSelectedEvent(newEvent)

    // Update the list of events for calendar
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === newEvent.id) {
        le.start = newEvent.start
        le.end = newEvent.end
      }
    }
    setEvents(newEvents)
    setShowDatePicker(false)
  }

  const setTime = (e: any, t: string, what: string) => {
    const value = parseInt(e.currentTarget.value)
    const newEvent = selectedEvent
    if (what === "hour") {
      if (t === "from") {
        setHourFrom(value)
        newEvent.start.setHours(value)
      } else if (t === "to") {
        setHourTo(value)
        newEvent.end.setHours(value)
      }
    } else if (what === "minute") {
      if (t === "from") {
        setMinuteFrom(value)
        newEvent.start.setMinutes(value)
      } else if (t === "to") {
        setMinuteTo(value)
        newEvent.end.setMinutes(value)
      }
    }
    setSelectedEvent(newEvent)
    // Update the list of events for calendar
    const newEvents = [...events]
    for (let le of newEvents) {
      if (le.id === newEvent.id) {
        le.start = newEvent.start
        le.end = newEvent.end
      }
    }
    setEvents(newEvents)
  }

  const renderPopupModal = () => {
    if (calType === "recurring") {
      return (
        <div className="mt-4">
          <div className="flex items-center">
            <img className="icon" alt="date" src={iconCalendar}/>
            <div className="ml-2">
              {daysLong[selectedEvent.start.getDay() - 1]}
            </div>
            <div className="ml-4">
              {dateFormatHourMinute(selectedEvent.start)} &ndash; {dateFormatHourMinute(selectedEvent.end)}
            </div>
          </div>
          <CustomButton extraStyle="mt-4 w-full" onClick={() => removeEvent()}>Ta bort</CustomButton>
        </div>
      )
    } else {
      return (
        <>
          <div className="flex items-center justify-between mt-4">
            <div className="flex cursor-pointer relative">
              <img className="icon" alt="date" src={iconCalendar}/>
              <div className="ml-2" onClick={() => setShowDatePicker(true)}>
                {dateFormatDate(selectedEvent.start, false, lang)}
              </div>
              {
                showDatePicker &&
                  <div style={{position: 'absolute', top: '22px', zIndex: 19001, width: '400px'}}
                       className="bg-neutral-800 p-4">
                      <div className="flex justify-end">
                          <img className="cursor-pointer"
                               onClick={() => setShowDatePicker(false)} alt="close" src={iconClose}/>
                      </div>
                      <DatePicker dates={[selectedEvent.start]}
                                  clickDate={(d: Date) => handleSelectedDay(d)}/>
                  </div>
              }
            </div>
            <div className="flex items-center">
              <img className="icon" alt="date" src={iconClock}/>
              <input type="text" value={hourFrom}
                     className="w-10 p-0 text-neutral-800 ml-2" placeholder="HH"
                     onChange={(e) => setTime(e, "from", "hour")}/>:
              <input type="text" value={minuteFrom}
                     className="w-10 p-0 text-neutral-800" placeholder="MM"
                     onChange={(e) => setTime(e, "from", "minute")}/>
              &ndash;
              <input type="text" value={hourTo}
                     className="w-10 p-0 text-neutral-800 ml-2" placeholder="HH"
                     onChange={(e) => setTime(e, "to", "hour")}/>:
              <input type="text" value={minuteTo}
                     className="w-10 p-0 text-neutral-800" placeholder="MM"
                     onChange={(e) => setTime(e, "to", "minute")}/>
            </div>
          </div>
          <CustomButton extraStyle="mt-4 w-full" onClick={() => removeEvent()}>Ta bort</CustomButton>
        </>
      )
    }
  }

  return (
    <>
      <Modal show={selectedEvent != null} onClose={() => setSelectedEvent(null)} size="lg">
        <Modal.Body className="bg-neutral-700 p-4 border border-neutral-300 rounded">
          <>
            {
              selectedEvent != null &&
                <>
                  <div className="flex justify-between">
                    <div className="text-xl font-medium">
                      {getTypeTitle(selectedEvent.title)}
                    </div>
                    <img className="cursor-pointer"
                         onClick={() => {
                           setSelectedEvent(null);
                           setShowDatePicker(false);
                         }} alt="close" src={iconClose}/>
                  </div>
                  {renderPopupModal()}
                </>
            }
          </>
        </Modal.Body>
      </Modal>

      <div className="bg-white text-neutral-800">
        <DnDCalendar
          components={settings.components}
          views={settings.views}
          defaultView={settings.defaultView}
          localizer={localizer}
          messages={messages}
          scrollToTime={scrollToTime}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{height: calHeight}}
          draggableAccessor={() => true}
          resizableAccessor={() => true}
          onSelectSlot={(e) => addEvent(e)}
          onEventDrop={(e) => onChangeEvent(e)}
          onEventResize={(e) => onChangeEvent(e)}
          onSelectEvent={(e: CalEventInterface) => editEvent(e)}
          eventPropGetter={(eventStyleGetter)}
          selectable={settings.selectable}
          popup={true}
          resizable={settings.resizeable}
          culture='sv'
          step={30}
        />
      </div>
    </>
  );
};

export default CalendarBig;
