import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Api from "../../services/Api";
import TabMenu from "../../components/TabMenu/TabMenu";
import AvailabilityRecurring from "./AvailabilityRecurring";
import AvailabilitySpecific from "./AvailabilitySpecific";
import AvailabilityOther from "./AvailabilityOther";
import AvailabilitySync from "./AvailabilitySync";


enum tabs {
  RECURRING_SCHEDULE,
  SPECIFIC_SCHEDULE,
  OTHER_SETTINGS,
  SYNC_EXTERNAL_CALENDAR
}

export default function Availability() {
  const api = Api.getInstance();
  const translation = useTranslation();
  const { t } = translation;
  const [view, setView] = useState<tabs>(tabs.RECURRING_SCHEDULE);
  const [activeTab, setActiveTab] = useState<number>(0);
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      console.log(location.hash);
      const keyVals = {}
      for (const pair of location.hash.substring(1).split('&')) {
        const [k, v] = pair.split('=');
        keyVals[k] = v;
        console.log(k + ' = ' + v);
      }
      window.history.replaceState("", document.title, window.location.pathname);
      if (keyVals['access_token']) {
        const data = {
          email: 'from_url@zebrain.se',
          name: "Google",
          token: keyVals['access_token'],
        }
        api.post('local/synced_calendars/set', data).then(response => {
          setView(tabs.SYNC_EXTERNAL_CALENDAR);
          setActiveTab(3)
        })
      }

    }
  }, [])

  const renderTab = () => {
    if (view === tabs.RECURRING_SCHEDULE) {
      return <AvailabilityRecurring/>
    } else if (view === tabs.SPECIFIC_SCHEDULE) {
      return <AvailabilitySpecific />
    } else if (view === tabs.OTHER_SETTINGS) {
      return <AvailabilityOther />
    } else {
      return <AvailabilitySync />
    }
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
        {t('set-availability')}
      </h1>
      <TabMenu items={[
        {title: t('standard-schedule'), callback: () => setView(tabs.RECURRING_SCHEDULE)},
        {title: t('deviant-schedule'), callback: () => setView(tabs.SPECIFIC_SCHEDULE)},
        {title: t('other-settings'), callback: () => setView(tabs.OTHER_SETTINGS)},
        {title: t('sync-external-calendar'), callback: () => setView(tabs.SYNC_EXTERNAL_CALENDAR)},
      ]}
        active={activeTab}
      />

      {renderTab()}

      <div className="mt-4">&nbsp;</div>
    </>
  );
};