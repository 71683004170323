import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect, ChangeEvent} from "react";
import { Modal, TextInput } from 'flowbite-react';
import CustomButton from "../../components/CustomButton";
import Api from "../../services/Api";
import DatePicker from "../../components/Calendar";
import {dateFormatDate, dateFormatHourMinute} from "../../helpers/dateFunctions";
import CalendarBig from "../../components/CalendarBig";
import {CalEventInterface} from "../../interfaces/common";

interface DateExtra {
  from: Date;
  to: Date;
}


export default function AvailabilitySpecific() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [editDay, setEditDay] = useState<Date|null>(null)
  const [openExtraModal, setOpenExtraModal] = useState<boolean>(false)
  const [openBlockedModal, setOpenBlockedModal] = useState<boolean>(false)
  const [openCalendar, setOpenCalendar] = useState<boolean>(false)
  const [openCalendarBlocked, setOpenCalendarBlocked] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [datesAvailable, setDatesAvailable] = useState<Array<DateExtra>>([])
  const [datesBlocked, setDatesBlocked] = useState<Array<DateExtra>>([])
  const [fromHour, setFromHour] = useState<string>("")
  const [fromMinute, setFromMinute] = useState<string>("00")
  const [toHour, setToHour] = useState<string>("")
  const [toMinute, setToMinute] = useState<string>("00")

  useEffect(() => {
    getData()
  }, [])
  
  const getData = () => {
    api.get('local/coach/availability/get/custom').then((result) => {
      const dA: Array<DateExtra> = []
      for (const d of result.json.available) {
        dA.push({from: new Date(d.from), to: new Date(d.to)})
      }

      const dB: Array<DateExtra> = []
      for (const d of result.json.blocked) {
        dB.push({from: new Date(d.from), to: new Date(d.to)})
      }
      setDatesAvailable(dA)
      setDatesBlocked(dB)
      setLoaded(true)
    })
  }

  const getEvents = (what: string) => {
    const startEvents: Array<CalEventInterface> = []
    let dates = what === "A" ? datesAvailable : datesBlocked
    let id = 1
    for (const d of dates) {
      startEvents.push({
        id: id,
        title: what,
        start: d.from,
        end: d.to
      })
      id++
    }
    return startEvents
  }

  const save = (what: string) => {
    const fromH = parseInt(fromHour)
    const fromM = parseInt(fromMinute)
    const toH = parseInt(toHour)
    const toM = parseInt(toMinute)
    let fromDate = new Date(editDay.getFullYear(), editDay.getMonth(), editDay.getDate(), fromH, fromM )
    let toDate = new Date(editDay.getFullYear(), editDay.getMonth(), editDay.getDate(), toH, toM )
    const data = {}
    if (what === "available") {
      const dE: Array<DateExtra> = [ ...datesAvailable]
      dE.push({from: fromDate, to: toDate})
      data['available'] = dE
      setDatesAvailable(dE)
    } else if (what === "blocked") {
      const dB: Array<DateExtra> = [ ...datesAvailable]
      dB.push({from: fromDate, to: toDate})
      data['blocked'] = dB
      setDatesBlocked(dB)
    }

    api.post('local/coach/availability/set/' + what, data).then((res) => {
      console.log('ok')
    })
  }

  const saveEvents = (what: string, dates: Array<CalEventInterface>) => {
    const dateArr: Array<DateExtra> = []
    for (const d of dates) {
      dateArr.push({
        from: d.start,
        to: d.end,
      })
    }
    const data = {}
    if (what === "available") {
      data['available'] = dateArr
      setDatesAvailable(dateArr)
    } else if (what === "blocked") {
      data['blocked'] = dateArr
      setDatesBlocked(dateArr)
    }

    api.post('local/coach/availability/set/' + what, data).then((res) => {
      console.log('ok')
    })
  }


  const calendarDates = (what: string) => {
    const dateList: Array<Date> = []
    let stored: Array<DateExtra>
    if (what === "available") {
      stored = datesAvailable
    } else {
      stored = datesBlocked
    }
    for (const d of stored) {
      dateList.push(d.from)
    }
    return dateList
  }

  const setTime = (e: ChangeEvent<HTMLInputElement>, t: string, what: string) => {
    const value = e.currentTarget.value
    if (what === "hour") {
      if (t === "from") {
        setFromHour(value)
      } else {
        setToHour(value)
      }
    } else if (what === "minute") {
      if (t === "from") {
        setFromMinute(value)
      } else {
        setToMinute(value)
      }
    } else {
      console.log("Failed!!!!")
    }
  }

  const handleSelectedDay = (d: Date)=> {
    // Check if we have set from and to for this date
    for (const day of datesAvailable) {
      const f = day.from
      if (f.getFullYear() === d.getFullYear() && f.getMonth() === d.getMonth() && f.getDate() === d.getDate()) {
        const t = day.to
        setFromHour(f.getHours().toString())
        setFromMinute(f.getMinutes().toString())
        setToHour(t.getHours().toString())
        setToMinute(t.getMinutes().toString())
      }
    }
    setEditDay(d)
  }

  const formatStartStop = (what: string, index: number, from: Date, to: Date)=> {
    return (
      <div key={"d_" + what + "_" + index} className="flex items-center mt-4">
        <div className="w-32">{dateFormatDate(from, false, lang)}</div>
        <div className="ml-4">
          {dateFormatHourMinute(from)} &ndash; {dateFormatHourMinute(to)}
        </div>
      </div>
    )
  }

  const formatBlocked = (index: number, from: Date, to: Date) => {
    if (from.getDate() === to.getDate()) {
      return formatStartStop("B", index, from, to)
    } else {
      to.setSeconds(to.getSeconds() - 60)
      return (
        <div key={"dE_" + index} className="flex items-center mt-4">
          <div className="w-40">
            {dateFormatDate(from, false, lang)} {dateFormatHourMinute(from)}
          </div>
          <div className="mx-1">
            &ndash;
          </div>
          <div className="w-40 ml-8">
            {dateFormatDate(to, false, lang)} {dateFormatHourMinute(to)}
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Modal show={openCalendar} onClose={() => {
        setOpenCalendar(false);
        getData();
      }} size="4xl">
        <Modal.Header className="bg-neutral-700 p-4 max-w-4xl">
          Välj tider du är tillgänglig
        </Modal.Header>
        <Modal.Body className="bg-neutral-700 p-4">
          {
            openCalendar &&
              <CalendarBig startEvents={getEvents("A")} save={saveEvents} calType="available"/>
          }
        </Modal.Body>
      </Modal>

      <Modal show={openCalendarBlocked} onClose={() => {
        setOpenCalendarBlocked(false);
        getData();
      }} size="4xl">
        <Modal.Header className="bg-neutral-700 p-4 max-w-4xl">
          Välj tider du vill blockera
        </Modal.Header>
        <Modal.Body className="bg-neutral-700 p-4">
          {
            openCalendarBlocked &&
              <CalendarBig startEvents={getEvents("B")} save={saveEvents} calType="blocked"/>
          }
        </Modal.Body>
      </Modal>

      <Modal show={openExtraModal} onClose={() => setOpenExtraModal(false)} size="md">
        <Modal.Header className="bg-neutral-700 p-4">
          Välj datum
        </Modal.Header>
        <Modal.Body className="bg-neutral-700 p-4">
          <DatePicker dates={calendarDates("available")} clickDate={(d: Date) => handleSelectedDay(d)} />
          {
            editDay &&
            <div className="my-4 flex items-center">
              {dateFormatDate(editDay, false, lang)}: available between
                <TextInput type="text" value={fromHour} className="w-12 ml-2" placeholder="HH"
                           onChange={(e) => setTime(e, "from", "hour")}/>:
                <TextInput type="text" value={fromMinute} className="w-12" placeholder="MM"
                           onChange={(e) => setTime(e, "from", "minute")}/>

                <span className="mx-2">and</span>
                <TextInput type="text" value={toHour} className="w-12" placeholder="HH"
                           onChange={(e) => setTime(e, "to", "hour")}/>:
                <TextInput type="text" value={toMinute} className="w-12" placeholder="MM"
                           onChange={(e) => setTime(e, "to", "minute")}/>
            </div>
          }
          <div className="mt-4">
            Du kan välja flera datum samtidigt. Valda datum åsidosätter standardschemat.
          </div>

          <CustomButton
            onClick={() => save('available')}
            extraStyle="w-full mt-2"
          >{t('save')}</CustomButton>
        </Modal.Body>
      </Modal>

      <Modal show={openBlockedModal} onClose={() => setOpenBlockedModal(false)} size="md">
        <Modal.Body className="bg-neutral-700">
          <DatePicker dates={calendarDates("blocked")} clickDate={(d: Date) => handleSelectedDay(d)} />
          {
            editDay &&
              <div className="my-4 flex items-center">
                {dateFormatDate(editDay, false, lang)}: available between
                  <TextInput type="text" value={fromHour} className="w-12 ml-2" placeholder="HH"
                             onChange={(e) => setTime(e, "from", "hour")}/>:
                  <TextInput type="text" value={fromMinute} className="w-12" placeholder="MM"
                             onChange={(e) => setTime(e, "from", "minute")}/>

                  <span className="mx-2">and</span>
                  <TextInput type="text" value={toHour} className="w-12" placeholder="HH"
                             onChange={(e) => setTime(e, "to", "hour")}/>:
                  <TextInput type="text" value={toMinute} className="w-12" placeholder="MM"
                             onChange={(e) => setTime(e, "to", "minute")}/>
              </div>
          }
          <div className="mt-4">
            {t('extra-availability-text')}
          </div>
          <CustomButton
            onClick={() => setOpenBlockedModal(false)}
            extraStyle="w-full mt-4"
            buttonType="outlineWhiteBase"
          >{t('cancel')}</CustomButton>

          <CustomButton
            onClick={() => save('blocked')}
            extraStyle="w-full mt-2"
          >{t('save')}</CustomButton>
        </Modal.Body>
      </Modal>

      <p className="mt-4 text-lg font-medium">Tillgänglig tid</p>

      {
        loaded ? (
          <>
            {
              datesAvailable.map((d, index) => {
                return formatStartStop("A", index, d.from, d.to)
              })
            }
            <CustomButton
              onClick={() => setOpenCalendar(true)}
              extraStyle="mt-4"
              buttonType="outlineWhiteBase"
            >
              +&nbsp;&nbsp; {t('add')}
            </CustomButton>

            <h2 className="mt-8 text-lg font-medium">{t('block-time-dates')}</h2>

            {
              datesBlocked.map((d, index) => {
                return formatBlocked(index, d.from, d.to)
              })
            }

            <CustomButton
              onClick={() => setOpenCalendarBlocked(true)}
              extraStyle="w-full mt-4"
              buttonType="outlineWhiteBase"
            >
              +&nbsp;&nbsp; {t('add')}
            </CustomButton>
          </>
        ) : (
          <div className="mt-4">Loading dates...</div>
        )
      }

    </>
  )
};