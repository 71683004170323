import {ResourcesInterface, ERRORS} from "./interfaces";
import {
  connectPartner,
  handleOffer,
  handleAnswer,
  handleCandidate,
  partnerClosedScreen,
  partnerClosed,
} from "./peer";
import { CONFIG } from "../../constants";


export function sendSocket(mess: any, resources: ResourcesInterface) {
  if (resources.webSocket) {
    if (resources.webSocket.readyState !== WebSocket.OPEN) {
      resources.errorFunc(ERRORS.WEBSOCKET_NOT_OPEN)
      return
    }
    resources.webSocket.send(JSON.stringify(mess))
  }
}

export function sendSocketDebug(msg: string, resources: ResourcesInterface) {
  const mess = {
    type: 'debug',
    command: msg
  }
  sendSocket(mess, resources)
}

export function sendSocketError(msg: string, resources: ResourcesInterface) {
  const mess = {
    type: 'error',
    command: msg
  }
  sendSocket(mess, resources)
}

function messageListener(event: any, resources: ResourcesInterface) {
  const message = JSON.parse(event.data)
  // console.log(message)
  if (message.type === 'meeting') {
    if (message.command === 'init') {
      if (message.status === 'first') {
        console.log('Du är först i mötet...')
      } else if (message.status === 'connect') {
        connectPartner(resources, message.user_id_from, message.user_name_from)
      } else {
        console.log('Error - unknown status in message.user_id_from', message.user_id_from, message.status, message.type, message.command)
      }
    } else if (message.command === 'offer') {
      console.log('Got offer')
      handleOffer(resources, message.user_id_from, message.user_name_from, message.offer, message.kind)
    } else if (message.command === 'answer') {
      console.log('Got answer')
      handleAnswer(resources, message.user_id_from, message.kind, message.answer)
    } else if (message.command === 'icecandidate') {
      console.log('Got icecandidate')
      handleCandidate(resources, message.user_id_from, message.candidate)
    } else if (message.command === 'stopScreen') {
      partnerClosedScreen(resources, message.user_id_from)
    } else if (message.command === 'close') {
      partnerClosed(resources, message.user_id_from)
    } else if (message.command === 'hangup') {
      console.log('----- will hangup session')
      if (window.opener) {
        window.opener.console.log('From opened window')
        console.log('----- Closing window')
        window.opener.postMessage('video_window_close')
      } else {
        // window.location.href = '/app/' + resources.who + '/overview'
        console.log('Received hangup from socket')
      }
    } else {
      console.log('Unknown command ' + message.command)
    }
  } else {
    console.log('Unknown message type: ' + message.type)
  }
}

export function openSocket(resources: ResourcesInterface, userId: string, userName: string) {

  console.log('--------- openSocket')
  resources.webSocket = new WebSocket(CONFIG.WEB_SOCKET_ENDPOINT)

  if (resources.webSocket) {
    resources.webSocket.addEventListener('message', function(event) {
      messageListener(event, resources)
    })

    resources.webSocket.addEventListener('error', function (event:any) {
      if (event.target?.readyState === WebSocket.CLOSED) {
        if (resources.webSocketHasOpened) {
          resources.errorFunc(ERRORS.WEBSOCKET_EVENT_CLOSED)
        } else {
          resources.errorFunc(ERRORS.WEBSOCKET_FAILED_OPEN)
        }

      }
    })

    resources.webSocket.onopen = () => {
      resources.webSocketHasOpened = true
      resources.webSocketPingInterval = setInterval(() => {
        if (resources.webSocket) {
          if (resources.webSocket?.readyState === WebSocket.OPEN) {
            const mess = {
              type: 'ping'
            }
            resources.webSocket.send(JSON.stringify(mess))
          } else {
            resources.errorFunc(ERRORS.WEBSOCKET_NOT_OPEN)
          }
        } else {
          resources.errorFunc(ERRORS.WEBSOCKET_NOT_AVAILABLE)
        }
      }, 30000)

      const message = {
        type: 'session_init',
        user_id: userId,
        user_name: userName,
      }
      sendSocket(message, resources)
    }
  }
}

export async function waitForSocket(resources: ResourcesInterface) {
  const isOpened = () => (resources.webSocket?.readyState === WebSocket.OPEN)

  if (resources.webSocket?.readyState !== WebSocket.CONNECTING) {
    return isOpened()
  }  else {
    const intrasleep = 100
    const ttl = 10000 / intrasleep // time to loop
    let loop = 0
    while (resources.webSocket?.readyState === WebSocket.CONNECTING && loop < ttl) {
      await new Promise(resolve => setTimeout(resolve, intrasleep))
      loop++
    }
    return isOpened()
  }
}

// When we have a local video stream (and websocket), we can connect
export function connectMeeting(resources: ResourcesInterface) {
  const mess = {
    type: 'meeting',
    command: 'init',
    key: resources.meetingKey,
    user_agent: window.navigator.userAgent
  }
  console.log('---- Sending meeting init')
  sendSocket(mess, resources)
}

export function closeSocket(resources: ResourcesInterface) {
  if (resources.webSocket) {
    resources.webSocket.removeEventListener('message', function(event) {
      messageListener(event, resources)
    })
    resources.webSocket.close()
    clearInterval(resources.webSocketPingInterval)
  }

}
