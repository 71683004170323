import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../services/Api";
import LocalBreadcrumbs from "../../components/LocalBreadcrumbs";
import {LocalCrumbsInterface} from "../../interfaces/common";
import {ClientInterface} from "../../interfaces/common";
import {CONFIG} from "../../constants"
import profileIcon from "shared-assets/icons/profile.svg";
import Chat from "../../components/Chat/Chat";

export default function ClientChatPage() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const { clientId } = useParams();
  const [client, setClient] = useState<ClientInterface>()
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    api.get('local/clients/' + clientId).then((result) => {
      setClient(result.json.client)
      setLoaded(true)
    })
  }, [])

  if (!loaded) {
    return (<div className="mt-4">Loading clients...</div>)
  }

  const crumbs: Array<LocalCrumbsInterface> = [
    { name: t('my-clients'), url: '/clients'},
    { name: client.firstName + " " + client.lastName, url: '/clients/' + clientId },
    { name: "Chat" },
  ]

  return (
    <>
      <LocalBreadcrumbs crumbs={crumbs}/>
      <div className="rounded bg-neutral-800" style={{height: "calc(100vh - 200px)"}}>
        <Chat clientId={client.id} isCoach={true}/>
      </div>
    </>
  )
};