import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import Api from "../../services/Api";
import {useNavigate} from "react-router-dom";
import {ClientInterface} from "../../interfaces/common";
import {CONFIG} from "../../constants"
// @ts-ignore
import profileIcon from "../../../assets/images/icons/profile.svg";


export default function ClientPage() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const navigate = useNavigate();
  const [clients, setClients] = useState<Array<ClientInterface>>([])
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    api.get('local/clients').then((result) => {
      setClients(result.json.clients)
      setLoaded(true)
    })
  }, [])

  return (
    <>

      <h2 className="mt-4 text-lg font-medium">{t('my-clients')}</h2>

      {
        loaded ? (
          <>
            {
              clients.map((client, idx) => {
                return (
                  <div key={"client_" + idx} className="mt-4 cursor-pointer flex items-center"
                       onClick={() => navigate('/clients/' + client.id)}>
                    <img className="picture" alt="pic"
                         src={client.picture ? (CONFIG.FILE_HOST + client.picture) : profileIcon}/>
                    <div className="ml-4">
                      <p className="text-lg font-medium">{client.firstName} {client.lastName}</p>
                      <p className="text-neutral-400">CPO, Zebrain</p>
                    </div>
                  </div>
                )
              })
            }
          </>
        ) : (
          <div className="mt-4">Loading clients...</div>
        )
      }

    </>
  )
};