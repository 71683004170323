import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {arrangeVideos} from "./placeVideos"
import {closeSocket, connectMeeting, openSocket, sendSocketError, waitForSocket} from "./socket"
import {formatSoon} from "../../helpers/dateFunctions"
import {
  createLocalInfoDiv,
  createVideoContainer,
  createVideoElement,
  replaceTrackToPeer,
  startShareScreen,
  stopShareScreen,
  unmountVideo,
} from "./peer";
import {
  DeviceInterface,
  ERRORS,
  ExtraWindow,
  MeetingInfoInterface,
  PartnerInterface,
  ResourcesInterface
} from "./interfaces";
import './Video.css';
import {bgImages, connectCanvasStream, startBackground, stopBackground} from "./backgroundImage";
import Api from "../../services/Api";
import TestSound from "./TestSound";
import iconDots from "shared-assets/icons/dots-horizontal.svg"
import {useTranslation} from "react-i18next";
import phoneOff from "../../../assets/images/icons/video/phone-off.svg"
import fullscreenOpen from "../../../assets/images/icons/video/fullscreen_open.svg"
import fullscreenClose from "../../../assets/images/icons/video/fullscreen_close.svg"
import iconCameraOn from "../../../assets/images/icons/video/camera-on.svg"
import iconCameraOff from "../../../assets/images/icons/video/camera-off.svg"
import iconMicOn from "../../../assets/images/icons/video/mic-on.svg"
import iconMicOff from "../../../assets/images/icons/video/mic-off.svg"
import screenshareOn from "../../../assets/images/icons/video/screenshare-on.svg"
import screenshareOff from "../../../assets/images/icons/video/screenshare-off.svg"
import iconChat from "../../../assets/images/icons/chat.svg"
import iconAccount from "shared-assets/icons/account.svg"
import iconClose from "shared-assets/icons/close.svg"
import iconCalendar from "shared-assets/icons/calendar.svg"
// @ts-ignore
import soundFileData from "../../../assets/sound/creativeminds.mp3"
import {CONFIG} from "../../constants";
import Chat from "../../components/Chat/Chat";
import {BookedMeeting} from "../../interfaces/common";
import BookMeeting from "../../components/BookMeeting/BookMeeting";

const soundFile = new Audio(soundFileData)
const debugOrder = false


export default function Meeting(){

  const api = Api.getInstance()
  const navigate = useNavigate();
  const location = useLocation();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const [searchParams] = useSearchParams();

  let localOpened = false
  let devicesChanging = false
  let infoText = ''
  const [fullscreen, setFullscreen] = useState<boolean>(false)
  const [cameraOn, setCameraOn] = useState<boolean>(false)
  const [micOn, setMicOn] = useState<boolean>(false)
  const [useCanvas, setUseCanvas] = useState<boolean>(false)
  const [videoDeviceId, setVideoDeviceId] = useState<string>('unset')
  const [videoGroupId, setVideoGroupId] = useState<string>('unset')
  const [audioDeviceId, setAudioDeviceId] = useState<string>('unset')
  const [audioGroupId, setAudioGroupId] = useState<string>('unset')
  const [audioOutDeviceId, setAudioOutDeviceId] = useState<string>('unset')
  const [menuSection, setMenuSection] = useState<string>(window.innerWidth < 800 ? '' : 'mic')
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [info, setInfo] = useState<string>('')
  const [shareScreen, setShareScreen] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [showTestSpeakers, setShowTestSpeakers] = useState<boolean>(false)
  const [extraWindow, setExtraWindow] = useState<ExtraWindow>(ExtraWindow.None)
  const [small, setSmall] = useState<boolean>(window.innerWidth < 800)
  const meetingInfo = useRef<MeetingInfoInterface|null>(null)
  const videoCompatible = !!navigator.mediaDevices
  const screenShareCompatible = 'getDisplayMedia' in navigator.mediaDevices

  useEffect(() => {
    if (!fullscreen) {
      // We get a fullscreen change on start, make sure we are in fullscreen before exiting fullscreen
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
    } else {
      const elem = document.getElementById("whole_window")
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        }
      }
    }
    // arrangeVideos(resources.current)
  }, [fullscreen])

  const resizeWindow = () => {
    setSmall(window.innerWidth < 800)
    arrangeVideos(resources.current)
  }

  const stateFunc = (key: string, value: any) => {
    if (key === 'shareScreen') {
      setShareScreen(value)
    } else if (key === 'showInfo') {
      setShowInfo(value)
    } else if (key === 'info') {
      setInfo(value)
    } else if (key === 'infoText') {
      infoText = value
    } else if (key === 'arrangeVideos') {
      arrangeVideos(resources.current)
    } else {
      console.log('!!! Unknown key to stateFunc: ' + key)
    }
  }

  const errorFunc = (error: ERRORS) => {
    let errorText: string
    switch(error) {
      case ERRORS.WEBSOCKET_FAILED_OPEN: {
        errorText = 'Failed opening connection to Zebrain system.\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_OPEN: {
        errorText = 'Lost connection to Zebrain system (1).\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_CLOSED: {
        errorText = 'Websocket is closed'
        break
      }
      case ERRORS.WEBSOCKET_EVENT_CLOSED: {
        errorText = 'Lost connection to Zebrain system (2).\nPlease check your internet connection.\n' +
            'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_AVAILABLE: {
        errorText = 'Websocket has died :-(\nTry reloading the page, ' +
            'and if that doesn\'t help, contact support@zebrain.se'
        break
      }
      case ERRORS.RTC_CONNECTION_STATE_FAILED: {
        if (resources.current.rtcHasConnected) {
          errorText = 'Lost video connection. This is probably due to a network error between you and meeting partner.\n'
          errorText += 'Check your internet connection. If it works, try reloading this page.\n'
          errorText += 'If that doesn\'t work, the problem could be with your partner\'s network.\n\n'
          errorText += 'If nothing works, contact support@zebrain.se and we will try to help.'
        } else {
          errorText = 'Failed connecting video stream. This is probably due to a firewall issue.\n'
          errorText += '\nSend a mail to support@zebrain.se and we will help.'
        }

        break
      }
      case ERRORS.RTC_NEGOTIATION_SLOW: {
        errorText = 'If your cannot see your meeting partner within ten seconds, try these steps:\n\n' +
            '1. Exit meeting and join again\n\n' +
            '2. If that doesn\'t work, restart your browser and join meeting again\n\n' +
            '3. If that doesn\'t work, contact support'
        break
      }
      default: {
        errorText = 'Unknown error: ' + error
        break
      }
    }

    displayInfo(errorText, true)
  }

  const who = searchParams.get('who')
  const userId = who === 'external' ? 'external_' + searchParams.get("externalId") :
    window.localStorage[CONFIG.PERSON_TOKEN]
  const userName = who === 'external' ? searchParams.get("name") :
    window.localStorage[CONFIG.USERNAME_TOKEN]
  const resources = useRef<ResourcesInterface>({
    videoDevices: [],
    audioDevices: [],
    audioOutDevices: [],
    audioDeviceId: 'unset',
    audioGroupId: 'unset',
    videoDeviceId: 'unset',
    videoGroupId: 'unset',
    who: who,
    userId: userId,
    userName: userName,
    externalMeeting: who === 'external',
    partners: [] as Array<PartnerInterface>,
    videoContainerRef: useRef(),
    webSocket: undefined,
    webSocketHasOpened: false,
    meetingKey: searchParams.get("meetingKey"),
    stateFunc: stateFunc,
    errorFunc: errorFunc,
    small: false,
    background: '',
    changeBackground: false,
    webSocketPingInterval: null,
    rtcStatus: 'waiting',
    rtcHasConnected: false,
    skipTurn: document.location.search.indexOf('skipTurn') > -1,
    gotInputDevices: false,
    showRightMenu: false,
    extraWindow: ExtraWindow.None,
  })

  async function start() {
    if (debugOrder) console.log('Order 2: start()')
    displayInfo(t('starting-network'), true)
    if (debugOrder) console.log('Order 3: openSocket()')
    openSocket(resources.current, resources.current.userId, resources.current.userName)


    const socketOpened = await waitForSocket(resources.current)
    if (debugOrder) console.log('Order 4: socketOpened is ' + socketOpened)

    if (!socketOpened) {
      if (resources.current.webSocket) {
        if (resources.current.webSocket.readyState === WebSocket.CLOSED) {
          console.log('-- websocket is closed ' + resources.current.webSocket.readyState)
        } else if (resources.current.webSocket.readyState === WebSocket.CONNECTING) {
          console.log('-- websocket is connecting ' + resources.current.webSocket.readyState)
        } else {
          console.log('websocket is in state ' + resources.current.webSocket.readyState)
        }
      }
      return
    }

    displayInfo(t('starting-camera'), true)

    if (debugOrder) console.log('Order 5: getInputDevices')
    resources.current.gotInputDevices = await getInputDevices()


    displayInfo(t('starting-video'), true)

    if (debugOrder) console.log('Order 6: startLocalVideo')
    startLocalVideo()
    if (debugOrder) console.log('Order 7: startLocalVideo done')

    setupMediaChange()
    if (debugOrder) console.log('Order 8: setupMediaChange')
    window.addEventListener('fullscreenchange', fullscreenChange)
    window.addEventListener('resize', resizeWindow)
  }

  useEffect(() => {
    if (debugOrder) console.log('Order 1: useEffect')
    // start will set up all initial resources
    start().then(() => console.log('start() has completed'));

    // When the component is unmounted we need to clean up resources
    return () => {
      console.log('Unmounting resources')
      window.removeEventListener('fullscreenchange', fullscreenChange)
      window.removeEventListener('resize', resizeWindow)
      closeSocket(resources.current)
      unmountVideo(resources.current)
    }
  }, [])

  const getChatData = () => {
    api.get('local/meeting/' + resources.current.who + '/' + resources.current.meetingKey).then(response => {
      meetingInfo.current = {
        meetingStart: response.json.meeting.start,
        meetingLength: response.json.meeting.meetingLength,
        clientId: response.json.meeting.clientId,
        clientName: response.json.meeting.clientName,
        coachId: response.json.meeting.coachId,
        coachName: response.json.meeting.coachName,
        meetingId: response.json.meeting.id,
      }
      let info = "Meeting starts"
      info += ': ' + formatSoon(meetingInfo.current.meetingStart, lang) + ', '
        + 'length' + ': ' + meetingInfo.current.meetingLength + ' minutes'

      info += '\n\nWaiting for '
      if (resources.current.who === 'coach') {
        info += meetingInfo.current.clientName
      } else {
        info += meetingInfo.current.coachName
      }
      info += '...'
      const meetingStart = new Date(meetingInfo.current.meetingStart)
      const now = new Date()
      const secondsToStart = (meetingStart.getTime() - now.getTime()) / 1000
      if (secondsToStart > 3600) {
        info += '\n\n\n\n'
        if (lang === 'sv') {
          info += 'Obs! Det är långt kvar tills mötet startar.'
        } else {
          info += 'NB! It is a long time until meeting starts.'
        }
      }
      displayInfo(info, true)
    })
  }

  const fullscreenF = () => {
    if (document.fullscreenElement) {
      setFullscreen(false)
    } else {
      setFullscreen(true)
    }
  }

  const fullscreenChange = (event: any) => {
    console.log('-------- fullscreenChange checking: ' + fullscreen)
    // console.log(event)
    // setTimeout(() => {
    //   if (!document.fullscreenElement && state.fullscreen) {
    //     console.log('-------- fullscreenChange: ' + false)
    //     setState({"fullscreen": false})
    //   }
    // }, 100)
  }

  const hangup = () => {
    if (window.opener) {
      window.opener.console.log('From opened window')
      console.log('----- Closing window')
      window.opener.postMessage('video_window_close')
      // Catcher in the rye - if window.opener has gone away
      // setTimeout(() => {
      //   window.location.href = '/app/' + who + '/overview'
      // }, 300)
    } else {
      // If location.key is 'default' you are routed here from external link
      if (location.key != 'default') {
        navigate(-1)
      } else {
        navigate('/')
      }
    }
  }

  const setupMediaChange = () => {
    navigator.mediaDevices.ondevicechange = () => {
      console.log('-------- media device change')
      if (devicesChanging) {
        console.log('---- warning: devices are in changing state')
        return
      }
      devicesChanging = true
      const oldAudioGroupId = audioGroupId
      const oldVideoGroupId = videoGroupId

      getInputDevices().then(() => {
        if (localOpened) {
          if (oldAudioGroupId !== audioGroupId || oldVideoGroupId !== videoGroupId) {
            console.log('Different audio or video')
            if (oldAudioGroupId !== audioGroupId) {
              console.log('Different audio: ' + oldAudioGroupId + ' !== ' + audioGroupId)
              stopLocalStream('audio')
            }
            if (oldVideoGroupId !== videoGroupId) {
              console.log('Different video: ' + oldVideoGroupId + ' !== ' + videoGroupId)
              stopLocalStream('video')
            }

            changeLocalVideo()
            console.log('Changed input stream')
          }
        }
        devicesChanging = false
      })
    }
  }

  // Local video methods
  async function getInputDevices() {
    console.log('------ getInputDevices starting')
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices: Array<DeviceInterface> = []
    const audioDevices: Array<DeviceInterface> = []
    const audioOutDevices: Array<DeviceInterface> = []
    for (let device of mediaDevices) {
      if (device.kind === 'audioinput') {
        audioDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      } else if (device.kind === 'videoinput') {
        videoDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId
        })
      } else if (device.kind === 'audiooutput') {
        audioOutDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      }
    }

    resources.current.audioDevices = audioDevices
    resources.current.videoDevices = videoDevices
    resources.current.audioOutDevices = audioOutDevices

    if (audioDevices.length > 0 && audioDevices[0].id !== ''
      && videoDevices.length > 0 && videoDevices[0].id !== '') {
      console.log('Setting audioDevices to ' + audioDevices[0].id + ' v ' + videoDevices[0].id)
      resources.current.audioDeviceId = audioDevices[0].id
      resources.current.audioGroupId = audioDevices[0].groupId
      resources.current.videoDeviceId = videoDevices[0].id
      resources.current.videoGroupId = videoDevices[0].groupId
      setAudioDeviceId(audioDevices[0].id)
      setAudioGroupId(audioDevices[0].groupId)
      setVideoDeviceId(videoDevices[0].id)
      setVideoGroupId(videoDevices[0].groupId)
      return true
    }
    return false
  }

  const getConstraints = () => {
    let video:any = resources.current.videoDeviceId === 'unset' ? true : {deviceId: {exact: resources.current.videoDeviceId}}
    const audio = resources.current.audioDeviceId === 'unset' ? true : {deviceId: {exact: resources.current.audioDeviceId}}
    return {
      video: video,
      audio: audio
    }
  }

  function startLocalVideo() {

    if (resources.current.videoContainer) {
      // React sometimes keeps this. Append to parent
      resources.current.videoContainerRef.current.appendChild(resources.current.videoContainer)
    } else {
      resources.current.videoContainer = createVideoContainer(resources.current.videoContainerRef.current)
    }
    if (resources.current.videoElement) {
      // React sometimes keeps this. Append to parent
      resources.current.videoContainer.appendChild(resources.current.videoElement)
    } else {
      resources.current.videoElement = createVideoElement(resources.current.videoContainer)
      resources.current.videoElement.classList.add('video_mirrored')
    }
    
    const constraints = getConstraints()

    if (debugOrder) console.log('Order 7.1: got constraints')

    if (!resources.current.gotInputDevices) {
      displayInfo('Waiting for camera access approval',true)
    }

    if (debugOrder) console.log('Order 7.2: getUserMedia')
    console.log(constraints)

    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {

      if (debugOrder) console.log('Order 7.3: in getUserMedia')
      if (!resources.current.externalMeeting) {
        displayInfo('Looking up meeting information',true)
        getChatData()
        if (debugOrder) console.log('Order 7.4: in getChatData')
      } else {
        setShowInfo(false)
      }
      if (resources.current.videoContainer) {
        if (resources.current.localInfoElement) {
          resources.current.videoContainer.appendChild(resources.current.localInfoElement)
        } else {
          resources.current.localInfoElement = createLocalInfoDiv(resources.current, resources.current.videoContainer, displayInfo)
        }

        if (debugOrder) console.log('Order 7.5: createLocalInfoDiv')
      }

      resources.current.localStream = localStream
      // In case we could not get input devices due to allow access dialogue, run getInputDevices again
      if (debugOrder) console.log('Order 7.6: getInputDevices')
      if (resources.current.videoDeviceId === 'unset' || resources.current.audioDeviceId === 'unset') {
        console.log('getInputDevices since v ' + resources.current.videoDeviceId + ' a ' + resources.current.audioDeviceId)
        getInputDevices().then(result => {
          resources.current.gotInputDevices = result
        })
      }
      try {
        // If user changed mic or camera, respect if the turned off camera or mic

        if (resources.current.videoElement) {
          if (debugOrder) console.log('Order 7.7: has videoElement')
          resources.current.videoElement.onloadedmetadata = () => {
            if (debugOrder) console.log('Order 7.8: onloadedmetadata')
            localOpened = true
            arrangeVideos(resources.current)
            setMicOn(true)
            setCameraOn(true)
            connectMeeting(resources.current)
          }

          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          resources.current.videoElement.muted = true
          resources.current.videoElement.srcObject = localStream
        } else {
          alert('Failed getting videoElement')
          sendSocketError('No video element', resources.current)
        }


      } catch (error) {
        displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
        sendSocketError('Failed opening video', resources.current)
      }
    }).catch(err => {
      sendSocketError('Failed getUserMedia', resources.current)
    })
  }

  function changeLocalVideo() {
    const constraints = getConstraints()
    try {
      navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
        resources.current.localStream = localStream
        if (resources.current.videoElement) {
          console.log('-------- changeLocalVideo c ' + cameraOn + ' m ' + micOn)
          // If user changed mic or camera, respect if the turned off camera or mic
          if (!micOn || !cameraOn) {
            resources.current.localStream?.getTracks().forEach((track: MediaStreamTrack) => {
              if (track.kind === 'video') {
                track.enabled = cameraOn
              } else if (track.kind === 'audio') {
                track.enabled = micOn
              }
            })
          }
          resources.current.videoElement.onloadedmetadata = () => {
            if (useCanvas) {
              connectCanvasStream(resources.current)
            } else {
              replaceTrackToPeer(resources.current)
            }
          }
          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          resources.current.videoElement.muted = true
          resources.current.videoElement.srcObject = localStream
        }

      }).catch(err => {
        sendSocketError('Failed getUserMedia', resources.current)
      })
    } catch (error) {
      displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
      sendSocketError('Failed opening video', resources.current)
    }
  }

  const stopLocalStream = (kind: string) => {
    if (resources.current.localStream) {
      resources.current.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === kind && track.readyState === 'live') {
          console.log('Change, stopping previous track ' + track.kind)
          track.stop()
        }
      })
    }
  }

  const changeVideo = (deviceId: string) => {
    if (deviceId === resources.current.videoDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    stopLocalStream('video')

    setOpenMenu(false)
    resources.current.videoDeviceId = deviceId
    setVideoDeviceId(deviceId)
    changeLocalVideo()
  }

  const changeAudio = (deviceId: string) => {
    if (deviceId === resources.current.audioDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    stopLocalStream('audio')

    resources.current.audioDeviceId = deviceId
    setAudioDeviceId(deviceId)
    changeLocalVideo()
    console.log('Changed to ' + deviceId)
  }

  const changeOutAudio = (deviceId: string) => {
    if (deviceId === audioOutDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }

    if (resources.current.videoElement && 'setSinkId' in HTMLMediaElement.prototype) {
      resources.current.audioDeviceId = deviceId
      setAudioDeviceId(deviceId)
      // @ts-ignore
      resources.videoElement.setSinkId(deviceId)
      // @ts-ignore
      soundFile.setSinkId(deviceId)
      console.log('Changed audioDeviceId to ' + deviceId)
    }
  }

  const displayInfo = (infoTxt: string, reset: boolean) => {
    if (reset) {
      infoText = infoTxt
    } else {
      infoText += '\n' + infoTxt
    }
    setInfo(infoText)
    setShowInfo(true)
  }

  const toggleCamera = () => {
    if (resources.current.localStream) {
      resources.current.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'video') {
          const newState = !cameraOn
          track.enabled = newState
          setCameraOn(newState)
        }
      })
    }
  }

  const toggleMic = () => {
    if (resources.current.localStream) {

      resources.current.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'audio') {
          const newState = !micOn
          track.enabled = newState
          setMicOn(newState)
        }
      })
    }
  }

  const toggleExtraWindow = (selectWindow: ExtraWindow) => {
    resources.current.extraWindow = resources.current.extraWindow === selectWindow ? ExtraWindow.None
      : selectWindow
    setExtraWindow(resources.current.extraWindow)
    setOpenMenu(false)
    arrangeVideos(resources.current)
  }

  const toggleShareScreen = () => {
    const newShareScreen = !shareScreen
    if (newShareScreen) {
      startShareScreen(resources.current)
    } else {
      stopShareScreen(resources.current)
    }
    setShareScreen(newShareScreen)
  }

  const toggleCanvas = (background: string) => {
    const shouldUseCanvas = background.length > 0
    const changeCanvas = useCanvas !== shouldUseCanvas
    resources.current.background = background
    resources.current.changeBackground = true
    setOpenMenu(false)
    setUseCanvas(shouldUseCanvas)
    if (changeCanvas) {
      if (shouldUseCanvas) {
        startBackground(resources.current)
      } else {
        stopBackground(resources.current)
        replaceTrackToPeer(resources.current)
        setTimeout(() => {
          // Allow for canvas to be removed
          arrangeVideos(resources.current)
        }, 200)

      }
    }
  }

  const testSpeakers = () => {
    if (showTestSpeakers) {
      soundFile.pause()
    } else {
      soundFile.play()
    }
    setShowTestSpeakers(!showTestSpeakers)
  }

  const speakertext = () => {
    if (showTestSpeakers) {
      return (
        lang === 'sv' ?
        'Spelar upp musik...'
        : 
        `Playing music...`)
    } else {
      return (
      lang === 'sv' ?
        'Testa dina högtalare'
        : 
        `Test your speakers`)
    }
  }

  const showMenu = () => {
    return (
      <div className="video_menu_page bg-neutral-800 px-4 py-2">
        {
          small &&
            <>
                <div className="flex items-center cursor-pointer"
                     onClick={() => toggleExtraWindow(ExtraWindow.Chat)}>
                    <img alt="chat" className="icon"
                         title="chat"
                         src={iconChat}/>
                    <div className="ml-2">Chat</div>
                </div>

                <hr className="my-2 border-neutral-600"/>

                <div className="flex items-center cursor-pointer"
                     onClick={() => toggleExtraWindow(ExtraWindow.BookMeeting)}>
                    <img alt="book" className="icon"
                         src={iconCalendar}/>
                    <div className="ml-2">Boka möte</div>
                </div>

                <hr className="my-2 border-neutral-600"/>

                <div className="cursor-pointer flex"
                     onClick={() => toggleExtraWindow(ExtraWindow.Client)}>
                    <img alt="client" className="icon"
                         title="client"
                         src={iconAccount}/>
                    <div className="ml-2">Klient</div>
                </div>

                <hr className="my-2 border-neutral-600"/>
            </>
        }

        {
          (small && screenShareCompatible) &&
            <>
                <div className="cursor-pointer flex" onClick={() => toggleShareScreen()}>
                    <img alt="present" className="icon"
                         title={shareScreen ? "Stop" : "Start"}
                         src={shareScreen ? screenshareOff : screenshareOn}/>
                    <div className="ml-2">Dela skärm</div>
                </div>
                <hr className="my-2 border-neutral-600"/>
            </>
        }


        <div className="cursor-pointer flex" onClick={() => {
          fullscreenF();
          setOpenMenu(false);
          }}>
          <img title="fullscreen" alt="fullscreen" className="icon"
               src={fullscreen ? fullscreenClose : fullscreenOpen}/>
          <div className="ml-2">Fullskärm</div>
        </div>

        <hr className="my-2 border-neutral-600"/>

        <div className="cursor-pointer flex" onClick={() => toggleExtraWindow(ExtraWindow.Background)}>
          <img alt="background" className="icon"
               src={iconChat}/>
          <div className="ml-2">Välj bakgrund</div>
        </div>

        <hr className="my-2 border-neutral-600"/>

        <div className="flex items-center cursor-pointer"
             onClick={() => toggleExtraWindow(ExtraWindow.Settings)}>
          <img alt="settings" className="icon"
               src={iconChat}/>
          <div className="ml-2">Inställningar</div>
        </div>
      </div>
    )
  }

  const renderExtraWindow = () => {
    if (extraWindow === ExtraWindow.Chat) {
      return (
        <Chat clientId={meetingInfo.current.clientId} isCoach={true}/>
      )
    } else if (extraWindow === ExtraWindow.Settings) {
      return (
        <>
          <div>
            <div className="text-lg font-medium">Video</div>
            {
              resources.current.videoDevices.length > 1 &&
                <select className="mt-4 page_select w100" value={videoDeviceId}
                        onChange={(e) => changeVideo(e.target.value)}>
                  {
                    resources.current.videoDevices.map((device, index) => {
                      return (
                        <option key={"video_" + index} value={device.id}>
                          {device.label}
                        </option>
                      )
                    })
                  }
                </select>
            }
          </div>
          <div className="mt-8">
            <div className="text-lg font-medium">Mikrofon</div>
            {
              resources.current.audioDevices.length > 1 &&
                <select className="my-4 page_select w100" value={audioDeviceId}
                        onChange={(e) => changeAudio(e.target.value)}>
                  {
                    resources.current.audioDevices.map((device, index) => {
                      return (
                        <option key={"audio_" + index} value={device.id}>
                          {device.label}
                        </option>
                      )
                    })
                  }
                </select>
            }
            <TestSound resources={resources.current} micOn={micOn} audioDeviceId={audioDeviceId}/>
          </div>
          <div className="mt-8">
            <div className="text-lg font-medium">{lang === 'sv' ? 'Högtalare' : 'Speakers'}</div>
            {
              resources.current.audioOutDevices.length > 1 &&
                <select className="mt-4 page_select w100" value={audioOutDeviceId}
                        onChange={(e) => changeOutAudio(e.target.value)}>
                  {
                    resources.current.audioOutDevices.map((device, index) => {
                      return (
                        <option key={"audio_" + index} value={device.id}>
                          {device.label}
                        </option>
                      )
                    })
                  }
                </select>
            }
            <div className="mt-4">
              <div onClick={() => testSpeakers()} className="flex">
                <div className={'video_test_sound ' + (showTestSpeakers ? 'on' : 'off')}/>
                <div style={{marginLeft: '5px'}} className='align-center'>
                  {speakertext()}
                </div>
              </div>
              <div style={{fontStyle: 'italic'}} className="mt-4 font_small">
                {lang === 'sv' ?
                  'Om du hör musik spelas, så fungerar dina högtalare'
                  :
                  `If you hear music, your speakers are working.`
                }
              </div>

              <h4 className="mt-4">
                {lang === 'sv' ? 'Hör du inget ljud?' : `Can't hear any sound?`}
              </h4>
              <div className="mt-4 font_small">
                {lang === 'sv' ? 'Kontrollera att dina högtalare är på' : `Check if your speakers are on.`}
              </div>
            </div>
          </div>
        </>
      )
    } else if (extraWindow === ExtraWindow.BookMeeting) {
      const meeting: BookedMeeting = {
        id: 0,
        clientId: meetingInfo.current.clientId,
        length: 30,
        key: 'no_key',
        firstName: meetingInfo.current.clientName,
        lastName: '',
        start: '2024-11-25 12:00:00Z',
        startDate: new Date(),
        status: 'no_status'
      }
      return (
        <div className="mr-4 mt-4">
          <BookMeeting meeting={meeting} close={() => toggleExtraWindow(ExtraWindow.None)} lang={lang}/>
        </div>
      )
    } else if (extraWindow === ExtraWindow.Background) {
      return (
        <div className="mt-4 mr-4">
          <div className="grid grid-cols-3 gap-2">
            {
              bgImages.map((bgImage, index) => {
                return (
                  <div key={"bg_" + index}
                       className={"cursor-pointer rounded border " + (resources.current.background === bgImage.image ? "border-sky-700" : "border-neutral-300")}
                       onClick={() => toggleCanvas(bgImage.image)}>
                    {
                      bgImage.src.length > 0 ? (
                        <img key={index} alt={bgImage.name_en} src={bgImage.src}/>
                      ) : (
                        <div className="flex items-center justify-center p-4">
                          {lang === 'sv'? bgImage.name_sv : bgImage.name_en}
                        </div>
                      )
                    }
                  </div>
                  )
                }
              )
            }
          </div>
        </div>
      )
    } else {
      return (
        <div>Not implemented {extraWindow} yet :-(</div>
      )
    }
  }

  const extraMenuTitle = () => {
    if (extraWindow === ExtraWindow.Chat) {
      return "Chat"
    } else if (extraWindow === ExtraWindow.Settings) {
      return "Inställningar"
    } else if (extraWindow === ExtraWindow.Client) {
      return "Klient"
    } else if (extraWindow === ExtraWindow.BookMeeting) {
      return "Boka möte"
    } else if (extraWindow === ExtraWindow.Background) {
      return "Välj bakgrund"
    }
    return "??"
  }

  if (!videoCompatible) {
    return (
      <div className="m-4">Your browser is not compatible with video conferences</div>
    )
  } else {
    return (
      <div id="whole_window">
        <div ref={resources.current.videoContainerRef}/>
        {
          showInfo &&
            <div className="video_info">
                <div className="flex justify-end">
                    <img src={iconClose} alt="close" onClick={() => setShowInfo(false)}
                         className="cursor-pointer"/>
                </div>
              {info}
            </div>
        }

        {
          openMenu && showMenu()
        }
        {
          extraWindow != ExtraWindow.None &&
            <div className="video_extra_window">
              <div className="flex justify-center relative p-4">
                  <div className="text-lg font-medium">
                    {extraMenuTitle()}
                  </div>
                  <img src={iconClose} className="icon-lg cursor-pointer" alt="close"
                       style={{position: 'absolute', right: '16px', top: '12px'}}
                       onClick={() => toggleExtraWindow(ExtraWindow.None)}/>
              </div>
              <hr className="border-neutral-600"/>
              {renderExtraWindow()}
            </div>
        }

        <div className="video_footer flex items-center justify-center">
          <div className="flex justify-evently">

            <div className="cursor-pointer video_footer_icon text-xs" onClick={() => toggleMic()}>
              <img alt="microphone" className="video_icon"
                   title={micOn ? "Mute" : "Unmute"}
                   src={micOn ? iconMicOn : iconMicOff}/>
              Mikrofon
            </div>

            <div className="cursor-pointer video_footer_icon text-xs" onClick={() => toggleCamera()}>
              <img title={cameraOn ? "Hide" : "Show"}
                   alt="camera" className="video_icon"
                   src={cameraOn ? iconCameraOn : iconCameraOff}/>
              Kamera
            </div>

            <div style={{borderLeft: "1px solid #4a4a6b"}}>&nbsp;</div>

            {
              !small &&
                <div className={"cursor-pointer video_footer_icon text-xs px-2" +
                  (extraWindow === ExtraWindow.Chat ? " bg-neutral-600 rounded" : "")}
                     onClick={() => toggleExtraWindow(ExtraWindow.Chat)}>
                    <img alt="chat" className="video_icon"
                         title="chat"
                         src={iconChat}/>
                    Chat
                </div>
            }

            {
              !small &&
                <div className={"cursor-pointer video_footer_icon text-xs px-2" +
                  (extraWindow === ExtraWindow.BookMeeting ? " bg-neutral-600 rounded" : "")}
                     onClick={() => toggleExtraWindow(ExtraWindow.BookMeeting)}>
                    <img alt="book" className="video_icon"
                         src={iconCalendar}/>
                    Boka
                </div>
            }

            {
              (!small && screenShareCompatible) &&
                <div className="cursor-pointer video_footer_icon text-xs" onClick={() => toggleShareScreen()}>
                    <img alt="present" className="video_icon"
                         title={shareScreen ? "Stop" : "Start"}
                         src={shareScreen ? screenshareOff : screenshareOn}/>
                    Dela
                </div>
            }

            {
              !small &&
                <div className={"cursor-pointer video_footer_icon text-xs px-2" +
                  (extraWindow === ExtraWindow.Client ? " bg-neutral-600 rounded" : "")}
                     onClick={() => toggleExtraWindow(ExtraWindow.Client)}>
                    <img alt="client" className="video_icon"
                         title="clint"
                         src={iconAccount}/>
                    Klient
                </div>
            }

            <div className="cursor-pointer video_footer_icon text-xs"
                 onClick={() => setOpenMenu(!openMenu)}>
              <img alt="menu" className="video_icon"
                   src={iconDots}/>
              Mer
            </div>

            <div className="cursor-pointer video_footer_icon" onClick={() => hangup()}>
              <img title="Leave" alt="hangup" className="video_icon"
                   src={phoneOff}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
