import React from 'react';
import {useNavigate} from "react-router-dom";
import {LocalCrumbsInterface} from '../../interfaces/common';
// @ts-ignore
import chevronRightIcon from '../../../assets/images/icons/chevronRight.svg';


interface Props {
    crumbs: Array<LocalCrumbsInterface>;
}

const LocalBreadcrumbs: React.FC<Props> = ({ crumbs }: Props) => {

    const navigate = useNavigate();

    const click = (crumb: LocalCrumbsInterface) => {
        if (crumb.url) {
            navigate(crumb.url)
        }
    }

    return (
        <nav aria-label="breadcrumb" className="w-full my-4 desktop:my-6 justify-center">
            <div className="flex  flex-col justify-start align-middle w-full desktop:w-151">
                <ol className="flex flex-wrap items-center text-neutral-400 text-xs">
                    {crumbs.map((crumb, index) => (
                        <li key={index} className={"flex items-center " + (crumb.url ? "cursor-pointer" : "")}
                            onClick={() => click(crumb)}>
                            <p className="hover:text-primary-500">{crumb.name}</p>
                            {index < crumbs.length - 1 && (
                                <img
                                    src={chevronRightIcon}
                                    alt="Chevron Right"
                                    className="w-4 h-4 mx-1.5"
                                />
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </nav>
    );
};

export default LocalBreadcrumbs;
