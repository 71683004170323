import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import TabMenu from "../../components/TabMenu/TabMenu"

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import Api from "../../services/Api";
import {BookedMeeting} from "../../interfaces/common";
import {dateFormatHourMinute, getMonthShort, calculateEndTime, todayStr} from "../../helpers/dateFunctions";
import iconClock from "shared-assets/icons/clock.svg";
import iconDots from "shared-assets/icons/dots-horizontal.svg";
import Popup from "../../components/Popup/Popup"
import CustomButton from "../../components/CustomButton";
import CancelMeeting from "../../components/CancelMeeting/CancelMeeting";

export default function BookingsPage() {


  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const navigate = useNavigate();
  const [dayMeetings, setDayMeetings] = useState<Record<string, Array<BookedMeeting>>>({});
  const [view, setView] = useState<string>("coming");
  const [popupEvent, setPopupEvent] = useState<React.MouseEvent<HTMLElement>|undefined>(undefined)
  const [selectedMeeting, setSelectedMeeting] = useState<BookedMeeting|null>(null)
  const [showCancelMeeting, setShowCancelMeeting] = useState<boolean>(false)
  const now = new Date()
  let thisDate = ""
  let prevDate = ""
  let today = todayStr()

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    api.get('local/meetings')
      .then(response => {
        let theDayMeetings: Record<string, Array<BookedMeeting>> = {}
        for (const m of response.json.meetings) {
          const dateStr = m.start.substring(0, 10)
          let dayItem = theDayMeetings[dateStr]
          if (dayItem === undefined) {
            dayItem = []
            theDayMeetings[dateStr] = dayItem
          }
          m.startDate = new Date(m.start)
          dayItem.push(m)
        }
        setDayMeetings(theDayMeetings)
      })
  }

  const showDate = (d: Date, idx: number)=> {
    thisDate = d.getDate() + " " + getMonthShort(d.getMonth(), lang)
    const sameDate = thisDate === prevDate
    let dayStr = todayStr(d)
    prevDate = thisDate
    if (sameDate) {
      return (<></>)
    }
    return (
      <>
        {
          idx > 0 && <hr className="border-neutral-600 my-4" />
        }
        <div className="font-medium">{dayStr === today ? "Idag" : thisDate}</div>
      </>

    )
  }

  const togglePopup = (e: React.MouseEvent<HTMLElement>, meeting: BookedMeeting) => {
    if (popupEvent === undefined) {
      setPopupEvent(e)
      setSelectedMeeting(meeting)
    } else {
      setPopupEvent(undefined)
    }
  }

  const popClick = (what: string) => {
    if (what === 'start') {
      navigate(`/meeting?who=coach&meetingKey=${selectedMeeting.key}`)
    } else if (what === 'cancel') {
      setShowCancelMeeting(true)
    } else {
      alert('Not implemented click handler for ' + what)
    }
  }

  const closeCancelMeeting = (changed: boolean) => {
    setShowCancelMeeting(false)
    if (changed) {
      getData()
    }
  }

  const showMeetingLine = (meeting: BookedMeeting, dayIndex: number, meetingIndex: number) => {
    if (view === "coming" && dayIndex === 0 && meetingIndex === 0 && todayStr(meeting.startDate) === today) {
      return (
        <>
          <div className="text-xl font-medium text-blue-400 mt-4">{meeting.firstName} {meeting.lastName}</div>
          <div className="flex justify-between items-center mt-4">

            <div className="flex items-center">
              <img alt="Time" src={iconClock} className="picture-xs"/>
              <span className="ml-2 text-sm font-medium">
                {dateFormatHourMinute(meeting.startDate)}
                &ndash;
                {dateFormatHourMinute(calculateEndTime(meeting.startDate, meeting.length))}
              </span>
            </div>

            <div className="cursor-pointer" onClick={(e) => togglePopup(e, meeting)}>
              <img className="icon-lg" alt="Options" src={iconDots}/>
            </div>
          </div>
          <CustomButton
            onClick={() => navigate(`/meeting?who=coach&meetingKey=${meeting.key}`)}
            extraStyle="w-full mt-4"
            buttonType="outlineWhiteBase"
          >
            Till möte
          </CustomButton>
        </>
      )
    }
    return (
      <>
        <div className="flex justify-between items-center mt-2">

          <div className="flex items-center">
            <div className="font-medium text-sm text-blue-400 w-32 cursor-pointer"
                 onClick={() => navigate(`/clients/${meeting.clientId}`)}>
              {meeting.firstName} {meeting.lastName}
            </div>
            <div className="flex items-center ml-2">
              <img className="ml-2 picture-xs" alt="Time" src={iconClock}/>
              <span className="ml-2 text-sm font-medium">
                {dateFormatHourMinute(meeting.startDate)}
                &ndash;
                {dateFormatHourMinute(calculateEndTime(meeting.startDate, meeting.length))}
                              </span>
            </div>
          </div>

          <div className="cursor-pointer" onClick={(e) => togglePopup(e, meeting)}>
          <img className="icon-lg" alt="Options" src={iconDots}/>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Popup event={popupEvent} close={() => setPopupEvent(undefined)}>
        <>
          {
            view === "coming" &&
              <>
                <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                     onClick={() => popClick('change')}>
                  Ändra
                </div>
                <hr className="border-neutral-600"/>
              </>
          }
          <div className="px-4 py-2 text-neutral-300 cursor-pointer" onClick={() => popClick('start')}>
           Gå till mötet
          </div>

          {
            view === "coming" &&
              <>
                <hr className="border-neutral-600"/>
                <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                     onClick={() => popClick('cancel')}>
                  Avboka
                </div>
              </>
          }
        </>
      </Popup>
      {
        (showCancelMeeting && selectedMeeting) &&
          <CancelMeeting lang={lang} meeting={selectedMeeting}
                         close={closeCancelMeeting}/>
      }
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
          {t('my-bookings')}
      </h1>
      <TabMenu items={[
        {title: t('coming'), callback: () => setView('coming')},
        {title: t('earlier'), callback: () => setView('previous')},
        ]} active={0}
      />

      <h2 className="text-lg font-medium dark:text-white mt-4 desktop:mt-6">
        {
          view === "coming" ? t('booked-meetings') : t('earlier')
        }
      </h2>
      <div className="rounded bg-neutral-700 p-4 mt-2">
      {
        Object.keys(dayMeetings).filter(day => (view === "coming" ?
          day >= today :
          day <= today))
          .sort((a, b) => {
            if (view === "coming") {
              return (a > b) ? 1 : -1
            } else {
              return (b > a) ? 1 : -1
            }

          }).map(((day, dayIndex) => {
          const meetings = dayMeetings[day]
          return (
            <div key={day}>
              <>
                {
                  meetings.filter(m => (view === "coming" ?
                    m.startDate > now :
                    m.startDate < now))
                    .sort((a, b) => {
                        return (a.startDate > b.startDate) ? 1 : -1
                    })
                    .map((meeting, meetingIndex) => (
                      <div key={meeting.key}>
                        {showDate(meeting.startDate, dayIndex)}
                        {showMeetingLine(meeting, dayIndex, meetingIndex)}
                      </div>
                    ))
                }
              </>
            </div>
            )
        }
        ))
      }
      </div>
    </>
  );
};