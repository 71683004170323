import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import {dateFormatDate, dateFormatHourMinute, isSameDate} from "../../helpers/dateFunctions"
import Api from "../../services/Api";
import {BookedMeeting, CoachStartInterface} from "../../interfaces/common";
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconClock from "shared-assets/icons/clock.svg";
import iconDots from "shared-assets/icons/dots-horizontal.svg";
import CustomButton from "../../components/CustomButton";
import Popup from "../../components/Popup/Popup"
import CancelMeeting from "../../components/CancelMeeting/CancelMeeting";
import BookMeeting from "../../components/BookMeeting/BookMeeting"
import {CONFIG} from "../../constants";


export default function Start() {
  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<CoachStartInterface>({name: "None", meetings: []})
  const [popupEvent, setPopupEvent] = useState<React.MouseEvent<HTMLElement>|undefined>(undefined)
  const [selectedMeeting, setSelectedMeeting] = useState<BookedMeeting|null>(null)
  const [showCancelMeeting, setShowCancelMeeting] = useState<boolean>(false)
  const [showChangeMeeting, setShowChangeMeeting] = useState<boolean>(false)


  useEffect(() => {
    api.waitForToken(getStartData)
  }, [])

  const getStartData = () => {
    api.get('local/coach/start')
      .then(response => {
        let startData = response.json
        for (let m of startData.meetings as Array<BookedMeeting>) {
          m.startDate = new Date(m.start)
        }
        setData(response.json)
        setLoaded(true);
        window.localStorage[CONFIG.PERSON_TOKEN] = response.json.token
        window.localStorage[CONFIG.USERNAME_TOKEN] = response.json.name
      })
  }

  const getMeetings = () => {
    api.get('coach_clients')
      .then(response => {
        console.log(response.json)
      })
  }

  const calculateMeetingsToday = () => {
    let midnight = new Date()
    midnight.setHours(23)
    midnight.setMinutes(59)
    let meetingsToday = 0
    for (const m of data.meetings as Array<BookedMeeting>) {
      if (m.startDate < midnight) {
        meetingsToday++
      }
    }
    if (meetingsToday === 0) {
      return "Du har inga möten idag."
    } else if (meetingsToday === 1) {
      return "Du har 1 möte inbokat idag."
    } else {
      return "Du har " + meetingsToday + " möten inbokade idag."
    }
  }

  const calculateEndTime = (start: Date, l: number) => {
    return new Date(start.getTime() + l * 60000)
  }

  const togglePopup = (e: React.MouseEvent<HTMLElement>, meeting: BookedMeeting) => {
    if (popupEvent === undefined) {
      setPopupEvent(e)
      setSelectedMeeting(meeting)
    } else {
      setPopupEvent(undefined)
    }
  }

  const popClick = (what: string) => {
    if (what === 'start') {
      navigate(`/meeting?who=coach&meetingKey=${selectedMeeting.key}`)
    } else if (what === 'cancel') {
      setShowCancelMeeting(true)
    } else {
      setShowChangeMeeting(true)
    }
  }

  const closeCancelMeeting = (changed: boolean) => {
    setShowCancelMeeting(false)
    if (changed) {
      getStartData()
    }
  }

  const closeChangeMeeting = (changed: boolean) => {
    setShowChangeMeeting(false)
    if (changed) {
      getStartData()
    }
  }

  const showNextMeetings = () => {
    if (data.meetings.length < 1) {
      return (<p>Du har inga möten idag</p>)
    } else {
      const now = new Date()
      const meeting: BookedMeeting = data.meetings[0]
      const endTime = calculateEndTime(meeting.startDate, meeting.length)
      const meetingToday = isSameDate(now, meeting.startDate)
      const moreMeetings: Array<BookedMeeting> = []
      for (let i = 1; i < data.meetings.length; i++) {
        moreMeetings.push(data.meetings[i])
      }

      return (
        <>
          <Popup event={popupEvent} close={() => setPopupEvent(undefined)}>
            <>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('change')}>
                Ändra
              </div>
              <hr className="border-neutral-600"/>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('start')}>
                Gå till mötet
              </div>
              <hr className="border-neutral-600"/>
              <div className="px-4 py-2 text-neutral-300 cursor-pointer"
                   onClick={() => popClick('cancel')}>
                Avboka
              </div>
            </>
          </Popup>

          <div className="rounded-lg mt-6 p-4 w-full dark:bg-neutral-800">
            <p className="font-medium">{meetingToday ? "Idag" : "Nästa möte"}</p>
            <div className="mt-4">
              <p className="text-xl font-medium text-blue-400 cursor-pointer"
                 onClick={() => navigate('/clients/' + meeting.clientId)}>
                {meeting.firstName} {meeting.lastName}
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center mt-2">
                  {
                    meetingToday === false &&
                      <>
                        <img alt="Date" src={iconCalendar} className="icon-lg"/>
                        <span className="mx-2">{dateFormatDate(meeting.startDate, false, lang)}</span>
                      </>
                  }
                  <img className="icon-lg" alt="Time" src={iconClock}/>
                  <span className="ml-2 text-sm font-medium">
                {dateFormatHourMinute(meeting.startDate)} - {dateFormatHourMinute(endTime)}
              </span>
                </div>
                <div className="cursor-pointer" onClick={(e) => togglePopup(e, meeting)}>
                  <img className="icon-lg" alt="Options" src={iconDots}/>
                </div>
              </div>

              <div className="mt-4 mb-2 flex justify-evenly">
                <CustomButton
                  onClick={() => navigate(`/meeting?who=coach&meetingKey=${meeting.key}`)}
                  extraStyle="w-full"
                  buttonType="outlineWhiteBase"
                >
                  Till möte
                </CustomButton>
              </div>
            </div>

            {
              moreMeetings.filter(m => isSameDate(m.startDate, meeting.startDate))
                .map((m, i) => {
                return(
                  <div key={"more_" + i} className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="font-medium text-sm text-blue-400 w-32 cursor-pointer"
                           onClick={() => navigate(`/clients/${m.clientId}`)}>
                        {m.firstName} {m.lastName}
                      </div>
                      <div className="flex items-center ml-2">
                        <img className="ml-2 picture-xs" alt="Time" src={iconClock}/>
                        <span className="ml-2 text-sm font-medium">
                              {dateFormatHourMinute(m.startDate)}
                          &ndash;
                          {dateFormatHourMinute(calculateEndTime(m.startDate, m.length))}
                            </span>
                      </div>
                    </div>

                    <div className="cursor-pointer" onClick={(e) => togglePopup(e, m)}>
                      <img className="icon-lg" alt="Options" src={iconDots}/>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </>
      )
    }
  }

  if (!loaded) {
    return (
      <>
        <h2>Loading...</h2>
      </>
    )
  }

  return (
    <>
      {
        (showCancelMeeting && selectedMeeting) &&
          <CancelMeeting lang={lang} meeting={selectedMeeting}
                         close={closeCancelMeeting}/>
      }
      {
        (showChangeMeeting && selectedMeeting) ? (
            <BookMeeting meeting={selectedMeeting} close={closeChangeMeeting} lang={lang}/>
          ) : (
            <>
              <h1 className="text-3xl mt-4 font-medium dark:text-white" onClick={() => getMeetings()}>
                Hej {data.name}
              </h1>
              <p className="mt-4">{calculateMeetingsToday()}</p>

              {showNextMeetings()}
            </>
          )
      }
    </>
  );
};