import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import Api from "../../services/Api";
import CalendarBig from "../../components/CalendarBig";
import {CalEventInterface} from "../../interfaces/common";
import {convertToWeekDate} from "../../helpers/dateFunctions";
import {CustomButton} from "shared-components";

interface AvailInterface {
  type: string
  from: Date
  to: Date
}

export default function AvailabilitySchedule() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t } = translation;
  const [available, setAvailable] = useState<Array<CalEventInterface>>([]);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const today = new Date()

  const getData = () => {
    api.get('local/coach/availability/get').then((result) => {
      const availableEvents: Array<CalEventInterface> = []

      let id = 1
      for (const available of result.json.available) {
        let from = new Date(available.from)
        let to = new Date(available.to)
        if (available.title === 'R') {
          from = convertToWeekDate(today, from)
          to = convertToWeekDate(today, to)
        }
        availableEvents.push({
          id: id,
          dbId: available.id,
          title: available.title,
          start: from,
          end: to,
        })
        id++
      }

      setAvailable(availableEvents)
      setLoaded(true)
    })
  }

  if (!loaded) {
    return (<div className="mt-4">Loading...</div>)
  }

  return (
    <>
      <div className="mt-4">
        <CalendarBig key="cal_r" startEvents={available} save={true} calType="available"/>
      </div>
    </>
  )
};