import React, { useState } from 'react';

interface TabItem {
  title: string;
  callback: Function;
}

interface Props {
  items: TabItem[];
  active?: number;
}

const TabMenu: React.FC<Props> = ({ items, active }) => {
  const [activeTab, setActiveTab] = useState(active ? active : 0);

  const inactiveClasses = 'text-neutral-400 whitespace-nowrap cursor-pointer hover:text-primary-400';
  const activeClasses = 'underline underline-offset-8 text-primary-500'

  const click = (item: TabItem, index: number) => {
    setActiveTab(index);
    item.callback()
  }
  return (
    <>
      {/* Tab headers */}
      <div className="flex border-b border-b-sky-950" style={{paddingBottom: "2px"}}>
          {items.map((item, index) => (
            <div key={"tab_" + index}
              className={"mr-4 " + (activeTab === index ? activeClasses : inactiveClasses)}
              onClick={() => click(item, index)}
            >
        {item.title}
      </div>

          ))}
      </div>
    </>
  );
}

export default TabMenu;