import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Api from "../../services/Api";
import TabMenu from "../../components/TabMenu/TabMenu";
import AvailabilitySchedule from "./AvailabilitySchedule";
import AvailabilityOther from "./AvailabilityOther";
import AvailabilitySync from "./AvailabilitySync";


enum tabs {
  SCHEDULE,
  OTHER_SETTINGS,
  SYNC_EXTERNAL_CALENDAR
}

export default function Availability() {
  const api = Api.getInstance();
  const translation = useTranslation();
  const { t } = translation;
  const [view, setView] = useState<tabs>(tabs.SCHEDULE);
  const [activeTab, setActiveTab] = useState<number>(0);
  const location = useLocation()

  useEffect(() => {
    if (location.search) {
      console.log(location.search);
      const keyVals = {}
      for (const pair of location.search.substring(1).split('&')) {
        const [k, v] = pair.split('=');
        keyVals[k] = decodeURIComponent(v);
        console.log(k + ' = ' + v);
      }

      if (keyVals['code']) {
        window.history.replaceState("", document.title, window.location.pathname);
        const state: string = keyVals['state']
        let name = "Google"
        if (state.startsWith("Microsoft")) {
          name = "Microsoft"
        }
        const data = {
          email: 'unknown_for_now',
          name: name,
          code: keyVals['code'],
        }
        api.post('local/synced_calendars/set', data).then(response => {
          setView(tabs.SYNC_EXTERNAL_CALENDAR);
          setActiveTab(3)
        })
      }

    }
  }, [])

  const renderTab = () => {
    if (view === tabs.SCHEDULE) {
      return <AvailabilitySchedule/>
    } else if (view === tabs.OTHER_SETTINGS) {
      return <AvailabilityOther />
    } else {
      return <AvailabilitySync />
    }
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
        {t('set-availability')}
      </h1>
      <TabMenu items={[
        {title: t('schedule'), callback: () => setView(tabs.SCHEDULE)},
        {title: t('other-settings'), callback: () => setView(tabs.OTHER_SETTINGS)},
        {title: t('sync-external-calendar'), callback: () => setView(tabs.SYNC_EXTERNAL_CALENDAR)},
      ]}
        active={activeTab}
      />

      {renderTab()}
    </>
  );
};