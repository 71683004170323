import '../../lang/i18n';
import React, {useState, useEffect} from "react";
import Api from "../../services/Api";
import CustomButton from "../../components/CustomButton";
import googleCalendarImg from "../../../assets/images/google_calendar.svg"
import googleImg from "../../../assets/images/google.svg"
import trashIcon from "../../../assets/images/icons/trash.svg"
import microsoftCalendarImg from "../../../assets/images/microsoft_calendar.svg"
import {CONFIG} from "../../constants";

interface SyncedCalendarInterface {
  id: number
  name: string
  email: string
}

export default function AvailabilitySync() {

  const api = Api.getInstance();
  const [calendars, setCalendars] = useState<Array<SyncedCalendarInterface>>([]);
  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    api.get('local/synced_calendars/get').then((result) => {
      setCalendars(result.json.calendars)
    })
  }

  const getEvents = (calendarId: number) => {
    api.get('local/synced_calendars/events/' + calendarId).then((result) => {
      console.log(result.json.events)
    })
  }

  const deleteCalendar = (id: number) => {
    api.get('local/synced_calendars/delete/' + id).then(() => {
      getData()
    })
  }

  const showSyncedCalendars = () => {
    if (calendars.length === 0) {
      return (
        <></>
      )
    } else {
      return (
        <>
          <div className="text-lg font-medium mt-4">
            Synkade kalendrar
          </div>
          {
            calendars.map((calendar, index) => {
              const img = calendar.name === "Microsoft" ? microsoftCalendarImg : googleCalendarImg
              return (
                <div key={"synced_calendar_" + index} className="mt-4 flex justify-between items-center">
                  <div className="w-1/3 flex items-center">
                    <img src={img} alt="Cal" onClick={() => getEvents(calendar.id)}/>
                    <div className="ml-4 text-sm">
                      <div className="font-medium">{calendar.name}</div>
                      <div>{calendar.email}</div>
                    </div>
                  </div>

                  <div>
                    <img src={trashIcon} alt="delete" className="cursor-pointer"
                         onClick={() => deleteCalendar(calendar.id)} />
                  </div>
                </div>
              )
            })
          }
        </>
      )
    }
  }

  const syncMicrosoft = () => {
    console.log('syncMicrosoft')
    const clientId = 'bf280ab1-e7b1-4826-bbc0-2b73bb3bf11d'
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&scope=Calendars.Read%20Calendars.Read.Shared%20offline_access&response_mode=query&state=Microsoft12`
  }

  const syncGoogle = () => {
    // https://developers.google.com/identity/protocols/oauth2/web-server#httprest_3
    console.log('syncGoogle')
    const clientId = '1083297605583-h136trshd252u8ts8l1frh3p6nmsoj3s.apps.googleusercontent.com'
    const redirect = CONFIG.APP_HOST + 'availability'
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirect}&response_type=code&scope=https%3A//www.googleapis.com/auth/calendar.readonly&access_type=offline`
  }

  return (
    <>
      {showSyncedCalendars()}
      <div className="mt-4 text-lg font-medium">
        {
          calendars.length === 0 ? "Välj kalender att synka med" : "Lägg till fler kalendrar"
        }
      </div>
      <div className="mt-4 bg-neutral-700 rounded p-4">
        <div className="font-medium">Google</div>
        <hr className="border-gray-500 mt-2"/>
        <div className="flex mt-4 items-center">
          <div className="w-1/3 flex items-center">
            <img src={googleImg} alt="Google"/>
            <div className="ml-4 text-sm font-medium">Google calendar</div>
          </div>
          <div className="w-1/3 text-sm text-neutral-400">Gmail, Gsuite</div>
          <CustomButton extraStyle="w-1/3" buttonType="outlineWhiteBase"
                        onClick={() => syncGoogle()}>Synka</CustomButton>
        </div>

        <div className="font-medium mt-4">Microsoft</div>
        <hr className="border-gray-500 mt-2"/>
        <div className="flex mt-4 items-center">
          <div className="w-1/3 flex items-center">
            <img src={microsoftCalendarImg} alt="microsoft"/>
            <div className="ml-4 text-sm font-medium">Outlook calendar</div>
          </div>
          <div className="w-1/3 text-sm text-neutral-400">
            Office 365, Outlook.com, live.com or hotmail calendar
          </div>
          <CustomButton extraStyle="w-1/3" buttonType="outlineWhiteBase"
                        onClick={() => syncMicrosoft()}>Synka</CustomButton>
        </div>
        <div className="flex mt-4 items-center">
          <div className="w-1/3 flex items-center">
            <img src={microsoftCalendarImg} alt="microsoft"/>
            <div className="ml-4 text-sm font-medium">Exchange calendar</div>
          </div>
          <div className="w-1/3 text-sm text-neutral-400 pr-8">
            Exchange Server 2013, 2016 or 2019
          </div>
          <CustomButton extraStyle="w-1/3" buttonType="outlineWhiteBase"
                        onClick={() => alert('Not implemented')}>Synka</CustomButton>
        </div>
      </div>
    </>
  )
};