import React from "react";


import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {

    const translation = useTranslation();
    const { t, i18n } = translation;

    // 
    const swedishText1 = "Nå din fulla ledarskapspotential med Zebrains AI-förstärkta plattform för ledarskapsutveckling, specifikt utformad för individuell utveckling och kontinuerligt lärande."
    const swedishText2 = "Personliga utvecklingsplaner säkerställer att din utvecklingsresa blir effektiv och relevant. AI-coachen hjälper dig att omvandla dina mål till konkreta steg, stärka dina förmågor och stötta hållbara beteendeförflyttningar."
    const swedishText3 = "Efterfråga och få snabb, relevant feedback från ditt team och andra nyckelpersoner för att stötta ditt fokus och hjälpa dig att nå dina mål snabbare."
    const swedishText4 = "Förädla din ledarskapsutveckling med Zebrain."

    const englishText1 = "Unlock your full leadership potential with Zebrain’s AI-enhanced leadership development platform, designed for individual and continuous growth."
    const englishText2 = "Tailored development plans ensure that your growth is personalized and effective, while the AI coach facilitates your development by turning your goals into actionable steps, strenghten abilities and sustainable behavior shifts."
    const englishText3 = "Ask for and receive rapid, relevant feedback from your team and stakeholders to stay on track and achieve your goals faster."
    const englishText4 = "Empower your leadership journey with Zebrain."

    const paragraph1 = i18n.language.startsWith('sv') ? swedishText1 : englishText1;
    const paragraph2 = i18n.language.startsWith('sv') ? swedishText2 : englishText2;
    const paragraph3 = i18n.language.startsWith('sv') ? swedishText3 : englishText3;
    const paragraph4 = i18n.language.startsWith('sv') ? swedishText4 : englishText4;

    return (

        <>
            <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
                {t('about-nav-title')}
            </h1>
            <div className="flex flex-col gap-4">
                <p className="text-base text-neutral-400">
                    {paragraph1}
                </p>
                <p className="text-base text-neutral-400">
                    {paragraph2}
                </p>
                <p className="text-base text-neutral-400">
                    {paragraph3}
                </p>
                <p className="text-base text-neutral-400">
                    {paragraph4}
                </p>
            </div>
        </>
    );
};

export default AboutPage;