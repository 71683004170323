let api_endpoint = "https://coaching-api.dazzle.zebrain.se/"
let local_api_endpoint = "http://localhost:8070/api/"
let web_socket_endpoint = "ws://localhost:9003"
let file_host = "http://localhost/"
let app_host = "http://localhost:1234/"

if (window.location.hostname === "stage.zebrain.se") {
  local_api_endpoint = "https://stage.zebrain.se/api/"
  web_socket_endpoint = "https://stage.zebrain.se/wss"
  file_host = "https://stage.zebrain.se/"
  app_host = "https://stage.zebrain.se/"
}

export interface AppConfig {
  TOKEN_NAME: string
  LOCAL_TOKEN_NAME: string
  API_ENDPOINT: string
  LOCAL_API_ENDPOINT: string
  WEB_SOCKET_ENDPOINT: string
  FILE_HOST: string
  APP_HOST: string
}

export const CONFIG: AppConfig = {
  TOKEN_NAME: "api_token",
  LOCAL_TOKEN_NAME: "mock_token",
  API_ENDPOINT: api_endpoint,
  LOCAL_API_ENDPOINT: local_api_endpoint,
  WEB_SOCKET_ENDPOINT: web_socket_endpoint,
  FILE_HOST: file_host,
  APP_HOST: app_host
}