import {ResourcesInterface, PartnerInterface, ERRORS} from "./interfaces";
import {arrangeVideos} from "./placeVideos"
import {showLocalInfo, showPartnerInfo} from "./debug";
import {sendSocket, sendSocketDebug, sendSocketError} from "./socket"
// import {debugOffer} from "./debug"
// @ts-ignore
import searchMinus from "../../../assets/images/icons/video/search-minus.svg"
// @ts-ignore
import searchPlus from "../../../assets/images/icons/video/search-plus.svg"

export function partnerFromPeer(peerConnection: RTCPeerConnection, resources: ResourcesInterface) {
  for (const partner of resources.partners) {
    if (partner.peerConnection === peerConnection) {
      return partner
    }
  }
  return null
}

function receivePeerCandidate(event: any, resources: ResourcesInterface) {
  const partner = partnerFromPeer(event.target, resources)
  if (partner && event.candidate) {
    const mess = {
      type: 'meeting',
      command: 'icecandidate',
      candidate: event.candidate,
      user_id_to: partner.userId
    }
    sendSocket(mess, resources)
  } else {
    if (partner) {
      sendSocketDebug('icecandidate is empty, can open remote stream', resources)
    } else {
      sendSocketError('Failed partnerFromPeer', resources)
    }
  }
}

export function createVideoContainer(parent: HTMLElement) {

  const videoContainer = document.createElement('div')

  videoContainer.classList.add('video_element_container')

  parent.appendChild(videoContainer)

  return videoContainer

}

export function createVideoElement(parent: HTMLElement) {

  const videoElement = document.createElement('video')
  videoElement.height = 200
  videoElement.width = 200
  videoElement.autoplay = true
  videoElement.setAttribute("playsinline", "playsinline")
  videoElement.classList.add('video_element')

  videoElement.controls = false
  parent.appendChild(videoElement)

  return videoElement
}

export function createPartnerInfoDiv(resources: ResourcesInterface, parent: HTMLElement, partner: PartnerInterface) {

  const el = document.createElement('div')
  el.classList.add('video_info_div')
  el.innerText = partner.userName
  el.addEventListener('click', function () {
    // console.log(partner.videoTrack?.getSettings().width)
    showPartnerInfo(partner).then(info => {
      resources.stateFunc('info', info)
      resources.stateFunc('showInfo', true)
    })
  })
  parent.appendChild(el)
}

export function createLocalInfoDiv(resources: ResourcesInterface, parent: HTMLElement, displayInfo: Function): HTMLElement {

  const el = document.createElement('div')
  el.classList.add('video_info_div')

  const enlarge = document.createElement('img')
  enlarge.src = searchMinus
  enlarge.classList.add('video_toggle_size')
  enlarge.addEventListener('click', function () {
    resources.small = !resources.small
    if (resources.small) {
      this.src = searchPlus
    } else {
      this.src = searchMinus
    }
    arrangeVideos(resources)
  })
  el.appendChild(enlarge)

  const nameEl = document.createElement('div')
  nameEl.innerText = resources.userName
  nameEl.classList.add('cursor-pointer')
  nameEl.addEventListener('click', function () {
    showLocalInfo(resources, displayInfo)
  })
  el.appendChild(nameEl)

  parent.appendChild(el)

  return el
}

function connectRemoteStream(partner: PartnerInterface, resources: ResourcesInterface) {

  const now = new Date()
  if (partner.connectedRemoteStream > 0) {
    const sinceLastConnect = now.getTime() - partner.connectedRemoteStream
    if (sinceLastConnect < 3000) {
      sendSocketDebug('connectRemoteStream ran ' + sinceLastConnect + ' ms ago, returning', resources)
      return
    } else {
      console.log('---- connectRemoteStream ran ' + sinceLastConnect + ' ms ago,')
    }
  }
  partner.connectedRemoteStream = now.getTime()

  let remoteVideo: HTMLVideoElement = undefined as any

  if (partner.offerType === 'video') {
    if (!partner.videoElement) {
      partner.videoContainer = createVideoContainer(resources.videoContainerRef.current)
      createPartnerInfoDiv(resources, partner.videoContainer, partner)
      remoteVideo = createVideoElement(partner.videoContainer)
      partner.videoElement = remoteVideo
      if (partner.videoStream) {
        remoteVideo.srcObject = partner.videoStream
        partner.videoElement.onresize = (e: any) => {
          if (partner.videoElement) {
            const w = partner.videoElement.videoWidth
            const h = partner.videoElement.videoHeight
            if (partner.videoWidth !== 0 && partner.videoWidth !== w &&
              partner.videoHeight !== 0 && partner.videoHeight !== h) {
              console.log('Trigger change from ' + partner.videoWidth + ' x ' +
                partner.videoHeight + ' to ' + w + ' x ' +h)
              resources.stateFunc('arrangeVideos')
            }
            partner.videoWidth = w
            partner.videoHeight = h
          }
        }
      } else {
        alert('No partner.videoStream')
      }
    }
  } else if (partner.offerType === 'screen') {
    if (!partner.screenElement) {
      partner.screenContainer = createVideoContainer(resources.videoContainerRef.current)
      remoteVideo = createVideoElement(partner.screenContainer)
      partner.screenElement = remoteVideo
      if (partner.screenStream) {
        remoteVideo.srcObject = partner.screenStream
        resources.rtcStatus = 'connected'
      } else {
        alert('No partner.screenStream')
      }
    } else {
      console.log('!!!partner.screenElement existed')
    }
    if (!partner.videoElement) {
      console.log('!!!No partner.videoElement')
    }
  } else {
    sendSocketError('Bad offer type: ' + partner.offerType, resources)
  }

  if (remoteVideo) {
    try {
      remoteVideo.onloadedmetadata = () => {
        resources.rtcStatus = 'connected'
        if (window.innerWidth > 500) {
          resources.small = true          
        }
        if (partner.offerType === 'video') {
          const infoText = partner.userName + ' is connected to meeting.'
          resources.stateFunc('info', infoText)
          resources.stateFunc('showInfo', true)
        }
        arrangeVideos(resources)
        const mess = {
          type: 'meeting',
          command: 'connected',
        }
        sendSocket(mess, resources)
      }
    } catch (error) {
      console.error('Error opening video camera.', error)
      sendSocketError('Error opening video camera', resources)
    }
  } else {
    arrangeVideos(resources)
  }

}

function receivePeerTrack(event: any, resources: ResourcesInterface) {
  console.log('receivePeerTrack ', event.track)

  if (event.track.kind !== 'video' && event.track.kind !== 'audio') {
    return
  }

  const partner = partnerFromPeer(event.target, resources)
  if (!partner) {
    sendSocketError('receivePeerTrack failed partnerFromPeer', resources)
    return
  }

  if (!partner.videoStream) {
    partner.videoStream = new MediaStream()
  }
  if (event.track.kind === 'audio') {
    if (!partner.audioTrack) {
      partner.audioTrack = event.track
      partner.videoStream.addTrack(event.track)
    }
  } else if (event.track.kind === 'video') {
    if (partner.offerType === 'video') {
      if (!partner.videoTrack) {
        partner.videoTrack = event.track
        partner.videoStream.addTrack(event.track)
      }
    } else if (partner.offerType === 'screen') {
      if (!partner.screenTrack) {
        if (!partner.screenStream) {
          partner.screenStream = new MediaStream()
          partner.screenTrack = event.track
          partner.screenStream.addTrack(event.track)
        } else {
          console.log('!!!partner.screenStream existed already!')
        }
      } else {
        console.log('!!!partner.screenTrack existed already: ', partner.screenTrack)
      }
    }
  }
}

function peerConnectionStateChange(event: any, resources: ResourcesInterface) {
  console.log('-------- connectionStateChange: ' + event.target.connectionState)
  if (event.target.connectionState === 'failed') {
    sendSocketError('peerConnectionStateChange failed rtc connection', resources)
    resources.errorFunc(ERRORS.RTC_CONNECTION_STATE_FAILED)
  }
}

function peerConnectionError(event: any, resources: ResourcesInterface) {
  sendSocketError(event.errorText, resources)
}

function peerIceCandidateError(event: any, resources: ResourcesInterface) {
  sendSocketError(event.errorText, resources)
}

function peerGatheringStateChange(event: any, resources: ResourcesInterface, partner: PartnerInterface) {
  console.log('------ icegatheringstatechange: ' + event.target.iceGatheringState)
  if (event.target.iceGatheringState === 'complete') {
    resources.rtcHasConnected = true
    connectRemoteStream(partner, resources)
  }
}

function peerNegotiationNeeded(event: any) {
  console.log('------ negotiation needed')
  console.log(event)
}

export function findPartner(resources: ResourcesInterface, userId: string) {
  for (const partner of resources.partners) {
    if (partner.userId === userId) {
      return partner
    }
  }
  return null
}

// connect is sent by second person connected
export function connectPartner(resources: ResourcesInterface, userId: string, userName: string) {
  const partner = addPartner(userId, userName, resources)
  addTracksToPeer(partner, resources)
  sendOffer(resources, partner, 'video')
}

function sendOffer(resources: ResourcesInterface, partner: PartnerInterface, kind: string) {
  partner.peerConnection.createOffer().then((offer: RTCSessionDescriptionInit) => {
    partner.peerConnection.setLocalDescription(offer).then(() => {
      const mess = {
        type: 'meeting',
        command: 'offer',
        user_id_to: partner.userId,
        user_name_from: resources.userName,
        kind: kind,
        offer
      }
      sendSocket(mess, resources)
      console.log('send offer to: ' + partner.userId)
    }).catch((error: any) => {
      sendSocketError('sendOffer cannot set localDescription', resources)
    })
  }).catch((error: any) => {
    sendSocketError('sendOffer cannot createOffer', resources)
  })
}

// handleOffer is sent from second person connected
export function handleOffer(resources: ResourcesInterface, userId: string, userName: string, offer: any, kind: string) {
  let partner = findPartner(resources, userId)
  if (!partner) {
    partner = addPartner(userId, userName, resources)
    if (kind === 'video') {
      rtcNegotiationStarted(resources, partner)
    }
  }
  partner.offerType = kind

  console.log('Offer of kind ' + kind)

  addTracksToPeer(partner, resources)

  const remoteDesc = new RTCSessionDescription(offer)

  partner.peerConnection.setRemoteDescription(remoteDesc).then(() => {
    partner?.peerConnection.createAnswer().then((answer: RTCSessionDescriptionInit) => {
      partner?.peerConnection.setLocalDescription(answer).then(() => {
        const mess = {
          type: 'meeting',
          command: 'answer',
          user_id_to: userId,
          kind: kind,
          answer
        }
        sendSocket(mess, resources)

        if (partner && kind === 'screen') {
          // Ideally we would call this from icegatheringstate === 'complete', but that is not fired when screen sharing
          connectRemoteStream(partner, resources)
        }
      })
    })
  }).catch((err: any) => {
    console.log(err)
    sendSocketError('handleOffer cannot set remoteDescription', resources)
  })
}

function rtcNegotiationStarted(resources: ResourcesInterface, partner: PartnerInterface) {
  const infoText = partner.userName + ' is connecting to meeting.'
  resources.stateFunc('info', infoText)
  resources.stateFunc('showInfo', true)
  resources.rtcStatus = 'connecting'

  setTimeout(() => {
    rtcNegotiationCheck(resources)
  }, 2000)
}

function rtcNegotiationCheck(resources: ResourcesInterface) {
  if (resources.rtcStatus === 'connected') {
    resources.stateFunc('showInfo', false)
  } else {
    resources.errorFunc(ERRORS.RTC_NEGOTIATION_SLOW)
    sendSocketError('rtcNegotiationCheck did not get connect in time', resources)
  }
}

// handleAnswer is handled by first person connected
export function handleAnswer(resources: ResourcesInterface, userId: string, kind: string, answer: any) {
  const partner = findPartner(resources, userId)
  if (partner) {
    const remoteDesc = new RTCSessionDescription(answer)
    partner.peerConnection.setRemoteDescription(remoteDesc).then(() => {
      console.log('--- handleAnswer')
      if (kind !== 'screen') {
        rtcNegotiationStarted(resources, partner)
      }
    })
  } else {
    alert('Could not find partner ' + userId)
    sendSocketError('handleAnswer could not find partner ' + userId, resources)
  }
}

export function handleCandidate(resources: ResourcesInterface, userId: string, candidate: any) {
  console.log('---- Got candidate', candidate)
  const partner = findPartner(resources, userId)
  if (partner) {
    try {
      partner.peerConnection.addIceCandidate(candidate).then(() => {
        console.log('added icecandidate')
      })
    } catch (e) {
      sendSocketError('handleCandidate failed addIceCandidate', resources)
    }
  }
}

export function addPartner(userId: string, userName: string, resources: ResourcesInterface) {
  const turnServer = resources.skipTurn ? 'skip_turn.zebrain.se' : 'turn.zebrain.se'
  if (resources.skipTurn) {
    console.log('------- skipTurn enabled, using turn server ' + turnServer)
  }
  const turnUdp = 'turn:' + turnServer + ':443'
  const turnTcp = 'turn:' + turnServer + ':443?transport=tcp'
  const RTCconfiguration = {
    iceCandidatePoolSize: 2,
    iceServers: [
      {urls: turnUdp, username: 'hakan', credential: 'darkness'},
      {urls: turnTcp, username: 'hakan', credential: 'darkness'}
    ]
  }
  const peerConnection = new RTCPeerConnection(RTCconfiguration)

  peerConnection.addEventListener('icecandidate', function(event) {
    receivePeerCandidate(event, resources)
  })

  peerConnection.addEventListener('connectionstatechange', function(event) {
    peerConnectionStateChange(event, resources)
  })

  peerConnection.addEventListener('track', function(event) {
    receivePeerTrack(event, resources)
  })

  peerConnection.addEventListener('negotiationneeded', peerNegotiationNeeded)

  const partner: PartnerInterface = {
    peerConnection: peerConnection,
    videoStream: new MediaStream(),
    userId: userId,
    userName: userName,
    videoWidth: 0,
    videoHeight: 0,
    offerType: 'video',
    connectedRemoteStream: 0,
  }
  resources.partners.push(partner)

  peerConnection.addEventListener('error', function(event) {
    peerConnectionError(event, resources)
  })

  peerConnection.addEventListener('icecandidateerror', function(event) {
    peerIceCandidateError(event, resources)
  })

  peerConnection.addEventListener('icegatheringstatechange', function(event) {
    peerGatheringStateChange(event, resources, partner)
  })

  return partner
}

export function addTracksToPeer(partner: PartnerInterface, resources: ResourcesInterface) {
  const existingTracks: Array<MediaStreamTrack> = []
  partner.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
    if (sender.track) {
      existingTracks.push(sender.track)
    }
  })
  // It is possible that sender has background image. In that case there is a canvasStream
  const canvasStream = resources.canvas?.canvasOut.captureStream()

  resources.localStream?.getTracks().forEach((track: MediaStreamTrack) => {
    if (existingTracks.indexOf(track) < 0) {
      if (resources.localStream) {
        if (canvasStream && track.kind === 'video') {
          console.log('Not adding localStream - there is a canvasStream')
        } else {
          partner.peerConnection.addTrack(track, resources.localStream)
          console.log('Add track to peer: ', track)
        }

        if (track.kind === 'audio' && track.muted) {
          resources.stateFunc('info', 'Your microphone is muted.\nCheck volume level.')
          resources.stateFunc('showInfo', true)
        }
      }
    } else {
      console.log('Track existed: ', track)
    }
  })

  if (canvasStream) {
    canvasStream?.getTracks().forEach((track: MediaStreamTrack) => {
      if (existingTracks.indexOf(track) < 0) {
        partner.peerConnection.addTrack(track, canvasStream)
      } else {
        console.log('Canvas track existed: ', track)
      }
    })
  }

  // if (resources.screenStream) {
  //   const track: MediaStreamTrack = resources.screenStream.getVideoTracks()[0]
  //   track.addEventListener('ended', function() {
  //     stopShareScreen(resources)
  //   })
  //   partner.peerConnection.addTrack(track, resources.screenStream)
  //   sendOffer(resources, partner, 'screen')
  //   console.log('Added screen stream to peer')
  // }

}

function addScreenTracksToPeer(resources: ResourcesInterface) {
  if (resources.screenStream) {

    const track: MediaStreamTrack = resources.screenStream.getVideoTracks()[0]

    track.addEventListener('ended', function() {
      stopShareScreen(resources)
    })

    for (const partner of resources.partners) {
      if (resources.screenStream) {
        partner.peerConnection.addTrack(track, resources.screenStream)
      }
    }

    for (const partner of resources.partners) {
      sendOffer(resources, partner, 'screen')
    }
    arrangeVideos(resources)
  }

}

export function replaceTrackToPeer(resources: ResourcesInterface) {
  for (const partner of resources.partners) {
    resources.localStream?.getTracks().forEach((track: MediaStreamTrack) => {
      partner.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
        if (sender.track !== null && sender.track.kind === track.kind) {
          sender.replaceTrack(track).then(() => {
            console.log('Replacing track ' + track.kind)
          })
          return
        }
      })
    })
  }
}

export function startShareScreen(resources: ResourcesInterface) {
  if (!resources.screenContainer) {
    resources.screenContainer = createVideoContainer(resources.videoContainerRef.current)
  }
  if (!resources.screenElement) {
    resources.screenElement = createVideoElement(resources.screenContainer)
  }


  navigator.mediaDevices.getDisplayMedia({video: true}).then((localStream: MediaStream) => {
    resources.screenStream = localStream
    try {
      if (resources.screenElement) {
        resources.screenElement.srcObject = resources.screenStream
        resources.screenElement.onloadedmetadata = () => {
          addScreenTracksToPeer(resources)
          resources.stateFunc('shareScreen', true)
        }
      }
    } catch (error) {
      console.error('Error opening screen sharing.', error)
      // this.showInfo('Sharing error:' + error.toString)
    }
  }).catch((err: any) => {
    console.error(err)
    // this.showInfo('Could not share screen. Ask Zebrain for help!')
  })

}

export function stopShareScreen(resources: ResourcesInterface) {
  if (resources.screenStream) {
    const removedIds: Array<string> = []
    resources.screenStream.getTracks().forEach((track: MediaStreamTrack) => {
      removedIds.push(track.id)
      track.removeEventListener('ended', function() {
        stopShareScreen(resources)
      })
      track.stop()
    })
    for (const partner of resources.partners) {
      partner.offerType = 'video'
      partner.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
        if (sender.track !== null && removedIds.indexOf(sender.track.id) > -1) {
          partner.peerConnection.removeTrack(sender)
        }
      })
      const mess = {
        type: 'meeting',
        command: 'stopScreen',
        user_id_to: partner.userId,
      }
      sendSocket(mess, resources)
    }
    resources.screenStream = undefined
    resources.stateFunc('shareScreen', false)
  }

  if (resources.screenElement) {
    removeVideoElement(resources.videoContainerRef.current, resources.screenContainer, resources.screenElement)
    resources.screenElement = undefined
  }
  arrangeVideos(resources)

}

export function partnerClosedScreen(resources: ResourcesInterface, userId: string) {
  const partner = findPartner(resources, userId)
  if (!partner) {
    return
  }

  if (partner.screenTrack) {
    partner.screenTrack.stop()
    partner.screenTrack = undefined
    partner.screenStream = undefined
  }

  if (partner.screenElement) {
    removeVideoElement(resources.videoContainerRef.current, partner.screenContainer, partner.screenElement)
    partner.screenElement = undefined
  }

  arrangeVideos(resources)
}

export function partnerClosed(resources: ResourcesInterface, userId: string) {
  closePartner(userId, resources)

  arrangeVideos(resources)
}

function removeVideoElement(parentElement: HTMLElement, container?: HTMLElement, element?: HTMLVideoElement) {
  if (container) {

    if (element) {
      container.removeChild(element)
      element.remove()
      element.srcObject = null
    }
    parentElement.removeChild(container)
  }
}

export function closePartner(userId: string, resources: ResourcesInterface) {
  const partner = findPartner(resources, userId)
  if (!partner) {
    return
  }

  resources.small = false
  resources.rtcStatus = 'waiting'
  const infoText = partner.userName + ' left the meeting.'
  resources.stateFunc('info', infoText)
  resources.stateFunc('showInfo', true)

  if (partner.audioTrack) {
    partner.audioTrack.stop()
  }

  if (partner.videoTrack) {
    partner.videoTrack.stop()
  }
  if (partner.screenTrack) {
    partner.screenTrack.stop()
  }

  partner.peerConnection.getSenders().forEach((sender: RTCRtpSender) => {
    if (sender.track !== null) {
      partner.peerConnection.removeTrack(sender)
    }
  })

  if (partner.videoElement) {
    removeVideoElement(resources.videoContainerRef.current, partner.videoContainer, partner.videoElement)
    partner.videoElement = undefined
  }

  if (partner.screenElement) {
    removeVideoElement(resources.videoContainerRef.current, partner.screenContainer, partner.screenElement)
    partner.screenElement = undefined
  }

  partner.peerConnection.close()

  resources.partners = resources.partners.filter(obj => obj !== partner);
}

function stopStream(stream: MediaStream) {
  stream.getTracks().forEach((track: MediaStreamTrack) => {
    if (track.readyState === 'live') {
      track.stop()
      console.log(track.kind + ' stopped')
    } else {
      console.log(track.kind + ': ' + track.readyState)
    }
  })
}

export function unmountVideo(resources: ResourcesInterface) {
  console.log('--- unmounting video. Resources: ' + resources.localStream)
  if (resources.localStream) {
    stopStream(resources.localStream)
  }

  if (resources.screenStream) {
    stopStream(resources.screenStream)
  }

  for (const partner of resources.partners) {
    if (partner.videoStream) {
      stopStream(partner.videoStream)
    }
    if (partner.screenStream) {
      stopStream(partner.screenStream)
    }

    resources.videoContainer = null
    resources.videoElement = null

    if (partner.peerConnection) {

      partner.peerConnection.removeEventListener('icecandidate', function(event) {
        receivePeerCandidate(event, resources)
      })
      partner.peerConnection.removeEventListener('connectionstatechange', function(event) {
        peerConnectionStateChange(event, resources)
      })

      partner.peerConnection.removeEventListener('error', function(event) {
        peerConnectionError(event, resources)
      })
      partner.peerConnection.removeEventListener('track', function(event) {
        receivePeerTrack(event, resources)
      })
      partner.peerConnection.removeEventListener('icecandidateerror', function(event) {
        peerIceCandidateError(event, resources)
      })
      partner.peerConnection.removeEventListener('icegatheringstatechange', function(event) {
        peerGatheringStateChange(event, resources, partner)
      })
      partner.peerConnection.removeEventListener('negotiationneeded', peerNegotiationNeeded)

      partner.peerConnection.close()
    }
  }
}
