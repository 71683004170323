import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Markdown from 'react-markdown';
import Api from "../../services/Api";
import LocalBreadcrumbs from "../../components/LocalBreadcrumbs";
import {LocalCrumbsInterface} from "../../interfaces/common";
import {ClientInterface} from "../../interfaces/common";
import {CONFIG} from "../../constants"
import profileIcon from "shared-assets/icons/profile.svg";
import learningIcon from "shared-assets/icons/learning.svg";
import iconClock from "shared-assets/icons/clock.svg";
import iconCalendar from "shared-assets/icons/calendar.svg";
import {dateFormatHourMinute, calculateEndTime, getMonthShort} from "../../helpers/dateFunctions";


export default function ClientPage() {

  const api = Api.getInstance();
  const translation = useTranslation();
  const { t, i18n } = translation;
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language.startsWith('sv') ? 'sv' : 'en' : 'en'
  const { clientId } = useParams();
  const [client, setClient] = useState<ClientInterface>()
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    api.get('local/clients/' + clientId).then((result) => {
      const clientJson = result.json.client
      for (const m of clientJson.meetings) {
        m.startDate = new Date(m.start)
      }
      setClient(clientJson)
      setLoaded(true)
    })
  }, [])

  if (!loaded) {
    return (<div className="mt-4">Loading clients...</div>)
  }

  const crumbs: Array<LocalCrumbsInterface> = [
    { name: t('my-clients'), url: '/clients'},
    { name: client.firstName + " " + client.lastName },
  ]

  const now = new Date()

  return (
    <>
      <LocalBreadcrumbs crumbs={crumbs}/>
      <div className="w-full text-center">
        <img className="picture-lg mx-auto" alt="pic"
             src={client.picture ? (CONFIG.FILE_HOST + client.picture) : profileIcon}/>

        <div className="mt-4">
          <p className="font-medium text-xl" onClick={() => navigate('/clients/' + client.id + '/chat')}>{client.firstName} {client.lastName}</p>
          <p className="text-neutral-400">{client.title}</p>
          <p className="text-neutral-400">{client.company}</p>
        </div>
      </div>

      <div className="flex justify-between mt-4 p-4 rounded bg-neutral-800">
        <div className="text-neutral-400 text-sm">
          Bokade möten
        </div>
        <div className="text-neutral-400 text-sm">
          Klara möten
        </div>
        <div className="text-neutral-400 text-sm">
          Licens utgår
        </div>
      </div>

      <div className="mt-6 text-lg">Utvecklingsplan</div>
      {
        client.focusAreas.map((focusArea, idx) => (
          <div className="mt-2" key={"fa_" + idx}>
            <div className="p-4 bg-neutral-700 flex items-center">
              <img src={learningIcon} alt="learning"/>
              <p className="font-medium ml-2">{focusArea.name}</p>
            </div>
            <div className="p-4 bg-neutral-800">
              <Markdown>
                {focusArea.text}
              </Markdown>
            </div>
          </div>
          )
        )
      }

      <div className="mt-6 text-lg">{t('booked-meetings')}</div>
      {
        client.meetings.filter(m => (m.startDate > now))
          .sort((a, b) => {
              return (b.startDate < a.startDate) ? 1 : -1
            }
          ).map((meeting, idx) => (
            <div key={"coming_" + idx} className="px-4 py-2 bg-neutral-700 flex items-center">
              <img className="icon" alt="Time" src={iconCalendar}/>
              <span className="ml-2 w-20">
                  {meeting.startDate.getDate() + " " + getMonthShort(meeting.startDate.getMonth(), lang)}
              </span>
              <img className="icon ml-2" alt="Time" src={iconClock}/>
              <span className="ml-2">
                  {dateFormatHourMinute(meeting.startDate)}&mdash;{dateFormatHourMinute(calculateEndTime(meeting.startDate, meeting.length))}
              </span>
            </div>
          )
        )
      }


    </>

  )
};