import React, {useState} from 'react';
import { Modal } from 'flowbite-react';
import {BookedMeeting} from "../../interfaces/common";
import CustomButton from "../CustomButton";
import {calculateEndTime, dateFormatDate, dateFormatHourMinute} from "../../helpers/dateFunctions";
import iconCalendar from "shared-assets/icons/calendar.svg"
import iconClock from "shared-assets/icons/clock.svg";
import iconBadgeCheck from "shared-assets/icons/badge-check.svg";
import Api from "../../services/Api";

interface Props {
  meeting: BookedMeeting;
  close: Function
  lang: string
}

const CancelMeeting: React.FC<Props> = ({ meeting, close, lang }) => {
  const api = Api.getInstance();
  const [txt, setTxt] = useState<string>('');
  const [cancelled, setCancelled] = useState<boolean>(false);

  const doCancel = () => {
    const data = {
      id: meeting.id,
      message: txt
    }
    api.post('local/meeting/cancel', data).then(response => {
      console.log('Cancelled meeting')
      setCancelled(true);
    })
  }

  const showContent = () => {
    if (cancelled) {
      return (
        <div className="p-4">
          <div className="my-12 flex justify-center items-center">
            <img className="icon-xl" alt="check" src={iconBadgeCheck} />
          </div>
          <div className="text-neutral-300">
            Ditt möte har avbokats och din klient uppdaterats.
          </div>
        </div>
      )
    } else {
      const endTime = calculateEndTime(meeting.startDate, meeting.length)
      return (
        <div className="p-4">
          <div className="font-medium">
            {meeting.firstName} {meeting.lastName}
          </div>
          <div className="mt-4 flex items-center">
            <img alt="calendar" className="icon" src={iconCalendar} />
            <div className="ml-2">{dateFormatDate(meeting.startDate, false, lang)}</div>
          </div>
          <div className="mt-4 flex items-center">
            <img className="icon" alt="Time" src={iconClock}/>
            <span className="ml-2">
                {dateFormatHourMinute(meeting.startDate)} - {dateFormatHourMinute(endTime)}
              </span>
          </div>

          <div className="mt-4">
            <textarea value={txt} className="bg-neutral-700 text-neutral-300 rounded w-full h-24"
                      placeholder="Skriv meddelande till klienten..."
                   onChange={(e) => setTxt(e.target.value)} autoComplete="off" />
          </div>
        </div>
      )
    }
  }

  return (
    <Modal show={true} onClose={() => close(cancelled)} size="md">
      <Modal.Header className="bg-neutral-700 font-medium text-xl p-4">
        {
          cancelled ? "Mötet har avbokats": "Bekräfta avbokning"
        }
      </Modal.Header>
      <Modal.Body className="bg-neutral-700">
        <>
          {showContent()}

          <hr className="border-neutral-600"/>

          <div className="p-4">
            {
              cancelled ? (
                <CustomButton extraStyle="w-full" onClick={() => close(cancelled)}>Ok</CustomButton>
              ) : (
                <CustomButton extraStyle="w-full" onClick={() => doCancel()}>
                  Avboka möte
                </CustomButton>
              )
            }
          </div>

        </>
      </Modal.Body>
    </Modal>
  );
};

export default CancelMeeting;
