import { doc, onSnapshot } from 'firebase/firestore';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { DB } from '../App';
import { AuthContext } from './AuthContext';
import * as Sentry from "@sentry/react";
import {CONFIG} from "../constants"

const ClientDataContext = createContext(null) as any;

const ClientDataContextProvider = ({ children }) => {

  let auth = useContext(AuthContext) as any;
  const { user } = auth;
  const [client_id, setClientId] = useState<string | null>(null)
  const [glance, setGlance] = useState(null);

  const retryWithBackoff = (fn, retries = 5, delay = 1000) => {
    return new Promise((resolve, reject) => {
      fn().then(resolve).catch((err) => {
        if (retries === 0) {
          reject(err);
        } else {
          setTimeout(() => {
            retryWithBackoff(fn, retries - 1, delay * 2).then(resolve).catch(reject);
          }, delay);
        }
      });
    });
  };

  const listenWithRetry = (queryFn, callback) => {
    return retryWithBackoff(() => new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(queryFn, callback, (error) => {
        if (error.code === 'permission-denied') {
          reject(error);
        } else {
          resolve(unsubscribe);
        }
      });
    }));
  };

  useEffect(() => {
    if (!user || !user.sub) {
      return;
    }
    const remote_user_id = user.sub;

    const path = doc(DB, `group/test6/remote_users/${remote_user_id}`);
    listenWithRetry(path, (doc) => {
      // console.log("glance: ", doc.data());
      const data = doc.data();
      // Store the email as user key to use when sending questions to mock backend
      const email = data.user_client_info.user.name;
      window.localStorage[CONFIG.LOCAL_TOKEN_NAME] = email;
      console.log("Stored token for mock_backend: " + email);

      setGlance(doc.data());
    }).catch((error) => {
      console.error('Failed to set up listener for glance:', error);
    });

  }, [user]);

  useEffect(() => {
    if (!glance) {
      return;
    }
    if (glance.user_client_info.clients.length === 0) {
      return;
    }
    let client = glance.user_client_info.clients[0];
    setClientId(client.id);
    Sentry.setContext('client', { id: client.id, customer_id: client.customer_id });
  }, [glance]);


  return (
    <ClientDataContext.Provider value={{
      client_id,
      glance
    }}>
      {children}
    </ClientDataContext.Provider>
  );
};

export { ClientDataContextProvider };